"use-strict";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Icon from "../components/core_component/icon";
import { standardPlaneIcon } from "../components/assets/icons";
import Core from "../common/clientSdk";
import { ToastContainer, toast } from "react-toastify";
import { CustomText, LinkCta, PrimaryCta } from "../components";
import { Loader } from "../screens/loader";
import { Util } from "reactstrap";
import Utils from "../common/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubscriptionPlan from "./planSubscription";
import { Sidebar } from "../components";

function SubscriptionModal(props) {
  const [planChooseTab, setPlanChooseTab] = useState("tab-1");
  const [creditId, setCreditId] = useState("002");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSubs, setLoadingSubs] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState();
  const [buySubscription, setBuySubscription] = useState(
    props?.subscriptionData ? props.subscriptionData[0] : {}
  );
  const [creditPolicySidebarOpen, setCreditPolicySidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { subscriptionData = {}, orgData = {}, closeModal } = props;

  const creditAmount = orgData.credits;

  console.log(" props?.subscriptionData", props?.subscriptionData);

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;

  console.log("org_deep_data", org_deep_data);

  useEffect(() => {
    getAutoSelectedTab();
    if (subscriptionData) {
      const firstPlanKey = Object.keys(subscriptionData)[0];
      if (firstPlanKey) {
        setSubscriptionSelected(subscriptionData[firstPlanKey]);
      }
    }
  }, [subscriptionData, setSubscriptionSelected]);

  function updateProgress(status) {
    setLoading(status);
  }

  function subscriptionUserCreate(plan_id) {
    navigate(`/checkout`, {
      state: { plan_id: plan_id },
    });

    return;
    var params = {
      org_id: orgData.org_id,
      plan_id: plan_id,
    };
    Core.subscriptionUserCreate(
      subscriptionUserCreateSuccess,
      subscriptionUserCreateFailed,
      (status) => setLoadingSubs(status),
      params
    );
  }
  function subscriptionUserCreateSuccess(response_data) {
    toast.info("Contact support to purchase this plan");
    closeModal();
  }

  function subscriptionUserCreateFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }
  function postCreditOrder() {
    navigate("/checkout", { state: { fromState: true } });
    return;
    var params = {
      c_id: creditId,
      redirect_url: window.location.origin + "/payment_success",
      platform: "web",
    };
    Core.postCreditOrder(
      postCreditOrderSuccess,
      postCreditOrderFailed,
      updateProgress,
      params
    );
  }
  function postCreditOrderSuccess(response_data) {
    const pgOrderId = response_data.credit_order_data.pg_order_id;
    redirectToPaymentPage(pgOrderId);
    setLoading(false);

    closeModal();
  }

  function postCreditOrderFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }

  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }
  function tostFun() {
    closeModal();
    return toast.info("Contact support to purchase this plan");
  }

  function creditPolicyOpen() {
    setCreditPolicySidebarOpen(true);
    closeModal();
  }

  // const halfYearly = subscriptionData;
  // const {Half-Yearly ={}}=subscriptionData
  // console.log("subscriptionData", Object.keys(subscriptionData));

  function getAutoSelectedTab() {
    getSubscriptionTabData()?.forEach((item) => {
      setPlanChooseTab(
        getSubscriptionTabData()[0]["subscription_data"]["type"]
      );
    });
  }

  function renderStandardPlan(item) {
    return (
      <div className="subscription-container subscription-container-hover">
        <div>
          <div className="standard-plan-heading">
            <Icon iconName="standardPlaneIcon" />
            <div className="m-l-15">
              <p className="typography-14 m-b-4 pay-per-user-container">
                <span>Standard Plan</span>
                {item?.subscription_data?.name}
              </p>
              <h5 className="typography-25 standard-plan-amount">
                {item.feature.free_credits == 0
                  ? "Free trial"
                  : Utils.convertFromINR(
                      item?.subscription_data?.price,
                      user_data.country
                    )}
              </h5>
            </div>
            {!org_deep_data?.subscription_data?.plan?.plan_id && (
              <span className="typography-14  current-plan-active m-l-12">
                CURRENT PLAN
              </span>
            )}
          </div>
          <div className="all-row-plan-data">
            <div className="row-plan">
              <p className="typography-14-600">Free Credits</p>
              <p className="typography-14-600">{item.feature.free_credits}</p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Discount on Credits</p>
              <p className="typography-14-600">
                {" "}
                {item.feature.discount_on_credits} %
              </p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Dedicated Support</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Influencer Database</p>
              <p style={{ fontSize: "14px" }}>Limited Access</p>
              {/* <Icon iconName="fillTickIcon" /> */}
            </div>
            <div className="row-plan">
              <p className="typography-14-600">{`Max Brand`} </p>
              {item?.feature?.max_brand_count}
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Fake Follower Check</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Max Team Member</p>
              <p className="typography-14-600"> {item.feature.user_count}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {!org_deep_data?.subscription_data && (
              <PrimaryCta
                invert
                className="buy-credits-btn height-42"
                onClick={() => postCreditOrder()}
                fontSize={"font_size_large"}
                isLoading={isLoading}
                text={"Buy Credits"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function onPressHandlerOnPress(item, index) {
    // setBuySubscription(item);

    setPlanChooseTab(item?.subscription_data?.type);
  }

  function getSubscriptionTabData() {
    return (
      subscriptionData &&
      subscriptionData?.filter(
        (item) => item?.subscription_data?.type !== "Standard"
      )
    );
  }

  function getCurrentPlan(plan_id) {
    if (org_deep_data?.subscription_data?.plan?.plan_id == plan_id) {
      return true;
    } else {
      return false;
    }
  }

  console.log(
    "org_deep_data?.subscription_data?.plan?.plan_id",
    org_deep_data?.subscription_data?.plan?.plan_id
  );

  console.log("item?.subscription_data", org_deep_data?.subscription_data);

  function renderSubscriptionTabs() {
    return (
      <>
        {getSubscriptionTabData().map((item, index) => {
          return (
            <>
              {" "}
              <p
                key={index}
                className={
                  planChooseTab == item?.subscription_data.type
                    ? "tab-change-subscription-active"
                    : "tab-change-subscription"
                }
                onClick={() => onPressHandlerOnPress(item)}>
                {item?.subscription_data.type}
              </p>
            </>
          );
        })}
      </>
    );
  }

  function getSubscriptionListItems() {
    let subs_data = subscriptionData
      ?.filter(
        (item) =>
          item?.subscription_data.type == planChooseTab ||
          item?.subscription_data.type == "Standard"
      )
      .sort((a, b) => (a.subscription_data?.type === "Standard" ? -1 : 1));

    return subs_data;
  }
  // render here subscription ui
  function renderSubscriptionItem() {
    const activePlanId = org_deep_data?.subscription_data?.plan?.plan_id;
    console.log("activePlanId", activePlanId);

    return (
      <>
        {getSubscriptionListItems()?.map((item) => {
          console.log("item", item);
          const isActivePlan =
            item?.subscription_data?.plan_id === activePlanId;
          console.log("isActivePlan", isActivePlan);

          const planType = item?.subscription_data?.type;
          const planPrice = item?.subscription_data?.price;

          // Determine the divisor based on the plan type
          const divisor =
            planType === "Yearly"
              ? 12
              : planType === "Half-Yearly"
              ? 6
              : planType === "Quarterly"
              ? 3
              : 1;
          const monthlyPrice = planPrice / divisor;

          if (planType === "Standard") {
            return renderStandardPlan(item);
          } else {
            return (
              <div className="subscription-container subscription-container-hover">
                <div className="standard-plan-heading">
                  <Icon
                    iconName={
                      planType === "Half-Yearly"
                        ? "proPlaneIcons"
                        : "diamondIcon"
                    }
                  />
                  <div className="m-l-15">
                    <p className="typography-14 m-b-4">
                      {item?.subscription_data?.name}
                      {getCurrentPlan(item?.subscription_data?.plan_id) && (
                        <span className="typography-14 m-b-4 m-l-12 current-plan-active">
                          CURRENT PLAN
                        </span>
                      )}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <h5 className="typography-25">
                        {Utils.convertFromINR(monthlyPrice, user_data.country)}
                        /mo
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="all-row-plan-data">
                  <div className="row-plan">
                    <p className="typography-14-600">Free Credits</p>
                    <p className="typography-14-600">
                      {item.feature.free_credits}
                    </p>
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">Discount on Credits</p>
                    <p className="typography-14-600">
                      {item.feature.discount_on_credits} %
                    </p>
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">Dedicated Support</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">Influencer Database</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">{`Max Brands`} </p>
                    {item?.feature?.max_brand_count}
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">Fake Follower Check</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className="row-plan">
                    <p className="typography-14-600">Max Team Members</p>
                    <p className="typography-14-600">
                      {item.feature.user_count}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {!org_deep_data?.subscription_data ? (
                    <PrimaryCta
                      invert
                      className="buy-credits-btn bg-color-buy-credit height-42"
                      onClick={() =>
                        subscriptionUserCreate(item?.subscription_data?.plan_id)
                      }
                      fontSize={"font_size_large"}
                      isLoading={isLoadingSubs}
                      text={"Subscribe"}
                    />
                  ) : isActivePlan ? (
                    <PrimaryCta
                      invert
                      className="buy-credits-btn buy-credits-btn-hover height-42"
                      onClick={() => postCreditOrder()}
                      fontSize={"font_size_large"}
                      isLoading={isLoading}
                      text={"Buy Credits"}
                    />
                  ) : null}
                </div>
              </div>
            );
          }
        })}
      </>
    );
  }

  function creditPolicyClose() {
    setCreditPolicySidebarOpen(false);
  }

  function reelaxCreditPolicy() {
    return (
      <Sidebar
        sidebarWidth={"40%"}
        isOpen={creditPolicySidebarOpen}
        onClose={creditPolicyClose}
        Header={creditPolicySidebarHeader}>
        <div className="p-20">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div>
              <h2 className="credit-terms-conditions">Campaign Creation</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}>
              10 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}>
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Creating a campaign will deduct 10 credit from your account. The
              campaign will remain online in the marketplace for 1 month,
              accessible to influencers.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <div>
              <h2 className="credit-terms-conditions">Campaign Renewal</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}>
              10 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}>
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Renewing an existing campaign will deduct 10 credit. The campaign
              will remain live in the marketplace for an additional 1 month.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <div>
              <h2 className="credit-terms-conditions">Request Price</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}>
              1 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}>
            <p style={{ marginTop: "10px", padding: "10px" }}>
              When you request a price from an influencer, 1 credit will be
              deducted. This allows the influencer to receive the campaign
              invite and provide their asking price.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <div>
              <h2 className="credit-terms-conditions">Counter Offer</h2>
            </div>
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "#e8f1fe",
                padding: "5px 7px",
              }}>
              1 Credit
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgb(248, 250, 252)",
              borderRadius: "5px",
            }}>
            <p style={{ marginTop: "10px", padding: "10px" }}>
              Submitting a counteroffer to an influencer will deduct 1 credit.
              The influencer will either accept or reject the counteroffer,
              facilitating smooth negotiations.
            </p>
          </div>
        </div>
      </Sidebar>
    );
  }

  const creditPolicySidebarHeader = () => (
    <CustomText
      p
      text={"Reelax Credit Usage Terms"}
      className="side-bar-heading-text"
    />
  );
  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={closeModal}
        style={CancelReminder}>
        <div onClick={closeModal} className="close-btn-icon-subscription">
          <Icon iconName="CrossBtnIcon" />
        </div>

        <div className="plan-choose-main-container">
          <div className="top-section-choose-plan-container">
            <p className="pricing typography-12-500">Pricing</p>
            <h3 className="plan-choose-heading">Choose Your Pricing Plan</h3>
            <div style={{ display: "flex", gap: "10px" }}>
              <p className="plan-choose-sub-heading">
                {creditAmount} Credits left
              </p>
              <div className="m-b-10  ">
                <u style={{ color: "#1977f2" }}>
                  <LinkCta
                    p
                    textColor={"text_color_forgot"}
                    fontSize={"font_size_small"}
                    text={"How It Works?"}
                    onClick={() => creditPolicyOpen()}
                  />
                </u>
              </div>
            </div>
            <div className="plan-change-container">
              {subscriptionData && renderSubscriptionTabs()}
            </div>
          </div>

          <div className="subscription-main-container">
            {renderSubscriptionItem()}
          </div>
        </div>
      </Modal>

      {reelaxCreditPolicy()}
    </div>
  );
}

export default SubscriptionModal;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    height: "97%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
    padding: "0px",
    borderRadius: 20,
    // maxHeight: 500,
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.75)",
    // filter: "blur(8px)",
    zIndex: 10,
  },
};
