import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
} from "../../components";

import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import campaign from "../../common/data/campaign";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function PriceToCollectionInfluencer({
  closePriceModal,
  setCollectionInfluencerList,
  collectionId,
  influencerId,
  priceType,
}) {
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  function addPriceToCollectionInfluencer() {
    const params = {
      collection_id: collectionId,
      influencer_id: influencerId,
    };
    if (priceType == "price") {
      params.price = Utils.convertToINR(price, user_data.country);
    }

    if (priceType == "internal_price") {
      params.internal_price = Utils.convertToINR(price, user_data.country);
    }
    Core.addPriceToCollectionInfluencer(
      addPriceToCollectionInfluencerSuccess,
      addPriceToCollectionInfluencerFail,
      () => {},
      params
    );
  }

  function addPriceToCollectionInfluencerSuccess(response) {
    console.log("reponse", response);

    closePriceModal();
    setCollectionInfluencerList((prevState) => ({
      ...prevState,
      collection_influencer_list: prevState?.collection_influencer_list?.map(
        (influencer) =>
          influencer?.influencer_data?.influencer_id ===
          response.influencer_deep_data?.influencer_data?.influencer_id
            ? { ...influencer, ...response.influencer_deep_data } // Update only matching influencer
            : influencer // Keep unchanged if no match
      ),
    }));
  }

  function addPriceToCollectionInfluencerFail(errorList) {
    toast.error(errorList);
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={closePriceModal}
      style={customStylesModal}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          paddingTop: 15,
        }}
      >
        <div className="">
          <CustomText
            h5
            text={"Add Cost"}
            textColor={"text_grey_400_color"}
            className="m-b-20 typography-centerH7"
          />
          <div onClick={closePriceModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 60 }}>
        <div className="m-t-10">
          <div style={{ textAlign: "center" }} className="flex-row">
            <CustomText
              h5
              fontSize={"font_size_large"}
              text={priceType == "price" ? "Client Cost" : "Internal Cost"}
              textColor={"text_grey_400_color"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
              className="m-b-8 m-l-10"
            />
          </div>
          <CustomTextInput
            invert
            className={"input-height-counter"}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeHolder={"Cost"}
            type="number"
          />
        </div>

        <div className="m-t-40">
          <PrimaryCta
            fifth
            onClick={() => addPriceToCollectionInfluencer()}
            fontSize={"font_size_large"}
            className="collection-create-btn-2"
            text={"Add Cost"}
            isLoading={loading}
            // disabled={props.disabled}
          />

          {/* <PrimaryCta
          invert
          onClick={props.onClose}
          className="confirm-btn-bid"
          fontSize={"font_size_large"}
          text={"Cancel"}
          disabled={props.disabled}
        /> */}
          <button
            style={{ height: 44 }}
            className="confirm-btn-bid  m-l-10"
            onClick={closePriceModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PriceToCollectionInfluencer;
