import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import { CustomText, PrimaryCta, Image, Video } from "../../components";
import Utils from "../../common/utils";
import ReactHtmlParser from "react-html-parser";
import CreateCampaignSuccess from "./createCampaignSuccess";
import Core from "../../common/clientSdk";
import { useSelector } from "react-redux";
import Sdk from "../../common/core/module";
import moment from "moment";
import * as PreviewCampaign from "../../common/data/preview_campaign";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

let fashionData = PreviewCampaign.FASHION_BEAUTY;
let eductionData = PreviewCampaign.EDUCTION;

function QuickCreateCampaign({
  closeModal,
  bundleType,
  setModalIsOpen,
  modalIsOpen,
}) {
  const currentUnixDate = Sdk.Campaign.getDateString(new Date());
  const [endDate, setEndDate] = useState(currentUnixDate + 1296000);
  const [deliveryDate, setDeliveryDate] = useState(currentUnixDate + 2592000);
  // const [campaignDetail, setCampaignDetail] = useState(
  //   PreviewCampaign[bundleType]
  // );
  const campaignDetail = PreviewCampaign[bundleType];

  const [loading, setLoading] = useState(false);
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false);
  const [count, setCount] = useState(0);
  var navigate = useNavigate();

  useEffect(() => {
    if (modalIsOpen == false) {
      openModalSuccess();
    }
  }, [modalIsOpen]);
  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  const { campaign_data = {} } = campaignDetail.campaign_data;
  const { audience_data = {} } = campaignDetail.campaign_data;
  const { influencer_data = {} } = campaignDetail.campaign_data;
  const { instruction_data = {} } = campaignDetail.campaign_data;

  function quickCampaignDetails() {
    var params = {
      campaign_deep_data: JSON.stringify({
        campaign_data: {
          brand_id: brand_data.brand_id,
          name: campaign_data.name + brand_data.name,
          max_budget: campaign_data.max_budget,
          type: "social_media",
          engagement_type: "AUCTION",
          end_date: campaign_data.end_date,
          delivery_date: campaign_data.delivery_date,
        },
        reward_data: {
          reward_by_follower: {
            below_5k: "",
            above_5k_below_10k: "",
            above_10k_below_25k: "",
            above_25k_below_1m: "",
          },
          type: "cash",
        },
        content_type_deep_data: [
          {
            content_data: {
              content_type_id: "fxt1",
            },
          },
        ],
        audience_data: {
          age: audience_data?.age,
          gender: [audience_data.gender],
          language: audience_data.language,
        },
        influencer_data: {
          minimum_followers: Number(influencer_data?.minimum_followers),

          categories: ["m0im"],
        },
        instruction_data: {
          instruction_note: instruction_data.instruction_note,
        },
        sample_content_data: [],
      }),
    };
    if (paymentValidation()) {
      Core.createCampaignUser(
        createCampaignUserSuccess,
        createCampaignUserFailed,
        (status) => setLoading(status),
        params
      );
    }
  }

  function createCampaignUserSuccess(response) {
    toast.success("Campaign Created Successfully");
    closeModal();
    navigate("/campaigns");
    openModalSuccess();
  }

  function createCampaignUserFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  let campaignBudget = campaign_data.max_budget * campaign_data.number;

  function paymentValidation() {
    if (org_deep_data.org_data.balance <= campaignBudget) {
      toast.error("Please add balance in wallet to publish campaign");
      setTimeout(() => {
        navigate("./transaction-list");
      }, 2000);

      return false;
    }

    return true;
  }

  const openModalSuccess = () => {
    setModalIsOpen(false);
    toast.success("Campaign Created Successfully");

    setCount(count + 1);

    setModalIsOpenSuccess(!modalIsOpenSuccess);
  };

  const closeModalSuccess = () => {
    setModalIsOpenSuccess(false);
  };

  const ageData = audience_data?.age?.map((item) => {
    return Utils.convertAgeIdToHuman(item);
  });

  let language = audience_data?.language?.map(Utils.firstUpperCase).join(", ");

  return (
    <>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>

        <>
          <div className="review-campaign-heading">
            <CustomText
              h5
              text={"Review Campaign"}
              className={"typographyBoldH5"}
            />
          </div>
          <div style={{ overflow: "auto", paddingTop: 70, paddingBottom: 100 }}>
            <div className="review-campaign-detail-wrap">
              <div className="review-campaign-detail-page m-b-16">
                <div>
                  <CustomText
                    h5
                    text={"Campaign Details"}
                    className="button2"
                    textAlign={"text_align_left"}
                  />
                </div>
              </div>

              <div className="review-campaign-data">
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Title"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={campaign_data.name + brand_data.name}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Maximum Bid Amount"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <div style={{ display: "flex", gap: 2 }}>
                    <CustomText
                      p
                      text={`Rs ${campaign_data.max_budget}`}
                      className="subtitle1"
                      textAlign={"text_align_left"}
                    />
                  </div>
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"End Date"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={Utils.convertUnixToDate(endDate, user_data.time_zone)}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Delivery Date"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={Utils.convertUnixToDate(
                      deliveryDate,
                      user_data.time_zone
                    )}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Categories"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={Object.values(influencer_data?.categories).map(
                      (item) => {
                        return item.name;
                      }
                    )}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Instruction for Creator"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={ReactHtmlParser(instruction_data.instruction_note)}
                    className="subtitle1 m-l-8"
                    textAlign={"text_align_left"}
                  />
                </div>
                {/* {imageId.length !== 0 && (
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Sample Content"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <div className="flex-row">
                    {imageId &&
                      imageId?.map((item, index) => {
                        if (item?.split(".").pop() == "mp4") {
                          return (
                            <div key={index} className="product-sample-main">
                              <div
                                id={index}
                                onClick={() => handleVideoClick()}
                                className="m-r-6"
                              >
                                <video
                                  controlsList="nodownload"
                                  preload="metadata"
                                  style={{ height: 106, width: 106 }}
                                  src={Utils.generateCampaignSampleUrl(
                                    campaign_data.brand_id,
                                    item
                                  )}
                                />
                              </div>

                              {showVideo && (
                                <div className="fullscreen-image">
                                  <div style={{ position: "relative" }}>
                                    <div
                                      onClick={() => handleVideoClick()}
                                      className="fullscreen-image-close-btn"
                                    >
                                      <Icon iconName="CloseRoundButtonIcon" />
                                    </div>
                                    <Video
                                      source={Utils.generateCampaignSampleUrl(
                                        campaign_data.brand_id,
                                        item
                                      )}
                                      width={600}
                                      height={337}
                                      type={"video/mp4"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <>
                              <div className="product-sample-main">
                                <div onClick={() => handleClick()}>
                                  <Image
                                    source={Utils.generateCampaignSampleUrl(
                                      campaign_data.brand_id,
                                      item
                                    )}
                                    width={106}
                                    height={106}
                                  />
                                </div>

                                {showImage && (
                                  <div className="fullscreen-image">
                                    <div style={{ position: "relative" }}>
                                      <div
                                        onClick={() => handleClick()}
                                        className="fullscreen-image-close-btn"
                                      >
                                        <Icon iconName="CloseRoundButtonIcon" />
                                      </div>
                                      <Image
                                        source={Utils.generateCampaignSampleUrl(
                                          campaign_data.brand_id,
                                          item
                                        )}
                                        width={600}
                                        height={337}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
              )} */}
              </div>
            </div>
            <div className="review-target-detail-wrap">
              <div className="review-campaign-detail-page m-b-16">
                <div>
                  <CustomText
                    h5
                    text={"Target Influencers"}
                    className="button2"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="cursor-pointer" onClick={() => closeModal()}>
                  {/* <CustomText
                  p
                  text={"Edit"}
                  className="button1"
                  textColor={"text_color_register"}
                /> */}
                </div>
              </div>

              <div className="review-campaign-data">
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Audience Age Group"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={ageData?.join(", ")}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Influencer’s Gender"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={audience_data?.gender}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Content Language"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={language}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
                {influencer_data?.minimum_followers !== 0 && (
                  <div className="m-b-16">
                    <CustomText
                      p
                      text={"Minimum Followers"}
                      className="button1 m-b-4"
                      textAlign={"text_align_left"}
                    />
                    <CustomText
                      p
                      text={influencer_data?.minimum_followers}
                      className="subtitle1"
                      textAlign={"text_align_left"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="quick-campaign-btn-wrap">
              <div className="m-b-16">
                <CustomText
                  h4
                  text={"Total Budget"}
                  className="button2 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={"Rs " + campaignBudget}
                  className="button2"
                  textAlign={"text_align_left"}
                />
              </div>
              <PrimaryCta
                fifth
                onClick={() => {
                  quickCampaignDetails();
                }}
                fontSize={"font_size_large"}
                text={"Publish Campaign"}
                isLoading={loading}
                disabled={loading}
                className="blue-button"
              />
            </div>
          </div>
        </>
      </Modal>

      {modalIsOpenSuccess && (
        <CreateCampaignSuccess closeModal={closeModalSuccess} />
      )}
    </>
  );
}

export default QuickCreateCampaign;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--bg-white-color)",
    zIndex: "5",
    height: "92%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    minWidth: "700px",
    padding: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 1100,
  },
};
