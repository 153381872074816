import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  AnalyticsCard,
  CustomText,
  FallBack,
  FallBackBtn,
  SearchFallBack,
  ViewCards,
  PrimaryCta,
  Image,
  Video,
} from "../../components";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InstagramAnalytics from "./contentInstagramAnalytics";
import YoutubeAnalytics from "./contentYoutubeAnalytics";
import { ToastContainer, toast } from "react-toastify";
import * as CampaignData from "../../common/data/campaign";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InfluencerContentCard from "../../features/influecnerContentCard";
import { useToast } from "../../features/toastContainer";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsiveSocial = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function AnalyticsCampaign(props, { campaignId, brandId, orgId }) {
  const [isLoading, setLoading] = useState(false);
  const [campaignReport, setCampaignReport] = useState("");
  const [contentList, setContentList] = useState([]);
  const [campaignAnalytic, setCampaignAnalytic] = useState("");
  const [campaignContentAnalytic, setCampaignContentAnalytic] = useState("");
  const [influencerId, setInfluencerId] = useState(null);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerList, setInfluencerList] = useState();
  const [assignmentInfluencerList, setAssignmentInfluencerList] = useState([]);
  const showToast = useToast();
  const [pdfLoader, setPDFLoader] = useState(false);

  const campaignStatus = props.campaignDetail?.campaign_data?.status;
  const pdfRef = useRef();
  const influencerClass = useRef();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;

  const { user_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { brand_deep_data = {} } = UserData;

  useEffect(() => {
    // if (campaignStatus == "ACTIVE" || campaignStatus == "COMPLETED") {
    //   getCampaignReport();
    //   campaignAnalytics();
    //   campaignContentAnalytics();
    // }
    getAssignmentUser();
    AssignmentContentList();
    getCampaignReport();
    campaignAnalytics();
    campaignContentAnalytics();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getAssignmentUser() {
    var params = {
      campaign_id: props.campaignId,
      status: "COMPLETED",
    };

    Core.getAssignmentUser(
      getAssignmentUserSuccess,
      getAssignmentUserFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentUserSuccess(response_data) {
    setAssignmentInfluencerList(response_data["assignment_list"]);
  }

  function getAssignmentUserFailed(errorList) {
    toast.error(errorList.map((err) => err.m).join(", "));
  }

  function AssignmentContentList() {
    const params = {
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: props.campaignId,
    };
    Core.AssignmentContentList(
      AssignmentContentListSuccess,
      AssignmentContentListFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentListSuccess(response) {
    setLoading(false);
    // setInfluencerList(response?.assignment_list?.influencer_deep_data);
    setContentList(
      response?.assignment_list?.filter((item) => {
        return item?.assignment_data?.status == "COMPLETED";
      })
    );

    // setNext(response?.pagination_data?.next);
  }

  console.log("assignmentList 4", assignmentInfluencerList);

  function AssignmentContentListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCampaignReport() {
    const params = {
      campaign_id: props.campaignId,
    };
    Core.getCampaignReport(
      getCampaignReportSuccess,
      getCampaignReportFailed,
      updateProgress,
      params
    );
  }

  function getCampaignReportSuccess(response_data) {
    setCampaignReport(response_data);
  }

  function getCampaignReportFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function campaignAnalytics() {
    const params = {
      campaign_id: props.campaignId,
    };
    Core.campaignAnalytics(
      campaignAnalyticsSuccess,
      campaignAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignAnalyticsSuccess(response_data) {
    setCampaignAnalytic(response_data.analytics_data);
  }

  function campaignAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function campaignContentAnalytics() {
    const params = {
      org_id: props.orgId,
      brand_id: props.brandId,
      campaign_id: props.campaignId,
    };
    Core.campaignContentAnalytics(
      campaignContentAnalyticsSuccess,
      campaignContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignContentAnalyticsSuccess(response_data) {
    setCampaignContentAnalytic(response_data.campaign_content_analytics_data);
  }

  function campaignContentAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  let Engagement =
    Number(campaignContentAnalytic.total_like_count) +
    Number(campaignContentAnalytic.total_comment_count);
  let totalEngagement =
    Engagement / Number(campaignContentAnalytic.total_play_count);

  let avgEngagement = totalEngagement * 100;
  let cpfValue =
    campaignReport.total_spent / campaignContentAnalytic.total_play_count;
  let cpEValue = campaignReport.total_spent / Engagement;
  let cpmValue =
    campaignReport.total_spent / campaignContentAnalytic.total_play_count;

  function ContentReachData() {
    return (
      <div className="analytics-social-media-data">
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="heartIcon" />
            <CustomText
              p
              text={"Like"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"76k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            <Icon iconName="commentIcon" />
            <CustomText
              p
              text={"Comment"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="viewEyes" />{" "}
            <CustomText
              p
              text={"View Rate"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />{" "}
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail">
          <div className="flex-row">
            <Icon iconName="rupeeIcon" />
            <CustomText
              p
              text={"CPV"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"90k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
      </div>
    );
  }

  if (!props.isActive) {
    return <></>;
  }

  const toggleFlyoutDropdown = () => {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["view_profile"]
      )
    ) {
      showToast();
    } else {
      setInfluencerFlyOut(!influencerFlyOut);
    }
  };
  const downloadPdf = () => {
    setPDFLoader(true);
    const input = pdfRef.current;

    // Wait for all images to load
    const loadAllImages = () =>
      Promise.all(
        Array.from(input.querySelectorAll("img")).map((img) => {
          if (img.complete) return Promise.resolve();
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve; // Prevent blocking if image fails to load
          });
        })
      );

    loadAllImages().then(() => {
      html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
        const imageURL = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a3", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30; // Adjust as needed for padding
        pdf.addImage(
          imageURL,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("Analytics.pdf");
        setPDFLoader(false);
      });
    });
  };

  function renderFallback() {
    if (campaignStatus == "COMPLETED") {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBackBtn
              heading="Campaign Completed"
              title="Your campaign got completed or expired."
              // text="Repost Job"
              // onClick={openModalRepost}
            />
          </div>
        </>
      );
    } else if (campaignStatus == "IN_REVIEW") {
      return (
        <div className="fall-back-center-campaign">
          <FallBack
            heading={"Your campaign is under review"}
            title={
              "You will start receiving proposals as soon as it gets reviewed"
            }
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBack
              heading={"No Analytics Available"}
              // title={"Please wait until you get a new proposal"}
            />
          </div>
        </>
      );
    }
  }
  console.log("jhh", cpEValue);

  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = mediaUrl;
    a.download = `downloaded_${isVideo ? "video" : "image"}.${media_id
      .split(".")
      .pop()}`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up the anchor element
    document.body.removeChild(a);
  };
  function renderCampaignContent() {
    return (
      <>
        {contentList.length !== 0 && (
          <div className="analytics-list-header m-t-32 m-b-12">
            <CustomText
              p
              text={"Top Content"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
        )}

        {contentList.length !== 0 ? (
          <div className="all_influencer_analytics_container">
            <Carousel responsive={responsiveSocial}>
              {contentList.map((item) => {
                const platform = Utils.socialMediaPlatform(
                  item?.assignment_data.content_type_id
                );

                const mediaType =
                  item?.assignment_data?.media_id?.split(".").pop() == "mp4";
                if (item?.assignment_data?.status == "COMPLETED") {
                  return (
                    <>
                      {item.assignment_data.url && (
                        <>
                          {platform == "Instagram" && (
                            <InstagramAnalytics
                              campaignId={campaignId}
                              contentId={item.assignment_data?.content_id}
                              mediaUrl={item.assignment_data.url}
                              assignmentId={item?.assignment_data.assignment_id}
                              dateModified={item.assignment_data?.date_modified}
                              brandId={brand_deep_data.brand_data.brand_id}
                              orgId={brand_deep_data.brand_data.org_id}
                              height={350}
                              width={300}
                            />
                          )}
                        </>
                      )}
                      {item.assignment_data.url && (
                        <div className="embed-container">
                          {platform === "youtube" && (
                            <YoutubeAnalytics
                              campaignId={campaignId}
                              contentId={item.assignment_data?.content_id}
                              mediaUrl={item.assignment_data.url}
                              assignmentId={item?.assignment_data.assignment_id}
                              dateModified={item.assignment_data?.date_modified}
                              brandId={brand_deep_data.brand_data.brand_id}
                              orgId={brand_deep_data.brand_data.org_id}
                              height={300}
                              width={300}
                            />
                          )}
                        </div>
                      )}
                      {item.assignment_data?.url && (
                        <div className="embed-container">
                          {platform === "twitter" && (
                            <XEmbed
                              url={item.assignment_data?.url}
                              width={325}
                            />
                          )}
                        </div>
                      )}

                      {item.assignment_data?.media_id &&
                        mediaType &&
                        !item.assignment_data?.url && (
                          <div className="download-content">
                            <Video
                              source={Utils.constructContentMediaUrl(
                                item?.assignment_data.assignment_id,
                                item.assignment_data?.media_id
                              )}
                              width={325}
                              height={620}
                              className="content-media"
                            />
                            <PrimaryCta
                              fifth
                              onClick={() =>
                                handleDownload(
                                  item.assignment_data?.media_id,
                                  true,
                                  item.assignment_data.assignment_id
                                )
                              }
                              fontSize={"font_size_large"}
                              text={"Download"}
                              isLoading={""}
                            />
                          </div>
                        )}

                      {item.assignment_data.media_id &&
                        !mediaType &&
                        !item.assignment_data.url && (
                          <div className="download-content">
                            <Image
                              source={Utils.constructContentMediaUrl(
                                item.assignment_data.assignment_id,
                                item.assignment_data.media_id
                              )}
                              width={325}
                              height={520}
                              className="content-media"
                            />

                            <PrimaryCta
                              fifth
                              onClick={() =>
                                handleDownload(
                                  item.assignment_data.media_id,
                                  true,
                                  item.assignment_data?.assignment_id
                                )
                              }
                              fontSize={"font_size_large"}
                              text={"Download"}
                              isLoading={""}
                            />
                          </div>
                        )}
                    </>
                  );
                }
              })}
            </Carousel>
          </div>
        ) : (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
              className="fall-back-center-campaign"
            >
              {/* <FallBack
                heading={"No Creatives Available"}
                // title={"Please wait until you get a new proposal"}
              /> */}
            </div>
          </>
        )}
      </>
    );
  }
  const influencerContentCards = [];
  function contentAssignmentInfluencerData() {
    assignmentInfluencerList.forEach((item) => {
      const contentKey = Object.keys(item.contents_deep_data)[0];
      const content = item.contents_deep_data[contentKey].content_data;

      const influencer = item.influencer_data;
      const campaign_data = item.campaign_data;
      const assignmentId = item.assignment_data?.assignment_id;
      const platform =
        item?.platforms_data[0] && item?.platforms_data[0].identifier_id;

      // Map the data to component props
      let influencerContentCardProps = {
        imageSource: content.media_id, // Use media_id for thumbnail
        url: content.url,
        content_id: content.content_id,
        influencerDp: influencer.profile_picture, // Profile picture
        influencerId: influencer.influencer_id, // Profile picture
        influencerUsername: influencer.name, // Influencer name
        assignmentId: assignmentId,
        influencerHandle: platform, // Handle with '@'
        likes: "1.2K", // Placeholder for likes, adjust based on data
        comments: "300", // Placeholder for comments
        campaignId: props.campaignId,
        //  views: `${Math.round(platform.avg_views / 1000)}K`, // Convert views to 'K' format
        date: new Date(
          item.assignment_data.date_added * 1000
        ).toLocaleDateString(), // Convert timestamp to readable date
        videoUrl: content.url, // URL of the content
      };

      influencerContentCards.push(influencerContentCardProps);
    });
  }

  function renderCampaignContentInfluencer() {
    return (
      <>
        {contentList.length !== 0 && (
          <div className="analytics-list-header m-t-30 m-b-12">
            <CustomText
              p
              text={"Top Content"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
        )}
      </>
    );
  }
  function getInflencerLocation(influencer_data) {
    if (!influencer_data) {
      return "-";
    }

    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  function filteringData() {
    if (assignmentInfluencerList.length == 0) {
      return assignmentInfluencerList;
    }
    const filteredData = assignmentInfluencerList.filter((item) =>
      item?.platforms_data?.some((platform) => platform?.platform_id === "btll")
    );
    return filteredData;
  }

  const InfluencerCards = ({ influencerList }) => {
    if (influencerList && filteringData()) {
      return (
        <div
          useRef={influencerClass}
          className={
            "content_all_influencer_analytics_container cursor-pointer"
          }
        >
          {filteringData() &&
            filteringData()?.map((influencer, index) => (
              <div
                className="suggest-influcener-card"
                key={index}
                onClick={(e) => {
                  toggleFlyoutDropdown();
                  setInfluencerId(influencer?.influencer_data.influencer_id);
                }}
              >
                <div
                  className="suggest-influcener-card-img "
                  style={{ cursor: "pointer" }}
                >
                  {/* <img
                    src={
                      influencer?.influencer_data?.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            influencer?.influencer_data?.influencer_id,
                            influencer?.influencer_data?.profile_picture
                          )
                        : "../assets/images/user-fallback.svg"
                    }
                    alt="Profile"
                    height={200}
                  /> */}

                  <Image
                    source={
                      influencer?.influencer_data?.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            influencer?.influencer_data?.influencer_id,
                            influencer?.influencer_data?.profile_picture
                          )
                        : "../assets/images/user-fallback.svg"
                    }
                    fallBack={"../assets/images/user-fallback.svg"}
                    alt="Profile"
                    height={200}
                  />

                  {/*                
                <div className="request-price" onClick={ ""}>
                  <div className="inner-request-price" style={{fontSize : "14px" , display: 'flex'}}> 
  
                  Request Price
                  <Icon iconName="rightArrow" width={16} height={16} />
                  </div>
                </div> */}
                </div>
                <div className="suggest-influcner-card-content">
                  <div>
                    <CustomText
                      p
                      text={influencer?.influencer_data?.name}
                      className="text-new-blue text-16 font-600"
                    />
                    <CustomText
                      p
                      text={
                        influencer?.influencer_data?.state_id
                          ? `${getInflencerLocation(
                              influencer.influencer_data
                            )}, India`
                          : "-"
                      }
                      className="text-new-gray font-medium m-t-4 text-14"
                    />
                  </div>
                  <div className="suggest-card-analytics">
                    <div>
                      <p className="text-12 font-600 text-new-gray">
                        Followers
                      </p>
                      <p className="text-14 text-new-blue font-600 m-t-4">
                        {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.followers || "-"
                        )}
                      </p>
                    </div>
                    <div className="average-views-in-card">
                      <p className="text-12 font-600 text-new-gray">
                        Average Views
                      </p>
                      <p className="text-14 text-new-blue m-t-4 text-align font-600">
                        {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.avg_views || "-"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="text-12 font-600 text-new-gray text-right">
                        ER
                      </p>
                      <p className="text-14 m-t-4 text-new-blue font-600">
                        {influencer?.platforms_data[0]?.engagement_rate != null
                          ? `${influencer.platforms_data[0].engagement_rate.toFixed(
                              1
                            )}%`
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    }
  };
  function renderInfluecnerCard() {}

  function renderCostKPI() {
    return (
      <>
        <div className="analytics-list-header m-t-22">
          <CustomText
            p
            text={"Cost KPI"}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
          <div className="analytics-download ">
            {/* <div className="analytics-download-icon" onClick={downloadPdf}>
              <Icon iconName="downloadPdfIcon" />
            </div> */}
            <div></div>
          </div>
        </div>

        <>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Total Spend"}
              backgroundColor={"#deecff"}
              value={` ${
                campaignReport.total_spent
                  ? Utils.convertFromINR(
                      campaignReport.total_spent,
                      user_data.country
                    )
                  : 0
              }`}
              iconName="rupeeIcon"
            />

            <AnalyticsCard
              title={"CPV"}
              backgroundColor={"rgb(246, 227, 255)"}
              value={`  ${
                cpmValue < Infinity
                  ? Utils.convertFromINR(cpmValue.toFixed(2), user_data.country)
                  : "0"
              }`}
              iconName="rupeeIcon"
            />
            <AnalyticsCard
              title={"CPE"}
              backgroundColor={"#deecff"}
              // backgroundColor={"rgb(222 255 233)"}
              value={`Rs ${
                cpEValue < Infinity
                  ? Utils.convertFromINR(cpEValue, user_data.country)
                  : "0"
              }`}
              iconName="rupeeIcon"
            />
            <AnalyticsCard
              title={"CPF"}
              backgroundColor={"rgb(255, 240, 221)"}
              value={`Rs ${
                cpfValue > 0 && campaignReport.total_reach != 0
                  ? Utils.convertFromINR(cpfValue.toFixed(2), user_data.country)
                  : 0
              }`}
              iconName="rupeeIcon"
            />
          </div>
        </>
      </>
    );
  }

  function renderCampaignAnalytics() {
    return (
      <>
        <>
          <div className="analytics-list-header m-t-30">
            <CustomText
              p
              text={"Campaign Analytics"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Influencer Live"}
              value={
                campaignAnalytic.assignment_count
                  ? Utils.changeNumberFormate(campaignAnalytic.assignment_count)
                  : 0
              }
              iconName="liveIcons"
              backgroundColor={"rgb(255, 240, 221)"}
            />
            <AnalyticsCard
              title={"Posts Live"}
              value={
                campaignAnalytic.assignment_count
                  ? Utils.changeNumberFormate(campaignAnalytic.assignment_count)
                  : 0
              }
              iconName="heartLikeIcon"
              backgroundColor={"#deecff"}
              // backgroundColor={"rgb(222 255 233)"}
            />
            <AnalyticsCard
              title={"Views"}
              value={
                campaignReport.total_view
                  ? Utils.changeNumberFormate(campaignReport.total_view)
                  : 0
              }
              iconName="playIcon"
              backgroundColor={"rgb(246, 227, 255)"}
            />
            <AnalyticsCard
              title={"Campaign Reach"}
              value={Utils.changeNumberFormate(campaignReport.total_reach)}
              iconName="reachIcon"
              backgroundColor={"rgb(255, 240, 221)"}
            />
          </div>
        </>
        {/* <div className="">
          <div className="analytics-list-header m-t-30 ">
            <CustomText
              p
              text={"Content KPI"}
              textAlign={"text_align_left"}
              fontSize={"font_size_xl"}
              textColor={"text_color_primary"}
            />
          </div>
          <div style={{ display: "flex", gap: 20 }}>
            <div className="flex-1">
              <ViewCards>
                <div className="campaign-detail-card m-b-12">
                  <div className="w-195 flex-row gap-10">
                    <Icon iconName="engHeartIcon" height={20} />
                    <CustomText
                      p
                      text={"Like:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div>
                    <CustomText
                      p
                      text={Utils.changeNumberFormate(
                        campaignContentAnalytic?.total_like_count
                      )}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_right"}
                      noOfLine={"twoLines"}
                      textColor={"text_color_primary"}
                      fontWeight={"500px"}
                    />
                  </div>
                </div>
                <div className="campaign-detail-card m-b-12">
                  <div className="w-195 flex-row gap-10">
                    <Icon iconName="reelsCommentIcon" height={20} />
                    <CustomText
                      p
                      text={"Comments:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div>
                    <CustomText
                      p
                      text={Utils.changeNumberFormate(
                        campaignContentAnalytic?.total_comment_count
                      )}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                    />
                  </div>
                </div>

                <div className="campaign-detail-card">
                  <div className="w-195 flex-row gap-10">
                    <Icon iconName="percentageIcon" height={20} />
                    <CustomText
                      p
                      text={"Engagement Rate:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div>
                    <CustomText
                      p
                      text={`${
                        avgEngagement ? avgEngagement.toFixed(2) : "0"
                      } %`}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                    />
                  </div>
                </div>
              </ViewCards>
            </div>
            <div className="flex-1">
              <ViewCards>
                <div className="campaign-detail-card m-b-12">
                  <div className="w-195 flex-row gap-10">
                    <Icon iconName="reelstIcon" height={20} />
                    <CustomText
                      p
                      text={"Reel Views:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div>
                    <CustomText
                      p
                      text={Utils.changeNumberFormate(
                        campaignContentAnalytic.total_play_count
                      )}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_right"}
                      noOfLine={"twoLines"}
                      textColor={"text_color_primary"}
                      fontWeight={"500px"}
                    />
                  </div>
                </div>
                <div className="campaign-detail-card m-b-12">
                  <div className="w-195 flex-row gap-10">
                    <Icon iconName="percentageIcon" />
                    <CustomText
                      p
                      text={`View Rate:`}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div>
                    <CustomText
                      p
                      text={`${
                        campaignReport.total_view && campaignReport.total_reach
                          ? (
                              campaignReport.total_view /
                              campaignReport.total_reach
                            ).toFixed(2)
                          : "0"
                      } %`}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                    />
                  </div>
                </div>
              </ViewCards>
            </div>
          </div>
        </div> */}
      </>
    );
  }

  function renderContentKPI() {
    return (
      <>
        <div className="analytics-list-header m-t-30 ">
          <CustomText
            p
            text={"Content Analytics "}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
        </div>

        <>
          <div className="analytics-card-container ">
            <AnalyticsCard
              title={"Likes"}
              backgroundColor={"rgb(246, 227, 255)"}
              value={Utils.changeNumberFormate(
                campaignContentAnalytic?.total_like_count
              )}
              iconName="thumbUpIcon"
            />

            <AnalyticsCard
              title={"Comments"}
              backgroundColor={"#deecff"}
              value={Utils.changeNumberFormate(
                campaignContentAnalytic?.total_comment_count
              )}
              iconName="commentIcon"
            />
            <AnalyticsCard
              title={"Views"}
              backgroundColor={"rgb(255, 240, 221)"}
              value={`${
                campaignContentAnalytic.total_play_count
                  ? Utils.changeNumberFormate(
                      campaignContentAnalytic.total_play_count
                    )
                  : "0"
              }`}
              iconName="playIcon"
            />
            <AnalyticsCard
              title={"Engagement Rate"}
              backgroundColor={"#deecff"}
              // backgroundColor={"rgb(222 255 233)"}
              value={`${avgEngagement ? avgEngagement.toFixed(2) : "0"} %`}
              iconName="engagementIcon"
            />
          </div>
        </>
      </>
    );
  }

  function renderInfluencerCategoryPerformance() {
    return (
      <>
        <div className="analytics-list-header m-t-30 ">
          <CustomText
            p
            text={"Influencer category Performance"}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
        </div>
        <div className="flex-row gap-10">
          <div>
            <ViewCards view_cards>
              <div className="flex-row gap-10">
                <CustomText
                  p
                  text={"Influencer Category"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="liveInfluencerIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Influencers Live:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="postLiveIcon" />
                  <CustomText
                    p
                    text={"Posts Live:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="campaignReachIcon" />
                  <CustomText
                    p
                    text={"Campaign Reach:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="engHeartIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Engagements:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="reelstIcon" height={20} width={20} />
                  <CustomText
                    p
                    text={"Reel Views:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="percentageIcon" />
                  <CustomText
                    p
                    text={"Engagement Rate:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="campaign-detail-card m-b-12">
                <div className="flex-row gap-10">
                  <Icon iconName="percentageIcon" />
                  <CustomText
                    p
                    text={`View Rate`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Nano(1K-10K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Micro (10K-100K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Macro (100K-500K)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"Mega (500K-1M)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
          <div>
            <ViewCards view_cards>
              <div className="">
                <CustomText
                  p
                  text={"A-Listers (1M+)"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
            </ViewCards>
            <ViewCards view_cards>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
              <div className="analytics-detail-column m-b-12">
                <div className="">
                  <CustomText
                    p
                    text={`-`}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
              </div>
            </ViewCards>
          </div>
        </div>
      </>
    );
  }

  // input.classList.add("download-mode");
  console.log("pdfRef");

  return (
    <div style={{ minHeight: 500, position: "relative" }} className="">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!campaignReport && !campaignAnalytic ? (
            <>{renderFallback()}</>
          ) : (
            <>
              <div className="analytics-download-icon">
                <PrimaryCta
                  invert
                  className="pdf-btn-campaign"
                  onClick={downloadPdf}
                  isLoading={pdfLoader}
                  // fontSize={"font_size_large"}
                  text={"Save PDF"}
                />
              </div>
              <div className="pdf-save-container" ref={pdfRef}>
                {renderCostKPI()}
                {renderCampaignAnalytics()}
                {renderContentKPI()}
                {contentAssignmentInfluencerData()}
                {renderCampaignContentInfluencer()}

                <div className="content_all_influencer_analytics_container">
                  {influencerContentCards.map((item) => {
                    return <InfluencerContentCard item={item} />;
                  })}
                </div>
                {assignmentInfluencerList.length != 0 && (
                  <div>
                    <div className="analytics-list-header m-t-30 m-b-12">
                      <CustomText
                        p
                        text={"Top Influencers"}
                        textAlign={"text_align_left"}
                        fontSize={"font_size_xl"}
                        textColor={"text_color_primary"}
                      />
                    </div>

                    <InfluencerCards
                      influencerList={assignmentInfluencerList}
                    />
                  </div>
                )}
                {/* <div className="m-l-10">{renderCampaignContent()}</div> */}

                {/* <>{renderInfluencerCategoryPerformance()}</> */}
                {influencerFlyOut && (
                  <InfluencerDetailModal
                    influencerFlyOut={influencerFlyOut}
                    toggleFlyoutDropdown={toggleFlyoutDropdown}
                    influencer_id={influencerId}
                    org_id={brand_deep_data.brand_data.org_id}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AnalyticsCampaign;
