"use-strict";

import { useEffect, useState } from "react";
import { CampaignPlan, CustomText, PrimaryCta } from "../../components";
import SubscriptionPlan from "../../modal/planSubscription";
import ReviewCampaign from "./reviewCampaign";
import { param } from "jquery";
import Core from "../../common/clientSdk";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import React from "react";
import CampaignBestMatchInfluencer from "./campaignBestMatchInfluencer";
import CreateCampaignSuccess from "./createCampaignSuccess";
import { ToastContainer, toast } from "react-toastify";
function CampaignSubscriptionPlan(props) {
  const [showSubscription, setShowSubscription] = useState(true);
  const [showSubscriptionActive, setShowSubscriptionActive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkoutSummary, setCheckoutSummary] = useState();
  const [subscriptionBasicDetail, setSubscriptionBasicDetail] = useState(null);
  const [createCampaignResponse, setCreateCampaignResponse] = useState(null);
  const [bestMatchModal, setBestMatchModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(false);

  useEffect(() => {
    subscriptionUserDetailBasic();
  }, []);
  const endDate = props?.campaignDeepData?.campaign_data?.end_date;
  const deliveryDate = props?.campaignDeepData?.campaign_data?.delivery_date;
  const title = props?.campaignDeepData?.campaign_data?.name;
  const maxBidAmount = props?.campaignDeepData?.campaign_data?.max_budget;
  const imageId = props?.campaignDeepData?.sample_content_data;

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  function checkCredits() {
    return org_deep_data?.org_data?.credits > 9;
  }
  function subscriptionUserDetailBasic() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
    };

    Core.subscriptionUserDetailBasic(
      subscriptionUserDetailBasicSuccess,
      subscriptionUserDetailBasicFail,
      () => {},
      params
    );
  }
  function subscriptionUserDetailBasicFail(error) {
    toast.error(error);
  }
  function subscriptionUserDetailBasicSuccess(response, data) {
    setSubscriptionBasicDetail(data.response);
  }

  function subscriptionUserCreateCreditNew() {
    const params = {
      org_id: org_deep_data?.org_data?.org_id,
      c_id: checkoutSummary?.credit_id,
      quantity: checkoutSummary?.quantity,
      value: checkoutSummary?.actual_value
        ? checkoutSummary?.actual_value
        : checkoutSummary?.value,
      redirect_url: window.location.origin + "/create-campaign",
      platform: "web",
    };

    Core.subscriptionUserCreateCreditNew(
      subscriptionUserCreateCreditNewSuccess,
      subscriptionUserCreateCreditNewFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function subscriptionUserCreateCreditNewSuccess(response, data) {
    console.log("demo1", response);
    console.log("demo2", data);

    // const pgOrderId = response.credit_order_data.pg_order_id;
    // redirectToPaymentPage(pgOrderId);
    createCreditOrder(data.credit_order_data);
    // createCampaign();
  }

  const createCreditOrder = (order_data) => {
    console.log("order_data", order_data);

    // Make an API call to your server to create a test order
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        console.log("demo3", response);
        createCampaign();
        if (response) {
        }

        // getTransactionHistoryList(start, updateProgress);
        // getOrgDataUser();
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  console.log("kkkkkkkkkkk", checkoutSummary);

  function subscriptionUserCreateCreditNewFail() {}
  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    console.log("pg order", pgOrderId);

    // const paymentUrl = `https://mercury-t2.phonepe.com/transact/pg?token=${pgOrderId}`;
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }
  function handleClick() {
    setShowSubscription(!showSubscription);
  }

  const openModalSuccess = () => {
    if (modalIsOpenSuccess == false) {
      setModalIsOpenSuccess(true);
    } else {
      setModalIsOpenSuccess(false);
    }
  };

  const closeModalSuccess = () => {
    setModalIsOpenSuccess(false);
  };

  function CreateCampaignAndBuyCredits() {
    if (!checkCredits()) {
      subscriptionUserCreateCreditNew();
    } else {
      createCampaign();
    }

    // props.nextStep();
  }

  function createCampaign() {
    const params = {
      campaign_deep_data: JSON.stringify(props.campaignDeepData),
    };
    Core.createCampaignUser(
      createCampaignUserSuccess,
      createCampaignUserFailed,
      (status) => setIsLoading(status),
      params
    );
  }

  function createCampaignUserSuccess(response) {
    setCreateCampaignResponse(response?.campaign_deep_data);
    // closeModal();
    // // openModalSuccess();
    // openModalBestMatch();

    openModalSuccess();

    setTimeout(() => {
      closeModalSuccess();
      openModalBestMatch();
    }, 2000);
  }
  console.log("createCampaignResponse", createCampaignResponse);

  function createCampaignUserFailed(errorList) {
    // closeModal();
    // toast.info(errorList.map((item) => item.m).join(", "));
    // setLoading(false);
  }
  const openModalBestMatch = () => {
    if (bestMatchModal == false) {
      setBestMatchModal(true);
    } else {
      setBestMatchModal(false);
    }
  };

  const closeModalBestMatch = () => {
    setBestMatchModal(false);
  };
  const handleBack = () => {
    props.previousStep();
  };

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  console.log("subscriptionBasicDetail", subscriptionBasicDetail);

  function checkoutPerCampaign() {
    return (
      <div className="p-20">
        <div>
          <CustomText p text={"Checkout"} className="h6Text" />
        </div>

        <div className="m-t-20">
          <CustomText p text={"Post Campaign Summary"} className="button2" />
          <div className="purchase-summary-inner-container  m-t-12 m-b-10">
            <CustomText
              p
              text={"Total Available Credits"}
              className="typographyH5"
            />
            <CustomText
              p
              // text={checkoutSummary?.quantity}
              text={org_deep_data?.org_data?.credits}
              className="typographyH5 "
            />
          </div>
          <div className="purchase-summary-inner-container  m-t-12 m-b-10">
            <CustomText
              p
              text={"Campaign Credits Charge"}
              className="typographyH5"
            />
            <CustomText
              p
              // text={checkoutSummary?.quantity}
              text={"10"}
              className="typographyH5 "
            />
          </div>

          <div className="dash-border-bottom m-b-10 m-t-16"></div>
          <div className="purchase-summary-inner-container p-b-10 ">
            <CustomText p text={`After Deduction`} className="typographyH5 " />

            <CustomText
              p
              // text={Utils.convertFromINR(
              //   checkoutSummary?.value,
              //   props.user_data.country
              // )}
              text={org_deep_data?.org_data?.credits - 10}
              className="typographyH5 "
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="create-campaign-checkbox-main-container">
      {checkCredits() ? (
        checkoutPerCampaign()
      ) : (
        <>
          <div className="checkout-summary-header-container">
            <CustomText
              p
              className="button1"
              text={
                "You need a minimum of 10 credits to create this campaign. Currently, you don’t have enough credits"
              }
              textColor={""}
              fontWeight={"Inter_regular"}
            />
          </div>
          {subscriptionBasicDetail?.basic_subscription_data && (
            <SubscriptionPlan
              setCheckoutSummary={setCheckoutSummary}
              basic_subscription_data={
                subscriptionBasicDetail?.basic_subscription_data
              }
              active_subscription_data={
                subscriptionBasicDetail?.active_subscription_data
              }
              user_data={user_data}
            />
          )}
        </>
      )}

      <>
        <div className="divide-line"></div>
        <div className="create-campaign-bottom-container">
          <div className="type-campaign-btn">
            {/* <PrimaryCta
              fifth
              className=""
              onClick={openModal}
              fontSize={"font_size_large"}
              text={"Preview"}
            /> */}
          </div>

          <div className="type-campaign-btn">
           
            <div style={{ maxWidth: 150 }}>
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={handleBack}
              fontSize={"font_size_large"}
              text={"Cancel"}
            />
                    </div>

            <PrimaryCta
              fullWidth={true}
              isLoading={loading}
              className="blue-button"
              onClick={() => CreateCampaignAndBuyCredits()}
              fontSize={"font_size_large"}
              text={"Submit"}
            />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            zIndex={9999999}
          />
        </div>
      </>

      {bestMatchModal && (
        <CampaignBestMatchInfluencer
          createCampaignResponse={createCampaignResponse}
          closeModal={closeModalBestMatch}
        />
      )}

      {modalIsOpenSuccess && (
        <CreateCampaignSuccess closeModal={closeModalSuccess} />
      )}
      {modalIsOpen && (
        <ReviewCampaign
          closeModal={closeModal}
          // handleNext={handleNext}
          imageId={imageId}
          // isLoading={isLoading}
          title={title}
          maxBidAmount={maxBidAmount}
          endDate={endDate}
          deliveryDate={deliveryDate}
          instructionNote={
            "<p>&nbsp;Script and Instruction for Creator&nbsp;</p>"
          }
          props={props}
          // postSaveCampaignUser={postSaveCampaignUser}
          // isLoadingDraft={isLoadingDraft}
        />
      )}
    </div>
  );
}

export default CampaignSubscriptionPlan;
