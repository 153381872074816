import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { AnalyticsCard, AssignmentList, CustomText } from "../../components";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import SideMenu from "../sideMenu";
import Header from "../header";

import { useNavigate } from "react-router-dom";

function BrandAnalytics() {
  const [isLoading, setLoading] = useState(false);
  const [brandReport, setBrandReport] = useState("");
  const [brandAnalytic, setBrandAnalytic] = useState("");
  const [brandContentAnalytic, setBrandContentAnalytic] = useState("");
  const [assignmentList, setAssignmentList] = useState([]);

  const pdfRef = useRef();

  const navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;

  const { user_data = {} } = UserData;

  useEffect(() => {
    getBrandReports();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getBrandReports() {
    if (redux_selector.UserData.brand_deep_data) {
      var params = {
        brand_id: UserData?.brand_deep_data?.brand_data?.brand_id,
      };
      Core.getBrandReports(
        getBrandReportsSuccess,
        getBrandReportsFailed,
        updateProgress,
        params
      );
    }
  }

  function getBrandReportsSuccess(response) {
    setBrandReport(response);
    getAssignmentBrandDetails(updateProgress);
  }

  function getBrandReportsFailed(errorList) {
    //    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getAssignmentBrandDetails(progress_cb) {
    var params = {
      brand_id: UserData.brand_deep_data.brand_data.brand_id,

      invert: true,
      status: "COMPLETED",
    };
    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      progress_cb,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
  }

  function getAssignmentBrandDetailsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function currencyConverter(value) {
    return Utils.convertFromINR(value, user_data.country);
  }
  let Engagement =
    Number(brandContentAnalytic.total_like_count) +
    Number(brandContentAnalytic.total_comment_count);
  let avgEngagement = (Engagement / Number(brandReport.total_reach)) * 100;
  let cpfValue = brandReport.total_spent / brandReport.total_reach;
  let cpEValue = brandReport.total_spent / Engagement;
  let cpmValue = brandReport.total_spent / (brandReport.total_view * 1000);
  function ContentReachData() {
    return (
      <div className="analytics-social-media-data">
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="heartIcon" />
            <CustomText
              p
              text={"Like"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"76k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            <Icon iconName="commentIcon" />
            <CustomText
              p
              text={"Comment"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="viewEyes" />{" "}
            <CustomText
              p
              text={"View Rate"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />{" "}
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail">
          <div className="flex-row">
            <Icon iconName="rupeeIcon" />
            <CustomText
              p
              text={"CPV"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"90k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
      </div>
    );
  }

  const downloadPdf = () => {
    const input = pdfRef.current;
    console.log("pdf", input);
    html2canvas(input).then((canvas) => {
      const imageURL = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "mm", "a3", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imageURL,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Analytics.pdf");
    });
  };

  function renderBrandAnalytics() {
    return (
      <>
        <AnalyticsCard
          title={"Total Campaign Views"}
          value={brandReport.total_view}
          iconName="viewEyes"
        />
        <AnalyticsCard
          title={"Total Proposal"}
          value={brandReport.total_bids}
          iconName="heartLikeIcon"
        />
        <AnalyticsCard
          title={"Total Spent"}
          value={` ${currencyConverter(brandReport.total_spent)}`}
          iconName="rupeeIcon"
        />
        <AnalyticsCard
          title={"Total Contracts"}
          value={brandReport.total_contract}
          iconName="AssignmentIconActive"
        />
      </>
    );
  }

  function navigateToAssignmentDetails(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }
  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />

        <div className="page-container">
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <div className="campaign-text-heading">
                <CustomText
                  p
                  text={"Brands Analytics"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_2xl"}
                />
              </div>
              <div className="analytics-card-container">
                {" "}
                {renderBrandAnalytics()}{" "}
              </div>

              <>
                {assignmentList && assignmentList.length > 0 && (
                  <div>
                    <div className="campaign-text-heading see-all-button">
                      <CustomText
                        p
                        text={"Active Contracts"}
                        textColor={"text_color_primary"}
                        fontSize={"font_size_2xl"}
                      />
                      <div
                        className="see-all-main-btn"
                        onClick={() => {
                          navigate("/contracts");
                        }}
                      >
                        <CustomText
                          p
                          text={"See All"}
                          textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                        />
                      </div>
                    </div>
                    {assignmentList.slice(0, 3).map((row) => (
                      <div className="space-list ">
                        <AssignmentList
                          onClick={() =>
                            navigateToAssignmentDetails(
                              row.assignment_data.assignment_id
                            )
                          }
                          source={
                            row.influencer_data.profile_picture
                              ? Utils.generateInfluencerDpUrl(
                                  row.influencer_data.influencer_id,
                                  row.influencer_data.profile_picture
                                )
                              : require("../../resources/images/user-copy.png")
                          }
                          influencerName={row.influencer_data.name}
                          rewardText={currencyConverter(
                            Number(row?.reward_data?.value?.amount)
                          )}
                          deliverablesText={Utils.generateAssignmentSubText(
                            row
                          )}
                          status={Utils.assignmentStatusToString(
                            row.assignment_data.assignment_status
                          )}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrandAnalytics;
