import React, { useState, useEffect, useRef } from "react";
import {
  CustomButton,
  CustomText,
  CustomTextInput,
  FallBack,
  FallBackBtn,
  ModalComponent,
  PrimaryCta,
  Sidebar,
  TeamMembers,
  TransitionHistory,
} from "../../components";
import Icon from "../../components/core_component/icon";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
import Modal from "react-modal";
import validator from "validator";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import SubscriptionModal from "../../modal/subscriptionModal";
import CustomTable from "../../components/customTable";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import styled from "@emotion/styled";
import { hasAllRoles } from "../../common/data/campaign";
import CampaignData from "../../common/data/campaign";
import UpdateInviteMember from "../../modal/updateInviteMember";
import Utils from "../../common/utils";
import { useToast } from "../../features/toastContainer";

function TeamMembersListTab() {
  const ref = useRef();
  const [memberList, setMemberList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadingRemove, setLoadingRemove] = useState(false);
  const [checkOrgSubUser, setCheckOrgSubUser] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [inviteId, setInviteId] = useState(null);
  const [loading, set_loading] = useState(false);
  const [inviteSidebarOpen, setInviteSidebarOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState(["IN"]);
  const [inviteIsOpen, setInviteIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [customRole, setCustomRole] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [response, setResponse] = useState([]);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [contactMember, setContactMember] = useState();
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [openSnackbar] = useSnackbar();
  const showToast = useToast();
  const [countrySelected, setCountrySelected] = useState([
    {
      name: "India",
      calling_code: "+91",
      country_code: "IN",
      currency: {
        code: "INR",
        name: "Indian Rupee",
        symbol: "₹",
      },
    },
  ]);

  const [error, set_error] = useState({
    member_name: "",
    w_number: "",
    email: "",
  });

  var navigate = useNavigate();

  const callbackFunction = (value) => {
    setResponse(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("sving fat");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    getUserRoleList();
    getMembersList(updateProgress0);
    userSelfData();
    getBrandList(updateProgress0);
  }, []);

  function updateProgress0(status) {
    setLoading(status);
  }
  function updateProgress(status) {
    set_loading(status);
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      toast.error("Please enter name");
    } else {
      set_error({ ...error, member_name: "" });
      return true;
    }
  }
  function validateCountry(value) {
    if (value) {
    }
  }
  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      toast.error("Please enter valid email");
      return false;
    }
    return true;
  }
  function validateBrandsSelect() {
    if (brandId.length <= 0) {
      toast.error("Please Select Brand");
      return false;
    }

    return true;
  }
  function validatePermissionsSelect() {
    if (selectedPermission.length <= 0) {
      toast.error("Please Select Permission");
      return false;
    }

    return true;
  }
  function validateNumber() {
    if (!number) {
      toast.error("Please Enter Whatsapp Number");
      return false;
    }

    return true;
  }
  function validateCustomRole() {
    if (selectedRole[0]?.value == "custom_role" && customRole == "") {
      toast.error("Please Enter Custom Role");
      return false;
    }
    return true;
  }
  function validateSelectedRole() {
    if (selectedRole.length <= 0) {
      toast.error("Please Select Role");
      return false;
    }
    return true;
  }

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { org_deep_data = {} } = UserData;

  function getBrandList(progressCb) {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.brandList(brandListSuccess, brandListFailed, progressCb, params);
  }

  function brandListSuccess(response) {
    setBrandList(response.brand_list);
  }

  function brandListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function getUserRoleList() {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.getUserRoleList(
      getUserRoleListSuccess,
      getUserRoleListFailed,
      () => {},
      params
    );
  }

  function getUserRoleListSuccess(response, data) {
    setUserRole(data.role_list);
    setLoading(false);
  }

  function getUserRoleListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postInviteMembers() {
    var params = {
      name: memberName,
      country: "IN",
      phone_number: removePlusSign(number),
      role_name: selectedRole[0]?.value == "custom_role" && customRole,
      role_id: selectedRole[0]?.value,
      email: email,
      role_data: JSON.stringify({
        role_id: selectedRole[0]?.value,
        brand_ids: brandId.map((item) => item.value),
        //  permissions: selectedPermission.map((item) => item.value),
      }),
      org_id: brand_deep_data?.brand_data?.org_id,

      //  type: selectedRole[0]?.type,
    };
    console.log("params", params);

    if (
      validateName(memberName) &&
      validateNumber() &&
      validateEmail(email) &&
      validateBrandsSelect() &&
      validateSelectedRole()
      // validatePermissionsSelect() &&
      // validateCustomRole()
    ) {
      Core.postInviteMembers(
        postInviteMembersSuccess,
        postInviteMembersFailed,
        (status) => set_loading(status),
        params
      );
    }
  }

  function postInviteMembersSuccess(response_data) {
    // toast.success("Successfully send");
    setMemberList((prev) => [...prev, response_data.members_data]);
    inviteToggle();
  }

  function postInviteMembersFailed(errorList) {
    toast.error(errorList);
  }

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (number) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }

  function updateCounty(selectedItem) {
    console.log("selectedItem", selectedItem);
    setCountrySelected(selectedItem);

    setCountry(
      selectedItem ? selectedItem.map((option) => option.country_code) : []
    );
  }

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSubUser(response?.org_data?.subscription_data);
  }
  function userSelfDataFailed() {}

  function getMembersList(progress_cb) {
    var params = {
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    Core.getMembersList(
      getMembersListSuccess,
      getMembersListFailed,
      progress_cb,
      params
    );
  }

  function getMembersListSuccess(response_data) {
    setMemberList(response_data.members_data);
    setLoading(false);
  }

  function getMembersListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postRemoveMembers() {
    var params = {
      invite_id: inviteId,
    };
    Core.postRemoveMembers(
      postRemoveMembersSuccess,
      postRemoveMembersFailed,
      (status) => setLoadingRemove(status),
      params
    );
  }

  function postRemoveMembersSuccess(response_data) {
    console.log("response s", response_data);

    removeMemberModal();
    setMemberList((prev) => {
      return prev.filter((member) => member.invite_id !== inviteId);
    });
  }

  function postRemoveMembersFailed(errorList) {
    console.log("response f", errorList);
    removeMemberModal();
    openSnackbar(errorList);
    removeMemberModal();
  }

  var navigate = useNavigate();

  function maxBidCountCheck() {
    if (checkOrgSubUser?.feature?.max_brand_count) {
      return checkOrgSubUser?.feature?.max_brand_count;
    }
    return 0;
  }

  function checkSubscriptionOrNot() {
    if (!checkOrgSubUser?.feature?.max_brand_count) {
      openModal();
    } else if (memberList?.length >= 3) {
      toast.info("you can invite only 3 members");
    } else {
      setInviteSidebarOpen(true);
      // navigate("/invite-member");
    }
  }

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  function removeMemberModal(id) {
    setModalIsOpenRemove(!modalIsOpenRemove);
    setInviteId(id);
  }
  function ActionOnLoadHandler(subscription_list) {
    setSubscriptionList(subscription_list);
  }
  const StyledItem = styled.div`
    padding: 10px;
    color: #555;
    border-radius: 3px;
    margin: 3px;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    :hover {
      background: #f2f2f2;
    }
  `;
  function updateRole(selectedItem) {
    console.log("selectedItem sur", selectedItem);

    setSelectedRole(selectedItem);
  }
  function updateBrandId(selectedItem) {
    console.log("selectedItem ss", selectedItem);
    var value = [];
    selectedItem.forEach((item) => {
      value.push(item.value);
    });

    // setBrandId(value);
    setBrandId(selectedItem);
  }

  function roleFilter() {
    return CampaignData.rolePermissions.filter(
      (item) => item.finance == selectedRole[0]?.id
    );
  }

  function handleSelection(selectedList, selectedItem) {}

  function selectedPageRole(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    console.log("selectedList", selectedList);
    setSelectedPermission(selectedList);
  }
  console.log("selectedPermission", selectedPermission);

  function inviteToggle(memberData) {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["invite_member"]
      )
    ) {
      showToast();
      //   toast.info("You are not authorised to invite member");
    } else {
      setInviteIsOpen(!inviteIsOpen);
    }

    setMemberName("");
    setEmail("");
    setNumber("");
    setSelectedRole([]);
    setCustomRole("");
    setSelectedPermission([]);
    setBrandId([]);
  }

  function inviteHeader() {
    return (
      <CustomText p text={"Invite Member"} className="side-bar-heading-text" />
    );
  }

  function inviteFooter() {
    return (
      <PrimaryCta
        className="btn-color-blue"
        fontSize={"font_size_small"}
        fullWidth={true}
        text={"Send Invite"}
        isLoading={loading}
        onClick={() => postInviteMembers()}
      />
    );
  }

  const toggleExpandCategory = (categoryId) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  function inviteUpdateToggle(memberData) {
    setMemberData(memberData);
    setUpdateIsOpen(!updateIsOpen);
  }
  function brandListArray() {
    const brandOption = brandList.map((item) => ({
      value: item.brand_data.brand_id,
      label: item.brand_data.name,
    }));

    return brandOption;
  }

  function renderRoleOptions() {
    const roleOptions = userRole.map((item) => {
      const value = item?.role_id || "custom_role";
      const label = item?.role_name;
      const permissions = item?.permissions || [];

      return {
        value,
        label,
        permissions,
      };
    });

    return roleOptions;
  }

  function renderPermissionOptions() {
    if (
      Array.isArray(selectedRole) &&
      selectedRole.length > 0 &&
      selectedRole[0]?.value == "custom_role"
    ) {
      console.log("ram 1", selectedRole[0]?.permissions.permissions);
      const formattedOptions = Object.entries(
        Object.values(selectedRole[0]?.permissions.permissions)
      ).flatMap(([_, module]) => {
        console.log("ram", module);

        const category = module.module_name; // Use the module name as category
        return Object.values(module.permissions).map((perm) => ({
          module: module.name,
          name: perm.name,
          value: perm.value,
        }));
      });
      return formattedOptions;
    }
  }

  function renderInviteMember() {
    return (
      <>
        <Sidebar
          isOpen={inviteIsOpen}
          sidebarWidth={"30%"}
          onClose={inviteToggle}
          Header={inviteHeader}
          Footer={inviteFooter}
          headerAlignment="left"
        >
          <div className="p-20">
            <div className="">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Name"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className=" full-width">
                <CustomTextInput
                  type="name"
                  placeholder="Name"
                  value={memberName}
                  onChange={(e) => setMemberName(e.target.value)}
                />
              </div>
            </div>
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Phone number"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div className=" whatsapp-number">
                <PhoneInput
                  defaultCountry="IN"
                  value={number}
                  onChange={setNumber}
                  placeholder="Enter phone number"
                />
              </div>
            </div>
            <div className="m-t-16">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Email"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  //   onBlur={(e) => validateEmail(e.target.value)}
                />
              </div>
            </div>
            {org_deep_data.org_data?.type == "agency_manager" && (
              <div className="m-t-16 invite-permission-container invite-permission-inner-container">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Select brand"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select brands"
                  id="css_custom"
                  options={brandListArray()}
                  displayValue="label"
                  showCheckbox
                  // customOption={customOptionRenderer}
                  selectedValues={brandId}
                  onSelect={(selectedList, selectedItem) =>
                    updateBrandId(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateBrandId(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  // groupBy="name"

                  showArrow={false}
                  style={{
                    chips: Style.chipsInvite,
                    option: Style.optionInvite,
                    multiselectContainer: Style.multiselectContainerInvite,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            )}

            <div className="m-t-16 invite-permission-inner-container">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select role"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={renderRoleOptions()}
                values={selectedRole}
                onChange={(value) => updateRole(value)}
                //  labelField="role_name"
                // valueField="role_id"
                itemRenderer={({ item, methods }) => (
                  <StyledItem>
                    <div onClick={() => methods.addItem(item)}>
                      <input
                        type="checkbox"
                        onChange={() => methods.addItem(item)}
                        checked={methods.isSelected(item)}
                      />
                      {item.label}
                    </div>
                  </StyledItem>
                )}
                placeholder="Select "
                autoFocus={false}
                color="#C8C2C2"
                style={{
                  border: "1px solid #cbd2d9",
                  backgroundColor: "#fff",
                  fontSize: 14,
                  borderRadius: 4,
                  padding: 10,

                  option: {
                    backgroundColor: "red",
                    margin: 0,
                  },
                }}
              />
            </div>
            {selectedRole[0]?.value == "custom_role" && (
              <div className="m-t-16">
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Role name"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <div>
                  <CustomTextInput
                    type="text"
                    value={customRole}
                    placeholder="Enter custom role name"
                    onChange={(e) => setCustomRole(e.target.value)}
                  />
                </div>
              </div>
            )}
            {selectedRole[0]?.value == "custom_role" && (
              <div className="m-t-16 invite-permission-container">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Select permissions"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select permissions"
                  id="css_custom"
                  options={renderPermissionOptions()}
                  displayValue="name"
                  groupBy="module"
                  showCheckbox
                  // customOption={customOptionRenderer}
                  // selectedValues={roleFilter()}
                  onSelect={(selectedList, selectedItem) =>
                    selectedPageRole(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    selectedPageRole(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  // groupBy="name"

                  showArrow={false}
                  style={{
                    chips: Style.chipsInvite,
                    option: Style.optionInvite,
                    multiselectContainer: Style.multiselectContainerInvite,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            )}

            {/* <div className="permission-card-main-container">
              <div className="card-in-grid">
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
                <div className="permission-card">campaign</div>
              </div>
            </div> */}
            <ToastContainer />
          </div>
        </Sidebar>
      </>
    );
  }

  function renderRemoveMember() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={removeMemberModal}
        style={CancelReminder}
      >
        <div
          onClick={removeMemberModal}
          className="close-btn-icon-subscription"
        >
          <Icon iconName="CrossBtnIcon" />
        </div>

        <div className="btn-accept-reject">
          <div className="confirm-cancelliation">
            <h2 className="cancel-text">Remove Member</h2>
            <p className="cancel-request m-b-10">
              Are you sure, you want to remove this member?
            </p>
            <div className="m-t-20">
              <PrimaryCta
                fifth
                onClick={() => postRemoveMembers()}
                fontSize={"font_size_regular"}
                className="blue-button"
                text={"Remove"}
                isLoading={isLoadingRemove}
              />

              <button
                className="confirm-btn-bid  m-l-32"
                onClick={() => removeMemberModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  function closeInviteMemberSidebar() {
    setInviteSidebarOpen(false);
  }
  const inviteSidebarHeader = () => <div>Invite Member</div>;
  const inviteSidebarFooter = () => (
    <div className="full-width">
      <PrimaryCta
        className="btn-color-blue"
        fontSize={"font_size_large"}
        fullWidth={true}
        text={"Send Invite"}
        isLoading={loading}
        onClick={() => {
          postInviteMembers(updateProgress);
        }}
      />
    </div>
  );
  function inviteMember() {
    return (
      <div>
        <Sidebar
          isOpen={inviteSidebarOpen}
          sidebarWidth={"30%"}
          onClose={closeInviteMemberSidebar}
          Header={inviteSidebarHeader}
          Footer={inviteSidebarFooter}
        >
          <div className="p-20">
            <div className="m-b-10">
              <CustomText
                p
                text={"Name"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>

            <div className=" ">
              <CustomTextInput
                type="name"
                placeholder="Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                // onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.member_name}</h5>
            </div>
            <div className="m-b-10">
              <CustomText
                p
                text={"Email"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>
            <div className=" m-b-10">
              <CustomTextInput
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            <div className="m-b-10">
              <CustomText
                p
                text={"Phone Number"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>
            <div className="m-b-10 whatsapp-number">
              <PhoneInput
                defaultCountry="IN"
                value={number}
                onChange={setNumber}
                placeholder="Enter phone number"
              />
            </div>
          </div>
        </Sidebar>
      </div>
    );
  }

  const customBidStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "14px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };
  const inviteMemberTableSchema = [
    {
      name: "Name",
      selector: (row) => row["name"],
      width: "300px",
    },
    {
      name: "Email",
      selector: (row) => row["email"],
    },
    {
      name: "Status",
      selector: (row) =>
        row?.is_accepted == true ? "Accepted" : "Not Accepted",
      // width: "100px",
    },
    {
      name: "Role",
      selector: (row) => Utils.getRoleName(userRole, row?.role_id),
      // width: "100px",
    },
    {
      name: "Brands",
      selector: (row) => "",
      cell: (data) => {
        if (!brandList || !Array.isArray(data?.brand_ids)) {
          return <span>All</span>;
        }

        const brandNames = Utils.renderBrandName(brandList, data?.brand_ids);

        return (
          <span>
            {Array.isArray(brandNames)
              ? brandNames.map((item) => item?.name).join(" , ")
              : "All"}
          </span>
        );
      },
    },

    {
      name: "",
      // paddingRight: "20px",
      textAlign: "right",
      cell: (item) => (
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div className="">
            <PrimaryCta
              fifth
              onClick={() => removeMemberModal(item.invite_id)}
              text={"Remove"}
              fontSize={"font_size_small"}
              className="blue-button"
            />
          </div>
        </span>
      ),
    },
  ];
  return (
    <>
      <div style={{ minHeight: "500px" }}>
        {isLoading ? (
          <div className="loader-mid">
            <Loader />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <div className="setting-team-member-header">
              <PrimaryCta
                fifth
                onClick={() => inviteToggle()}
                text={"Invite Member"}
                fontSize={"font_size_small"}
                className="blue-button p-l-r-20"
              />
            </div>
            {memberList && memberList?.length == 0 ? (
              <div className="fall-back-center-campaign">
                <FallBackBtn
                  heading={"No Member available"}
                  title={"Active member will be shown here"}
                  // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                />
              </div>
            ) : (
              <div class="">
                <div style={{ paddingBottom: "20px" }}>
                  <CustomTable
                    className="CustomTable"
                    tableSchema={inviteMemberTableSchema}
                    tableData={memberList}
                    rowPaddingTop={16}
                    onRowClicked={inviteUpdateToggle}
                    rowPaddingBottom={16}
                    customStyles={customBidStyles}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {modalIsOpenRemove && renderRemoveMember()}
      {modalIsOpen && (
        <SubscriptionModal
          subscriptionData={subscriptionList}
          closeModal={closeModal}
          orgData={org_deep_data?.org_data}
        />
      )}
      {inviteMember()}
      {renderInviteMember()}
      {
        <UpdateInviteMember
          inviteUpdateToggle={inviteUpdateToggle}
          updateIsOpen={updateIsOpen}
          memberData={memberData}
          setMemberList={setMemberList}
        />
      }
      {/* <ReportSidebar /> */}
    </>
  );
}

export default TeamMembersListTab;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    height: "28%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
    padding: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
