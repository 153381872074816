import { max } from "date-fns";
import Theme from "./themes/light.js";

const Style = {
  multiselectContainer: {
    width: "100%",
    maxWidth: 480,
    maxHeight: 51,
    height: "100%",
    paddingTop: 0,
    borderRadius: 6,
    cursor: "pointer",
    background: "var(--general-white-color)",
    boxSizing: "content-box",
  },

  multiselectContainerFilter: {
    height: "100%",
    maxHeight: 51,
    padding: 0,
    borderRadius: 6,
    cursor: "pointer",
    background: "var(--general-white-color)",
    boxSizing: "content-box",
    fontSize: 14,
  },
  multiselectContainerInvite: {
    // width: 343,
    // height: 44,
    paddingTop: 0,
    borderRadius: 6,
    cursor: "pointer",
    background: "var(--general-white-color)",
    boxSizing: "content-box",
    // border: "1px solid #b3b3b6",
    fontSize: 14,
  },

  multiselectContainerEdit: {
    width: "100%",
    maxWidth: 480,
    maxHeight: 51,
    height: "100%",
    paddingTop: 0,
    borderRadius: 4,
    cursor: "pointer",
    background: "var(--general-white-color)",
  },
  multiselectContainerAddress: {
    width: "100%",
    // height: 54,
    paddingTop: 0,
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "var(--general-white-color)",
  },
  searchBox: {
    border: "none !important",
    fontSize: 10,
    backgroundColor: "var(--general-white-color)",
    padding: "13.25px 10px",
    maxHeight: 51,
    overflowY: "auto",
  },
  searchBoxAddress: {
    // To change search box element look
    border: "none !important",
    fontSize: 10,
    // minHeight: 51,
    backgroundColor: "var(--general-white-color)",
    padding: "0px 0px",
  },

  inputFieldAddress: {
    // To change input field position or margin
    marginTop: 12,
    width: 78,
    height: 10,
  },
  chips: {
    // To change css chips(Selected options)
    color: Theme.colors.TC_BACK,
    background: Theme.colors.TC_LIGHT,
    fontSize: Theme.dimensions.LARGE,
    borderRadius: 2,
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  chipsInvite: {
    // To change css chips(Selected options)
    color: Theme.colors.TC_BACK,
    background: Theme.colors.TC_LIGHT,
    fontSize: 14,
    borderRadius: 2,
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 10,
  },
  chipsAddress: {
    // To change css chips(Selected options)
    color: "var(--gray-gray-500)",
    //background: "var(--general-secondary-color)",
    fontSize: Theme.dimensions.LARGE,
    borderRadius: 2,
    marginBottom: 0,
    marginTop: 7,
    paddingTop: 0,
    paddingBottom: 0,
  },
  optionContainer: {
    // To change css for option container
    border: "2px solid",
  },
  optionStateContainer: {
    // To change css for option container
    border: "2px solid",
  },
  option: {
    // To change css for dropdown options
    background: Theme.colors.FC_LIGHT,
    color: Theme.colors.TC_TERTIARY,
    // padding: "5px",
  },
  optionInvite: {
    // To change css for dropdown options
    background: Theme.colors.FC_LIGHT,
    color: Theme.colors.TC_TERTIARY,
    padding: "5px 5px 5px 24px",
  },
  optionContainer: {
    height: 200,
  },

  inputField: {
    backgroundColor: "red",
  },

  optionContainerGender: {
    height: 120,
  },

  searchBox1: {
    padding: "10px 16px",
    // width: 343,

    // overflowY: "scroll",
    // overflowX: "hidden",
    backgroundImage: "url('./assets/images/dropdownIcon.png') ",
  },
  groupHeading: {
    // To chanage group heading style
  },
};

export default Style;
