import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import { useState } from "react";
import Core from "../common/clientSdk";
import validator from "validator";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../redux/actions";
import { Sidebar } from "../components";
import { toast, ToastContainer } from "react-toastify";
function UserFullName({changeFullNameSidebarOpen,setChangeFullNameSidebarOpen}) {
  const [isLoading, setLoading] = useState(false);
  const [changeName, setChangeName] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    name: "",
  });
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;

  useEffect(() => {
    setChangeName(user_data.name);
  }, [user_data]);

  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        name: changeName,
      }),
    };
    if (validateName(changeName))
      Core.postUpdateUserProfile(
        postUpdateUserProfileSuccess,
        postUpdateUserProfileFailed,
        progress_cb,
        params
      );
  }

  function postUpdateUserProfileSuccess(response_data) {
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    setLoading(false);
    setChangeFullNameSidebarOpen(false)
    toast.success("User name changed successfully!");
  }

  function postUpdateUserProfileFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, designation: "Please enter name" });
    } else {
      set_error({ ...error, designation: "" });
      return true;
    }
  }

  const changeFullNameSidebarHeader=()=>{
    return <CustomText p text={"Change Full Name"} />
  }
  const changeFullNameSidebarFooter=()=>{
    return (
        <PrimaryCta
          fontSize={"font_size_large"}
          fullWidth100
          text={"Update"}
          className="blue-button"
          isLoading={isLoading}
          onClick={() => {
            postUpdateUserProfile(updateProgress)
          }
        
      }
        />
    );
  }
  return (
    <div>
    <Sidebar 
    sidebarWidth={"30%"}
    isOpen={changeFullNameSidebarOpen}
    onClose={()=>setChangeFullNameSidebarOpen(false)}
    Header={changeFullNameSidebarHeader}
    Footer={changeFullNameSidebarFooter}
    headerAlignment="left">
         <div className="p-20">
          <div className="">
            <CustomText
              p
              text={"Full Name"}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <div className="full-width">
              <CustomTextInput
                value={changeName}
                height={"51px"}
                onChange={(e) => setChangeName(e.target.value)}
                onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.name}</h5>
            </div>
            
          </div>
          </div>
          </Sidebar>
          <ToastContainer/>
    </div>
  );
}

export default UserFullName;
