import React, { useState } from "react";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import { CustomText, PrimaryCta, Image, Video } from "../../components";
import Utils from "../../common/utils";
import ReactHtmlParser from "react-html-parser";
import CreateCampaignSuccess from "./createCampaignSuccess";
import { useSelector } from "react-redux";
import CampaignBestMatchInfluencer from "./campaignBestMatchInfluencer";

function ReviewCampaign({
  closeModal,
  props,
  imageId,
  handleNext,
  isLoading,
  instructionNote,
  deliveryDate,
  endDate,
  maxBidAmount,
  title,
  postSaveCampaignUser,
  isLoadingDraft,
}) {
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [bestMatchModal, setBestMatchModal] = useState(false);

  const openModalBestMatch = () => {
    if (bestMatchModal == false) {
      setBestMatchModal(true);
    } else {
      setBestMatchModal(false);
    }
  };

  const closeModalBestMatch = () => {
    setBestMatchModal(false);
  };
  const {
    campaign_data,
    instruction_data,
    sample_content_data,
    audience_data,
    influencer_data,
    content_type_deep_data,
  } = props.campaignDeepData;

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;

  const handleClick = () => {
    setShowImage(!showImage);
  };

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  const ageData = audience_data?.age?.map((item) => {
    return Utils.convertAgeIdToHuman(item);
  });

  let language = audience_data?.language.map(Utils.firstUpperCase).join(", ");

  return (
    <>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>

        <div className="campaign-review-container">
          <div className="review-campaign-heading">
            <CustomText
              h4
              text={"Review your Campaign"}
              className={"typographyBoldH5"}
            />
          </div>
          <div className="review-campaign-detail-wrap">
            <div className="review-campaign-detail-page m-b-16">
              <div>
                <CustomText
                  h5
                  text={"Campaign Details"}
                  className="button2"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <CustomText
                  p
                  text={"Edit"}
                  className="button1"
                  textColor={"text_color_register"}
                />
              </div>
            </div>

            <div className="review-campaign-data">
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Title"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={title}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>

              {maxBidAmount && (
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Per Influencer Budget"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={`${Utils.getCurrencySymbol(
                      user_data.country
                    )}${maxBidAmount}`}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
              )}

              <div className="m-b-16">
                <CustomText
                  p
                  text={"End Date"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={Utils.convertUnixToDate(endDate, user_data.time_zone)}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Delivery Date"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={Utils.convertUnixToDate(
                    deliveryDate,
                    user_data.time_zone
                  )}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Instruction for Creator"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={ReactHtmlParser(instructionNote)}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              {imageId && imageId.length !== 0 && (
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Sample Content"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <div className="flex-row">
                    {imageId &&
                      imageId?.map((item, index) => {
                        if (item?.split(".").pop() == "mp4") {
                          return (
                            <div key={index} className="product-sample-main">
                              <div
                                id={index}
                                onClick={() => handleVideoClick()}
                                className="m-r-6"
                              >
                                <video
                                  controlsList="nodownload"
                                  preload="metadata"
                                  style={{ height: 106, width: 106 }}
                                  src={Utils.generateCampaignSampleUrl(
                                    campaign_data.brand_id,
                                    item
                                  )}
                                />
                              </div>

                              {showVideo && (
                                <div className="fullscreen-image">
                                  <div style={{ position: "relative" }}>
                                    <div
                                      onClick={() => handleVideoClick()}
                                      className="fullscreen-image-close-btn"
                                    >
                                      <Icon iconName="CloseRoundButtonIcon" />
                                    </div>
                                    <Video
                                      source={Utils.generateCampaignSampleUrl(
                                        campaign_data.brand_id,
                                        item
                                      )}
                                      width={600}
                                      height={337}
                                      type={"video/mp4"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <>
                              <div className="product-sample-main">
                                <div onClick={() => handleClick()}>
                                  <Image
                                    source={Utils.generateCampaignSampleUrl(
                                      campaign_data.brand_id,
                                      item
                                    )}
                                    width={106}
                                    height={106}
                                  />
                                </div>

                                {showImage && (
                                  <div className="fullscreen-image">
                                    <div style={{ position: "relative" }}>
                                      <div
                                        onClick={() => handleClick()}
                                        className="fullscreen-image-close-btn"
                                      >
                                        <Icon iconName="CloseRoundButtonIcon" />
                                      </div>
                                      <Image
                                        source={Utils.generateCampaignSampleUrl(
                                          campaign_data.brand_id,
                                          item
                                        )}
                                        width={600}
                                        height={337}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="review-target-detail-wrap">
            <div className="review-campaign-detail-page m-b-16">
              <div>
                <CustomText
                  h5
                  text={"Target Influencers"}
                  className="button2"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <CustomText
                  p
                  text={"Edit"}
                  className="button1"
                  textColor={"text_color_register"}
                />
              </div>
            </div>

            <div className="review-campaign-data">
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Audience Age Group"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={ageData?.join(", ")}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Influencer’s Gender"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={audience_data?.gender}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              <div className="m-b-16">
                <CustomText
                  p
                  text={"Content Language"}
                  className="button1 m-b-4"
                  textAlign={"text_align_left"}
                />
                <CustomText
                  p
                  text={language}
                  className="subtitle1"
                  textAlign={"text_align_left"}
                />
              </div>
              {influencer_data?.minimum_followers !== 0 && (
                <div className="m-b-16">
                  <CustomText
                    p
                    text={"Minimum Followers"}
                    className="button1 m-b-4"
                    textAlign={"text_align_left"}
                  />
                  <CustomText
                    p
                    text={Utils.getFollowersValue(
                      influencer_data?.minimum_followers
                    )}
                    className="subtitle1"
                    textAlign={"text_align_left"}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="review-campaign-btn-wrap">
            {/* <PrimaryCta
              invert
              onClick={() => postSaveCampaignUser()}
              fontSize={"font_size_large"}
              text={"Save as Draft"}
              className={"review-cancel-btn grey_100"}
              textColor={"text_grey_100_color"}
              isLoading={isLoadingDraft}
            /> */}

            <PrimaryCta
              fifth
              onClick={() => {
                handleNext();
              }}
              fontSize={"font_size_large"}
              text={"Publish Campaign"}
              isLoading={isLoading}
              disabled={isLoading}
              className="blue-button"
            />
          </div>
        </div>
      </Modal>
      {bestMatchModal && <CampaignBestMatchInfluencer />}
    </>
  );
}

export default ReviewCampaign;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--bg-white-color)",
    // borderColor: "var( --blue-blue-500)",

    zIndex: "5",
    height: "82%",
    display: "flex",
    flexDirection: "column",

    minWidth: "700px",

    padding: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
