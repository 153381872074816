// ToastProvider.js
import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export function ToastProvider({ children }) {
  const showToast = (
    message = "You are not authorised to perform this action",
    type = "info"
  ) => {
    toast[type](message, { position: "top-right", autoClose: 3000 });
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);
