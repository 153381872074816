import React, { useEffect, useState } from "react";
import {
  CustomText,
  CustomTextArea,
  CustomTextInput,
  ConfirmationDialog,
  Image,
  PrimaryCta,
  Video,
  ViewCards,
} from "../../components";
import {
  format,
  parse,
  fromUnixTime,
  nextWednesday,
  setHours,
  setMinutes,
} from "date-fns";
import "react-best-tabs/dist/index.css";
import Icon from "../../components/core_component/icon";

import Core from "../../common/clientSdk";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { type } from "@testing-library/user-event/dist/type";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import { saveAs } from "file-saver";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import validator from "validator";
import ReactGA from "react-ga";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import PayoutFlyOut from "./payoutFlyOut";
import { Rating } from "react-simple-star-rating";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    minWidth: "658px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const customStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    maxHeight: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    minWidth: "400px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "658px",
    height: "130px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};

const contractId = ["48w45x", "2vla9s", "tlezgd", "1u3tdg"];

function ContentStatusScreen(props) {
  const [activeTab, setActiveTab] = useState("detail-page");
  const [assignmentDeepData, setAssignmentDeepData] = useState({});
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isRatingButtonLoading, setRatingButtonLoading] = useState(false);
  const [isLoadingButton, setLoadingButton] = useState({});
  const [rejectedLoader, setRejectedLoader] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [rejectedText, setRejectedText] = useState("");
  const [openSnackbar] = useSnackbar();
  const [showImage, setShowImage] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [cancelContractModalOpen, setCancelContractModalOpen] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [rating, setRating] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [approveDialog, setApproveDialog] = useState(false);
  const [payoutOpen, setPayoutOpen] = useState(false);
  const [pgOrderId, setPgOrderId] = useState("");
  const [error, set_error] = useState({
    content_rejected: "",
  });
  const assignment_id = useParams().id;
  const handleVideoClick = () => {
    console.log("sandeep");
    setShowVideo(!showVideo);
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default right-click behavior
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const contentId = params.get("content_id");

  useEffect(() => {
    getUserAssignmentDetail(updateProgress);
  }, []);

  // useEffect(() => {
  //   getInfluencerDetailPublic(updateProgress);
  // }, []);

  // useEffect(() => {
  //   postRatingInfluencer();
  // }, []);

  useEffect(() => {
    // Check if any content is in review
    if (assignmentDeepData.contents_deep_data) {
      const isInReview = Object.values(
        assignmentDeepData.contents_deep_data
      ).every((item) => item.content_data.status === "IN_REVIEW");

      if (isInReview) {
        setActiveStep(1); // Advance the stepper
      }
      const isComplete = Object.values(
        assignmentDeepData.contents_deep_data
      ).every(
        (item) =>
          item.content_data.status === "COMPLETED" ||
          item.content_data.status === "CONTENT_PENDING"
      );

      if (isComplete) {
        setActiveStep(2); // Advance the stepper
      }
      const isUrl = Object.values(assignmentDeepData.contents_deep_data).every(
        (item) => item.content_data.url
      );
      if (isUrl) {
        setActiveStep(3);
      }
    }
  }, [assignmentDeepData]);

  function updateProgress(status) {}

  const toggleFlyoutDropdown = () => {
    setPayoutOpen(!payoutOpen);
  };

  const handleClick = () => {
    setShowImage(!showImage);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function CancelContractOpen() {
    setCancelContractModalOpen(true);
  }
  function CancelContractClose() {
    setCancelContractModalOpen(false);
  }

  // rating
  const handleRating = (rate) => {
    setRating(rate);
  };

  const buttonDisabled = () => {
    setIsButtonDisabled(true);
  };

  const reminderTostify = () => {
    toast.success("Reminder Send Successfully to influencer");
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");

  function validateContent(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, content_rejected: "Please Enter Feedback" });
      openSnackbar("Please Enter Content Feedback");
    } else {
      set_error({ ...error, content_rejected: "" });
      return true;
    }
  }

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  // assignment cancel API
  function postAssignmentWithdraw() {
    const params = {
      assignment_id: assignment_id,
    };
    Core.postAssignmentWithdraw(
      postAssignmentWithdrawSuccess,
      postAssignmentWithdrawFailed,
      (status) => setLoading(status),
      params
    );
  }

  function postAssignmentWithdrawSuccess(response_data) {
    getUserAssignmentDetail(updateProgress);
    toast.success("Contract cancelled and refund initiated");
  }
  function postAssignmentWithdrawFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  // influncer rating
  function postRatingInfluencer(influencer_id) {
    if (rating == 0) {
      return;
    }
    const params = {
      review_points: rating,
      assignment_id: assignment_id,
      review: "active",
      influencer_id: assignmentDeepData.influencer_data.influencer_id,
    };
    Core.postRatingInfluencer(
      getInfluncerRatingSuccess,
      getInfluncerRatingFailed,
      (status) => setRatingButtonLoading(status),
      params
    );
  }

  function getInfluncerRatingSuccess(response_data) {
    toast.success("Thanks for rating the Influencer");
    getUserAssignmentDetail(updateProgress);
  }

  function getInfluncerRatingFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getUserAssignmentDetail() {
    const params = {
      assignment_id: assignment_id,
      // brand_id: brand_data.brand_id,
    };
    Core.getUserAssignmentDetail(
      getUserAssignmentDetailSuccess,
      getUserAssignmentDetailFailed,
      (status) => setLoading(status),
      params
    );
  }

  function getUserAssignmentDetailSuccess(response_data) {
    setAssignmentDeepData(response_data.assignment_deep_data);
    // if (
    //   response_data?.assignment_deep_data?.assignment_data?.assignment_status ==
    //   "COMPLETED"
    // ) {
    //   ContentStatusApprove();
    //   console.log("approve");
    // }
  }

  function getUserAssignmentDetailFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function ContentStatusApprove(approvedContentId) {
    const params = {
      assignment_id: assignment_id,
      content_id: approvedContentId,
      is_approved: "true",
    };
    Core.postUserAssignmentReviewContent(
      ContentStatusApproveSuccess,
      ContentStatusApproveFailed,
      (status) => setLoadingButton({ [approvedContentId]: status }),
      params
    );
  }

  function ContentStatusApproveSuccess(response_data) {
    // if (response_data.success) {
    //   // postCreateOrderAssignment(updateProgress);
    //   getUserAssignmentDetail();
    // }
    getUserAssignmentDetail(updateProgress);
    props.getUserAssignmentDetail();
    openSnackbar("Content approved");
  }

  function ContentStatusApproveFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function contractCheck(content_id) {
    if (contractId.includes(assignment_id)) {
      toggleFlyoutDropdown();
    } else {
      ContentStatusApprove(content_id);
      // props.getUserAssignmentDetail();
    }
  }

  //payment

  // const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;
  // function redirectToPaymentPage() {
  //   const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
  //   window.location.href = paymentUrl;
  // }

  function ContentStatusRejected(content_id) {
    const params = {
      assignment_id: assignment_id,
      content_id: content_id,
      comment: rejectedText,
      is_approved: "false",
    };
    if (validateContent(rejectedText))
      Core.postUserAssignmentReviewContent(
        ContentStatusRejectedSuccess,
        ContentStatusRejectedFailed,
        (status) => setRejectedLoader({ [content_id]: status }),
        params
      );
  }

  function ContentStatusRejectedSuccess(response_data) {
    getUserAssignmentDetail();
    openSnackbar("Content rejected");
    setRejectedText("");
    setSelectedContentId(null);
    props.getUserAssignmentDetail();
  }

  function ContentStatusRejectedFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const totalAssignment = Object.keys(assignmentDeepData).length;

  let completedAssignment = 0;
  let inReview = 0;
  let contentPending = 0;
  let rework = 0;
  let newAssignment = 0;

  assignmentDeepData.contents_deep_data &&
    Object.keys(assignmentDeepData.contents_deep_data).map((item) => {
      const { status } =
        assignmentDeepData.contents_deep_data[item].content_data;
      if (status == "COMPLETED") {
        completedAssignment++;
      }
      if (status == "IN_REVIEW") {
        inReview++;
      }
      if (status == "CONTENT_PENDING") {
        contentPending++;
      }
      if (status == "REWORK") {
        rework++;
      }
      if (status == "NEW") {
        newAssignment++;
      }
      return {};
    });

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  const { influencer_data = {}, campaign_data = {} } = assignmentDeepData;
  const { type = {} } = campaign_data;

  function ContentFallBack(props) {
    return (
      <ViewCards>
        <div className="content-fall-back-container">
          <div className="m-b-16">
            <Icon iconName="InstagramIconNonColoredIcon" />
          </div>
          <CustomText
            p
            textColor={"text_color_primary"}
            fontSize={"font_size_small"}
            fontWeight={"Poppins_lightItalic"}
            text={props.textPrimary}
          />
          <CustomText
            p
            textColor={"text_color_primary"}
            fontSize={"font_size_small"}
            fontWeight={"Poppins_lightItalic"}
            text={props.textSecondary}
          />
        </div>
      </ViewCards>
    );
  }

  const ImageView = ({ imageUrl }) => {
    const [zoomed, setZoomed] = useState(false);

    const toggleZoom = () => {
      setZoomed(!zoomed);
    };

    const closeZoom = () => {
      setZoomed(false);
    };

    return (
      <>
        <div className={`image-zoom-container ${zoomed ? "zoomed" : ""}`}>
          <div className="content-close-icon" onClick={closeZoom}>
            {zoomed && <Icon iconName="CloseRoundButtonIcon" />}
          </div>
          <img
            src={imageUrl}
            alt="Image"
            className={`image-content-status ${zoomed ? "zoomed" : ""}`}
            onClick={toggleZoom}
          />
        </div>
      </>
    );
  };

  const VideoPlayer = ({ videoUrl }) => {
    const [playing, setPlaying] = useState(false);

    const togglePlaying = () => {
      setPlaying(!playing);
    };

    const closeVideo = () => {
      setPlaying(false);
    };

    return (
      <div className={`video-player-container ${playing ? "playing" : ""}`}>
        <div className="content-close-icon" onClick={closeVideo}>
          {playing && (
            <Icon iconName="CloseRoundButtonIcon" onClick={closeVideo} />
          )}
        </div>
        <video
          controlsList="nodownload"
          src={videoUrl}
          className={`video-content-status ${playing ? "playing" : ""}`}
          onClick={togglePlaying}
          controls
        />
      </div>
    );
  };

  const handleCancel = () => {
    setApproveDialog(false);
  };

  function ContentInReview() {
    if (inReview > 0 || completedAssignment > 0 || contentPending > 0) {
      return (
        <>
          <div className="content-pending-container">
            {assignmentDeepData.contents_deep_data &&
              Object.keys(assignmentDeepData.contents_deep_data).map(
                (item, index) => {
                  const { content_id, status, media_id } =
                    assignmentDeepData.contents_deep_data[item].content_data;
                  const { name, platform_id } =
                    assignmentDeepData.contents_deep_data[item].platform_data;

                  if (status == "IN_REVIEW") {
                    if (media_id.split(".").pop() == "mp4") {
                      return (
                        <>
                          {index === 0 && (
                            <div
                              className="content-pending-card"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div className="m-r-8">
                                  <Icon
                                    iconName={Utils.socialMediaIcons(
                                      platform_id
                                    )}
                                    height={24}
                                    width={24}
                                  />
                                </div>

                                <CustomText
                                  p
                                  className="content-pending-card-text"
                                  text={name}
                                  textColor={"text_color_gray_500"}
                                />
                              </div>
                              <div
                                className="Caption1"
                                style={{ color: "var(--gray-gray-400)" }}
                              >
                                {/*  {currentTime()} */}
                              </div>
                            </div>
                          )}

                          <div className="content-pending-content-imagecard">
                            <div className="content-status-media">
                              <div onClick={handleVideoClick}>
                                <video
                                  controlsList="nodownload"
                                  preload="metadata"
                                  width={96}
                                  height={96}
                                  type={"video/mp4"}
                                  onContextMenu={handleContextMenu}
                                >
                                  <source
                                    src={Utils.constructContentMediaUrl(
                                      assignment_id,
                                      media_id
                                    )}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </div>

                              {showVideo && (
                                <div className="fullscreen-image">
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      onClick={handleVideoClick}
                                      className="fullscreen-image-close-btn"
                                    >
                                      <Icon iconName="CloseRoundButtonIcon" />
                                    </div>
                                    <Video
                                      source={Utils.constructContentMediaUrl(
                                        assignment_id,
                                        media_id
                                      )}
                                      width={600}
                                      height={337}
                                      type={"video/mp4"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="content-status-bottom-container">
                              <div>
                                <PrimaryCta
                                  invert
                                  className="approve-reject-content-cta"
                                  onClick={() => {
                                    openModal();
                                    setSelectedContentId(content_id);
                                  }}
                                  fontSize={"font_size_large"}
                                  text={"Reject"}
                                  isLoading={rejectedLoader[content_id]}
                                />
                              </div>
                              <div>
                                <PrimaryCta
                                  fifth
                                  className="blue-button-p-10-30"
                                  onClick={() => {
                                    contractCheck(content_id);
                                  }}
                                  fontSize={"font_size_large"}
                                  text={"Approve"}
                                  isLoading={isLoadingButton[content_id]}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          {index === 0 && (
                            <div
                              className="content-pending-card"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div className="m-r-8">
                                  <Icon
                                    iconName={Utils.socialMediaIcons(
                                      platform_id
                                    )}
                                    height={24}
                                    width={24}
                                  />
                                </div>

                                <CustomText
                                  p
                                  className="content-pending-card-text"
                                  text={name}
                                  textColor={"text_color_gray_500"}
                                />
                              </div>
                              {/* <div className="Caption1">{currentTime()}</div> */}
                            </div>
                          )}
                          <div className="content-pending-content-imagecard">
                            <div
                              style={{ position: "relative" }}
                              onClick={handleClick}
                              className="content-status-media"
                            >
                              {/* <ImageView
                            imageUrl={Utils.constructContentMediaUrl(
                              assignment_id,
                              media_id
                            )}
                          /> */}
                              <Image
                                source={Utils.constructContentMediaUrl(
                                  assignment_id,
                                  media_id
                                )}
                                width={96}
                                height={96}
                              />
                              {showImage && (
                                <div className="fullscreen-image">
                                  <div style={{ position: "relative" }}>
                                    <div className="fullscreen-image-close-btn">
                                      <Icon iconName="CloseRoundButtonIcon" />
                                    </div>
                                    <Image
                                      source={Utils.constructContentMediaUrl(
                                        assignment_id,
                                        media_id
                                      )}
                                      width={600}
                                      height={337}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="content-status-bottom-container">
                              <div>
                                <PrimaryCta
                                  invert
                                  onClick={() => {
                                    openModal();
                                    setSelectedContentId(content_id);
                                  }}
                                  fontSize={"font_size_large"}
                                  text={"Reject"}
                                  isLoading={rejectedLoader[content_id]}
                                />
                              </div>
                              <div>
                                <PrimaryCta
                                  fifth
                                  onClick={() => {
                                    contractCheck(content_id);
                                  }}
                                  fontSize={"font_size_large"}
                                  text={"Approved"}
                                  className="blue-button"
                                  isLoading={isLoadingButton[content_id]}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  if (status == "COMPLETED" || status == "CONTENT_PENDING") {
                    return (
                      <>
                        {index === 0 && (
                          <div
                            className="content-pending-card"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="m-r-8">
                                <Icon
                                  iconName={Utils.socialMediaIcons(platform_id)}
                                  height={24}
                                  width={24}
                                />
                              </div>

                              <CustomText
                                p
                                className="content-pending-card-text"
                                text={name}
                                textColor={"text_color_gray_500"}
                              />
                            </div>
                            {/* <div className="Caption1">{currentTime()}</div> */}
                          </div>
                        )}
                        {index === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "33px 35px",
                              gap: 8,
                            }}
                          >
                            {" "}
                            <Icon iconName="InReviewContent" />{" "}
                            <CustomText
                              p
                              className="subtitle1"
                              text={"I have approve the content"}
                              textColor={"text_color_gray_500"}
                            />
                          </div>
                        )}
                        {/* <div>
                          <PrimaryCta
                            fifth
                            onClick={() => {
                              setApproveDialog(true);
                              setSelectedContentId(content_id);
                            }}
                            fontSize={"font_size_large"}
                            text={"payment"}
                            isLoading={isLoadingButton}
                          />
                        </div> */}
                      </>
                    );
                  }
                }
              )}
          </div>
        </>
      );
    }
  }

  function ContentCompleted() {
    const handleDownload = (media_id, isVideo) => {
      const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = mediaUrl;
      a.download = `downloaded_${isVideo ? "video" : "image"}.${media_id
        .split(".")
        .pop()}`;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      // Clean up the anchor element
      document.body.removeChild(a);
    };

    if (completedAssignment > 0 || contentPending > 0) {
      return (
        <div className="content-pending-container">
          {/* {completedAssignment === 0 ? (
            <>
              <ContentFallBack
                textPrimary="No content available"
                textSecondary="Ready contents will be shown here"
              />
            </>
          ) : ( */}
          <>
            {assignmentDeepData.contents_deep_data &&
              Object.keys(assignmentDeepData.contents_deep_data).map(
                (item, index) => {
                  const { content_id, content_type_id, status, media_id, url } =
                    assignmentDeepData.contents_deep_data[item].content_data;
                  const { platform_id } =
                    assignmentDeepData.contents_deep_data[item].platform_data;

                  const openUrl = () => {
                    window.open(url);
                  };

                  if (campaign_data.type === "social_media") {
                    if (status == "COMPLETED") {
                      return (
                        <>
                          {index === 0 && (
                            <div className="content-pending-card">
                              <div className="m-r-8">
                                <Icon
                                  iconName={Utils.socialMediaIcons(platform_id)}
                                  height={24}
                                  width={24}
                                />
                              </div>

                              <CustomText
                                p
                                className="content-pending-card-text"
                                text={"Content Social Link"}
                                textColor={"text_color_gray_500"}
                              />
                            </div>
                          )}
                          <div className="m-b-12">
                            <div
                              className="content-pending-content-card"
                              onClick={() => openUrl()}
                            >
                              <CustomText
                                p
                                className="Body-1 content-url cursor-pointer"
                                text={url && url}
                                textColor={"text_color_blue_500"}
                              />

                              <div>
                                <Icon
                                  iconName={"contentStatusCheckIcon"}
                                  height={24}
                                  width={24}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                    if (status == "CONTENT_PENDING") {
                      return (
                        <>
                          {index === 0 && (
                            <div className="content-pending-card">
                              <div className="m-r-8">
                                <Icon
                                  iconName={Utils.socialMediaIcons(platform_id)}
                                  height={24}
                                  width={24}
                                />
                              </div>

                              <CustomText
                                p
                                className="content-pending-card-text"
                                text={"Content Social Link"}
                              />
                            </div>
                          )}
                          <div className="m-b-12">
                            <div className="content-pending-content-card">
                              <CustomText
                                p
                                className="Body-1 content-url"
                                text={Utils.getContentTypeNameById(
                                  content_type_id
                                )}
                                textColor={"text_color_gray_400"}
                              />

                              <div>
                                <Icon
                                  iconName={"pendingTimeIcon"}
                                  height={24}
                                  width={24}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                  if (
                    campaign_data.type === "content" &&
                    status == "COMPLETED"
                  ) {
                    if (media_id.split(".").pop() === "mp4") {
                      return (
                        <ViewCards style={{ marginBottom: "10px" }}>
                          <div className="content-status-media">
                            <div onClick={handleVideoClick}>
                              <video
                                preload="metadata"
                                width={"100%"}
                                height={"100%"}
                                type={"video/mp4"}
                              >
                                <source
                                  src={Utils.constructContentMediaUrl(
                                    assignment_id,
                                    media_id
                                  )}
                                  type="video/mp4"
                                ></source>
                              </video>
                            </div>
                            {showVideo && (
                              <div className="fullscreen-image">
                                <div
                                  style={{
                                    position: "relative",
                                    backgroundColor: "var( --gray-gray-500)",
                                  }}
                                >
                                  <div
                                    onClick={handleVideoClick}
                                    className="fullscreen-image-close-btn"
                                  >
                                    <Icon iconName="CloseRoundButtonIcon" />
                                  </div>
                                  <Video
                                    source={Utils.constructContentMediaUrl(
                                      assignment_id,
                                      media_id
                                    )}
                                    width={600}
                                    height={337}
                                    type={"video/mp4"}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="content-status-bottom-container">
                            {type === "content" && (
                              <div>
                                <PrimaryCta
                                  fifth
                                  onClick={() => handleDownload(media_id, true)}
                                  fontSize={"font_size_large"}
                                  className="blue-button"
                                  text={"Download"}
                                  isLoading={isLoading}
                                />
                              </div>
                            )}
                          </div>
                        </ViewCards>
                      );
                    } else {
                      return (
                        <ViewCards style={{ marginBottom: "10px" }}>
                          <div
                            style={{ position: "relative" }}
                            onClick={handleClick}
                            className="content-status-media"
                          >
                            <Image
                              source={Utils.constructContentMediaUrl(
                                assignment_id,
                                media_id
                              )}
                              width={"100%"}
                              height={"100%"}
                            />
                            {showImage && (
                              <div className="fullscreen-image">
                                <div style={{ position: "relative" }}>
                                  <div className="fullscreen-image-close-btn">
                                    <Icon iconName="CloseRoundButtonIcon" />
                                  </div>
                                  <Image
                                    source={Utils.constructContentMediaUrl(
                                      assignment_id,
                                      media_id
                                    )}
                                    width={600}
                                    height={337}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="content-status-bottom-container">
                            {type === "content" && (
                              <div>
                                {/* Replace empty onClick with the download function */}
                                <PrimaryCta
                                  fifth
                                  onClick={() => handleDownload(media_id, true)}
                                  fontSize={"font_size_large"}
                                  text={"Download"}
                                  className="blue-button"
                                  isLoading={isLoading}
                                />
                              </div>
                            )}
                          </div>
                        </ViewCards>
                      );
                    }
                  }
                }
              )}
          </>
          {/* )} */}
        </div>
      );
    }
  }

  function updateContentSubmitionIcon(status) {
    const updatedIcon = {
      IN_REVIEW: "contentStatusCheckIcon",
      REWORK: "contentStatusRejectIcon",
      NEW: "pendingTimeIcon",
      CONTENT_PENDING: "contentStatusCheckIcon",
      COMPLETED: "contentStatusCheckIcon",
    };

    return updatedIcon[status];
  }

  function ContentPending() {
    return (
      <>
        <div className="content-pending-container">
          {assignmentDeepData.contents_deep_data &&
            Object.keys(assignmentDeepData.contents_deep_data).map(
              (item, index, array) => {
                const { content_id, content_type_id, status, media_id } =
                  assignmentDeepData.contents_deep_data[item].content_data;
                const { name, platform_id } =
                  assignmentDeepData.contents_deep_data[item].platform_data;
                const isLastIndex = index === array.length - 1;
                if (campaign_data.type === "social_media") {
                  return (
                    <div className="m-b-12">
                      {index === 0 && (
                        <div className="content-pending-card">
                          <div className="m-r-8">
                            <Icon
                              iconName={Utils.socialMediaIcons(platform_id)}
                              height={24}
                              width={24}
                            />
                          </div>

                          <CustomText
                            p
                            className="content-pending-card-text"
                            text={name}
                            textColor={"text_color_gray_500"}
                          />
                        </div>
                      )}
                      <div className="content-pending-content-card">
                        <CustomText
                          p
                          className="Body-1"
                          text={Utils.getContentTypeNameById(content_type_id)}
                          textColor={"text_color_gray_400"}
                        />
                        <div>
                          <Icon
                            iconName={updateContentSubmitionIcon(status)}
                            height={24}
                            width={24}
                          />
                        </div>
                      </div>
                      {status == "REWORK" && (
                        <div className="m-t-8">
                          <CustomText
                            p
                            className="Caption1"
                            text={"Content Rejected"}
                            textColor={"text_general_color_error"}
                          />
                        </div>
                      )}
                    </div>
                  );
                }

                if (campaign_data.type === "content") {
                  return (
                    <>
                      <div
                        className={`content-pending-content-card ${
                          isLastIndex ? "" : "m-b-12"
                        }`}
                      >
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <div className="m-r-8">
                            <Icon
                              iconName={Utils.socialMediaIcons(platform_id)}
                              height={24}
                              width={24}
                            />
                          </div>

                          <CustomText
                            p
                            className="content-pending-card-text"
                            text={name}
                            textColor={"text_color_gray_500"}
                          />
                        </div>

                        <div>
                          <Icon
                            iconName={updateContentSubmitionIcon(status)}
                            height={24}
                            width={24}
                          />
                        </div>
                      </div>
                      {status == "REWORK" && (
                        <div className="m-t-8">
                          <CustomText
                            p
                            className="Caption1"
                            text={"Content Rejected"}
                            textColor={"text_general_color_error"}
                          />
                        </div>
                      )}
                    </>
                  );
                }
              }
            )}
        </div>
      </>
    );
  }

  function currentTime() {
    // Get the current date
    const currentDate = new Date();

    // Set the time to 3:00 PM
    const timeAdjustedDate = setMinutes(setHours(currentDate, 15), 0);

    // Format the date
    const formattedDate = format(timeAdjustedDate, "eeee, h:mm a");
    return formattedDate;
  }

  // vertical steps
  const steps = [
    {
      label: "Content Submission",
      description: ContentPending(),
    },
    {
      label: "In-Review",
      description: ContentInReview(),
    },
    {
      label: "Completed",
      description: ContentCompleted(),
    },
  ];

  return (
    <>
      <>
        <div className="m-b-12">
          <CustomText
            p
            text={"Content Status"}
            textColor={"text_color_gray_500"}
            fontSize={"font_size_h7"}
            textAlign={"text_align_left"}
            fontWeight={"Inter_Bold"}
            lineHeight={"line_height_24"}
          />
        </div>
        {Object.keys(assignmentDeepData).length !== 0 && (
          <>
            <div className="content-container-main">
              <div className="content-status-container-box">
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} expanded={true}>
                      <StepLabel className="Caption-3">{step.label}</StepLabel>
                      <StepContent>{step.description}</StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>

              {assignmentDeepData.assignment_data.assignment_status ==
                "COMPLETED" && (
                <div className="rating-star">
                  <CustomText
                    p
                    text={"Rate your Collaboration Experience with"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_l"}
                    className="rating-para"
                  />
                  <CustomText
                    p
                    text={assignmentDeepData.influencer_data.name}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                    className="name-rating"
                  />
                  <div className="rating-icon">
                    <Rating
                      size={42}
                      initialValue={
                        assignmentDeepData.rating_data[0]?.review_points ||
                        rating
                      }
                      onClick={handleRating}
                      readonly={
                        assignmentDeepData?.rating_data.length == 0
                          ? false
                          : true
                      }
                    />
                  </div>
                  {assignmentDeepData.rating_data.length == 0 && (
                    <PrimaryCta
                      third
                      fullWidth={true}
                      fontSize={"font_size_large"}
                      text={"Submit"}
                      isLoading={isRatingButtonLoading}
                      className={
                        rating == 0 ? "submit-rating" : "submit-rating-after"
                      }
                      // onClick={()=>postRatingInfluencer() }
                      onClick={() => {
                        postRatingInfluencer();
                      }}
                    />
                  )}
                </div>
              )}
              {assignmentDeepData.assignment_data.assignment_status !==
                "COMPLETED" && (
                <div className="button-box">
                  <PrimaryCta
                    invert
                    fontSize={"font_size_large"}
                    text={"Cancel Contract"}
                    className={"contract-cancel "}
                    onClick={CancelContractOpen}
                    textColor="white"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div onClick={closeModal} className="close-btn-icon m-b-15">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
        <div className="m-t-36">
          <CustomText
            p
            textColor={"text_color_primary"}
            fontSize={"font_size_medium"}
            text={"Content review*"}
            textAlign={"text_align_left"}
          />
          <div className="brand-text-area m-t-12">
            <CustomTextArea
              className="background-white"
              placeholder={"Please enter your content review"}
              value={rejectedText}
              onChange={(e) => setRejectedText(e.target.value)}
              onBlur={(e) => validateContent(e.target.value)}
            />
          </div>
        </div>
        <h5 className="error">{error.content_rejected}</h5>
        <div className="m-t-12">
          <PrimaryCta
            fifth
            fullWidth={true}
            onClick={() => {
              ContentStatusRejected(selectedContentId);
              closeModal();
            }}
            fontSize={"font_size_large"}
            text={"Submit Review"}
            className="blue-button"
          />
        </div>
      </Modal>

      {/* open on close contract */}
      <Modal
        isOpen={cancelContractModalOpen}
        onRequestClose={CancelContractClose}
        style={customStyle}
      >
        <div>
          <div className="header-modal-container">
            <div className="header-modal-container-heading">
              <center style={{ fontSize: "font_size_xl" }}>
                Reelax Contract Cancellation Terms
              </center>
            </div>

            <div
              onClick={CancelContractClose}
              className="close-icon-brand-list"
            >
              <Icon iconName="CloseRoundButtonIcon" />
            </div>
          </div>
          <>
            <div>
              <div className="verify-otp ">
                <div className="text-screen-container">
                  <p>
                    To maintain fairness and transparency for both brands and
                    influencers, Reelax outlines the following contract
                    cancellation terms:
                  </p>
                  <h4 style={{ marginTop: "10px" }}>
                    1. Cancellation Before Work Starts
                  </h4>
                  <div style={{ paddingLeft: "40px" }}>
                    <p style={{ marginTop: "8px" }}>
                      <ul>
                        <li>
                          <b style={{ color: "#303030" }}>Scenario:</b> The
                          brand assigns the contract, and the influencer does
                          not begin working.
                        </li>
                        <li>
                          <b style={{ color: "#303030" }}> Policy:</b> 100%
                          cancellation is offered, and the full contract amount
                          is refunded to the brand.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <h4 style={{ marginTop: "10px" }}>
                    2. Cancellation After Work Starts
                  </h4>
                  <div style={{ paddingLeft: "40px" }}>
                    <p style={{ marginTop: "8px" }}>
                      <ul>
                        <li>
                          <b style={{ color: "#303030", fontWeight: 600 }}>
                            Scenario:
                          </b>{" "}
                          The influencer marks the contract as “Work Started”
                          but has not submitted the content.
                        </li>
                      </ul>
                      <div style={{ paddingLeft: "20px" }}>
                        <ul type="circle">
                          <li>
                            <b style={{ color: "#303030" }}>
                              Influencer’s Fault (Delayed Submission):
                            </b>{" "}
                            A 10% cancellation fee is applied, and the remaining
                            amount is refunded to the brand.
                          </li>
                          <li>
                            <b style={{ color: "#303030" }}>
                              Influencer Not Responding:
                            </b>{" "}
                            100% cancellation is offered, and the full amount is
                            refunded to the brand.
                          </li>
                          <li>
                            <b style={{ color: "#303030" }}>
                              Influencer Working but Brand Denies:
                            </b>{" "}
                            A 50% cancellation fee is applied, and the remaining
                            amount is refunded to the brand.
                          </li>
                        </ul>
                      </div>
                    </p>
                  </div>
                  <h4 style={{ marginTop: "10px" }}>
                    3. Cancellation After Content Submission
                  </h4>
                  <div style={{ paddingLeft: "40px" }}>
                    <p style={{ marginTop: "8px" }}>
                      <ul>
                        <li>
                          <b style={{ color: "#303030" }}>Scenario:</b> The
                          influencer submits the content, and the brand does not
                          approve it within 15 days.
                        </li>
                      </ul>
                      <div style={{ paddingLeft: "20px" }}>
                        <ul type="circle">
                          <li>
                            <b style={{ color: "#303030" }}>Policy:</b> If the
                            brand takes no action within 15 days, the content
                            will be automatically approved.
                          </li>
                        </ul>
                      </div>
                    </p>
                    <p style={{ marginTop: "8px" }}>
                      <ul>
                        <li>
                          <b style={{ color: "#303030" }}>Scenario:</b> The
                          influencer submits the content, and the brand does not
                          approve it within 15 days.
                        </li>
                      </ul>
                      <div style={{ paddingLeft: "20px" }}>
                        <ul type="circle">
                          <li>
                            <b style={{ color: "#303030" }}>
                              If the Content Follows Instructions:
                            </b>{" "}
                            The brand can request minor fixes only. Retakes are
                            not permitted.
                          </li>
                        </ul>
                      </div>
                    </p>
                    <p style={{ marginTop: "8px" }}>
                      <ul>
                        <li>
                          <b style={{ color: "#303030" }}>
                            If the Content Violates Brand Guidelines:
                          </b>
                        </li>
                      </ul>
                      <div style={{ paddingLeft: "20px" }}>
                        <ul type="circle">
                          <li>
                            The influencer must either retake the content or
                            agree to a cancellation with a 10% cancellation fee
                            applied to the influencer.
                          </li>
                          <li>
                            <b style={{ color: "#303030" }}>
                              If the Brand Declines Retake:
                            </b>{" "}
                            The brand may cancel the contract with a 50%
                            cancellation fee applied.
                          </li>
                        </ul>
                      </div>
                    </p>
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    These terms ensure clarity and protect both parties in case
                    of disputes or changes during the collaboration.
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </Modal>
      {payoutOpen && !isLoadingButton && (
        <PayoutFlyOut
          contentsDeepData={assignmentDeepData}
          payoutOpen={payoutOpen}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          pgOrderId={pgOrderId}
          isLoadingButton={isLoadingButton}
        />
      )}

      {approveDialog && (
        <ConfirmationDialog
          title={"Approve"}
          subtext={"Are you sure, you want to Approve this content?"}
          open={approveDialog}
          handleClose={handleCancel}
          handelAgree={() => ""}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default ContentStatusScreen;
