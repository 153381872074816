import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../screens/sideMenu";
import Header from "../screens/header";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import { useState } from "react";
import Core from "../common/clientSdk";
import validator from "validator";
import { Sidebar } from "../components";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../redux/actions";
import { set } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
function UserEmail({ changeEmailSidebarOpen, setChangeEmailSidebarOpen }) {
  const [isLoading, setLoading] = useState(false);
  const [changeEmail, setChangeEmail] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    email: "",
  });

  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;

  useEffect(() => {
    setChangeEmail(user_data.email);
  }, []);
  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        email: changeEmail,
      }),
    };
    if (validateEmail(changeEmail))
      Core.postUpdateUserProfile(
        postUpdateUserProfileSuccess,
        postUpdateUserProfileFailed,
        progress_cb,
        params
      );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    setChangeEmailSidebarOpen(false);
    toast.success("User email changed successfully!");
  }

  function postUpdateUserProfileFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }
  const changeFullNameSidebarHeader = () => {
    return <CustomText p text={"Change Email"} />;
  };
  const changeFullNameSidebarFooter = () => {
    return (
        <PrimaryCta
          fontSize={"font_size_large"}
          fullWidth100
          className="blue-button"
          text={"Update"}
          isLoading={isLoading}
          onClick={() => {
            postUpdateUserProfile(updateProgress)
          }}
        />
    );
  };
  return (
    <div>
      <Sidebar
      sidebarWidth={"30%"}
      isOpen={changeEmailSidebarOpen}
      onClose={() => setChangeEmailSidebarOpen(false)}
      Header={changeFullNameSidebarHeader}
      Footer={changeFullNameSidebarFooter}
      headerAlignment="left"
    >
      <div className="p-20">
      <div className="">
        <CustomText
          p
          text={"Email"}
        />
      </div>

      <div style={{ marginTop: "10px" }}>
        <div className="full-width">
          <CustomTextInput
            type="email"
            value={changeEmail}
            height={"51px"}
            onChange={(e) => setChangeEmail(e.target.value)}
            onBlur={(e) => validateEmail(e.target.value)}
          />
          <h5 className="error">{error.email}</h5>
        </div>
      </div>
      </div>
    </Sidebar>
    <ToastContainer/>
    </div>
    
  );
}

export default UserEmail;
