import React, { useState, useEffect } from "react";
import { CustomTextInput, ModalComponent } from "../../components";
import Modal from "react-modal";
import { CustomText, PrimaryCta, TeamMembers } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useDispatch, useSelector } from "react-redux";
import Sdk from "../../common/core/module";
import * as Action from "../../redux/actions";
import * as CampaignData from "../../common/data/campaign";
import WebStorage from "../../common/web_storage";
import { BRAND_ID_KEY } from "../../common/web_storage_keys";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";

const AddBrandModal = ({ onRequestClose, setAddNewBrandModalOpen }) => {
  const [addBrandLoading, setAddBrandLoading] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [category, setCategory] = useState([]);

  var navigate = useNavigate();

  var redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  console.log("UserData", UserData);
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { brand_id = {} } = brand_deep_data.brand_data;

  console.log("addNewBrandModalOpen");
  function updateCategory(selectedList) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });

    setCategory(selectedList);

    value.flatMap((key) => CampaignData.FULL_CATEGORIES.subcategories[key]);
  }

  const customStylesAddNewBrand = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      maxHeight: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      minWidth: "400px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  };

  function updateProgressAddBrand(status) {
    setAddBrandLoading(status);
  }

  function createBrand() {
    console.log("asdsada");
    var params = {
      brand_data: JSON.stringify({
        name: brandName,
        user_id: Sdk.User.getUserId(UserData.user_data),
        org_id: Sdk.User.getUserOrgId(UserData.user_data),
        category: category[0].value,
      }),
      org_id: Sdk.User.getUserOrgId(UserData.user_data),
    };

    Core.createBrand(
      createBrandSuccess,
      createBrandFailed,
      updateProgressAddBrand,
      params,
      redux_selector.Auth.auth_token
    );
  }

  function createBrandSuccess(response_data) {
    redux_dispatch({
      type: Action.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.b_d },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.b_d.brand_data.brand_id
    );
    setAddNewBrandModalOpen(false);
    // navigate("/")
    window.location.reload();
  }

  function createBrandFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={onRequestClose}
        style={customStylesAddNewBrand}>
        <div className="header-modal-container">
          <CustomText
            p
            text={"Add New Brand"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
          <div onClick={onRequestClose} className="close-icon-brand-list">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
        <>
          <div>
            <div className="add-new-brand-modal-label1 ">
              <CustomText h5 fontSize={"font_size_large"} text={`Brand Name`} />
            </div>

            <div className="signup-right-side-top-container-div-4 ">
              <CustomTextInput
                newWidth={"login-right-side-top-input-field"}
                placeholder="Enter Brand Name"
                name="brandName"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
              />
            </div>
            <div className="add-new-brand-modal-label2 ">
              <CustomText h5 fontSize={"font_size_large"} text={`Category`} />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <Select
                multi={false}
                options={Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS)}
                // values={category}
                onChange={(value) => updateCategory(value)}
                placeholder="Select Category"
                labelField="label"
                valueField="value"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid #cbd2d9",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  paddingBottom: "10px",
                  height: "48px",
                  paddingTop: "10px",
                  marginBottom: "5px",
                  option: {
                    backgroundColor: "red",
                    onhover: "grey",
                  },
                }}
              />
            </div>
          </div>
          <div className="m-t-15 full-width">
            <PrimaryCta
              fifth
              className="blue-button"
              fullWidth100
              fontSize={"font_size_large"}
              text={"Add Brand"}
              isLoading={addBrandLoading}
              onClick={() => createBrand()}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};
export default AddBrandModal;
