import React from "react";
import SideMenu from "../sideMenu";
import InfluencerDetailPage from "./influencerDetailPage";
import { useParams } from "react-router-dom";
import Header from "../header";

function InfluencerDetailMainPage() {
  const influencer_id = useParams().id;

  console.log("useParams", useParams());

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen-influencer">
        <Header />
        <div className="influencer-details-main-container">
          <InfluencerDetailPage influencer_id={influencer_id} />
        </div>
      </div>
    </div>
  );
}

export default InfluencerDetailMainPage;
