import React, { useState, useEffect } from "react";
import {
  CustomButton,
  CustomText,
  CustomTextInput,
  FallBack,
  FallBackBtn,
  ModalComponent,
  PrimaryCta,
  Sidebar,
  TeamMembers,
  TransitionHistory,
} from "../../components";
import Icon from "../../components/core_component/icon";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
import Modal from "react-modal";
import validator from "validator";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SubscriptionModal from "../../modal/subscriptionModal";
import CustomTable from "../../components/customTable";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import styled from "@emotion/styled";
const hasAllRoles = [
  {
    value: "has_email",
    result: true,
    label: "Campaign Manager",
    id: "has_email",
  },
  {
    value: "has_phone_number",
    result: true,
    label: "Finance",
    id: "has_phone",
  },
  {
    value: "has_phone_number",
    result: true,
    label: "Admin",
    id: "has_phone",
  },
  {
    value: "has_phone_number",
    result: true,
    label: "Custom",
    id: "has_phone",
  },
];
function TeamMembersList() {
  const [memberList, setMemberList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadingRemove, setLoadingRemove] = useState(false);
  const [checkOrgSubUser, setCheckOrgSubUser] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [inviteId, setInviteId] = useState(null);
  const [loading, set_loading] = useState(false);
  const [inviteSidebarOpen, setInviteSidebarOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState(["IN"]);
  const [inviteIsOpen, setInviteIsOpen] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [openSnackbar] = useSnackbar();
  console.log("subscriptionList", subscriptionList);
  const [countrySelected, setCountrySelected] = useState([
    {
      name: "India",
      calling_code: "+91",
      country_code: "IN",
      currency: {
        code: "INR",
        name: "Indian Rupee",
        symbol: "₹",
      },
    },
  ]);

  const [error, set_error] = useState({
    member_name: "",
    w_number: "",
    email: "",
  });

  var navigate = useNavigate();

  useEffect(() => {
    getMembersList(updateProgress0);
    userSelfData();
  }, []);

  function updateProgress0(status) {}
  function updateProgress(status) {
    set_loading(status);
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, member_name: "Please enter name" });
    } else {
      set_error({ ...error, member_name: "" });
      return true;
    }
  }
  function validateCountry(value) {
    if (value) {
    }
  }
  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }
  function validateNumber() {
    if (!number) {
      toast.error("Please Enter Whatsapp Number");
      return false;
    }

    return true;
  }
  function postInviteMembers(progress_cb) {
    var params = {
      name: memberName,
      email: email,
      country: country.toString(),
      phone_number: removePlusSign(number),
    };

    if (validateName(memberName) && validateEmail(email) && validateNumber()) {
      Core.postInviteMembers(
        postInviteMembersSuccess,
        postInviteMembersFailed,
        progress_cb,
        params
      );
    }
  }

  console.log("number", number);

  function postInviteMembersSuccess(response_data) {
    toast.success("Successfully  send");
    navigate("/team-members-list");
  }

  function postInviteMembersFailed(errorList) {
    toast.error(errorList);
  }

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (number) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }

  function updateCounty(selectedItem) {
    console.log("selectedItem", selectedItem);
    setCountrySelected(selectedItem);

    setCountry(
      selectedItem ? selectedItem.map((option) => option.country_code) : []
    );
  }

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;

  console.log("brand_deep_data", brand_deep_data);

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSubUser(response?.org_data?.subscription_data);
  }
  function userSelfDataFailed() {}

  function getMembersList(progress_cb) {
    var params = {};
    Core.getMembersList(
      getMembersListSuccess,
      getMembersListFailed,
      progress_cb,
      params
    );
  }

  function getMembersListSuccess(response_data) {
    setMemberList(response_data.members_data);
    setLoading(false);
  }

  function getMembersListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postRemoveMembers() {
    var params = {
      invite_id: inviteId,
    };
    Core.postRemoveMembers(
      postRemoveMembersSuccess,
      postRemoveMembersFailed,
      (status) => setLoadingRemove(status),
      params
    );
  }

  function postRemoveMembersSuccess(response_data) {
    console.log("response_data preve", response_data);
    setMemberList((prev) => {
      return prev.filter((member) => member.invite_id !== inviteId);
    });
    removeMemberModal();
  }

  function postRemoveMembersFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  var navigate = useNavigate();

  function maxBidCountCheck() {
    if (checkOrgSubUser?.feature?.max_brand_count) {
      return checkOrgSubUser?.feature?.max_brand_count;
    }
    return 0;
  }

  console.log("checkOrgSubUser 2", checkOrgSubUser);
  function checkSubscriptionOrNot() {
    if (!checkOrgSubUser?.feature?.max_brand_count) {
      openModal();
    } else if (memberList?.length >= 3) {
      toast.info("you can invite only 3 members");
    } else {
      setInviteSidebarOpen(true);
      // navigate("/invite-member");
    }
  }

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  function removeMemberModal(id) {
    setModalIsOpenRemove(!modalIsOpenRemove);
    setInviteId(id);
  }
  function ActionOnLoadHandler(subscription_list) {
    setSubscriptionList(subscription_list);
  }
  const StyledItem = styled.div`
    padding: 10px;
    color: #555;
    border-radius: 3px;
    margin: 3px;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    :hover {
      background: #f2f2f2;
    }
  `;
  function updateRole(selectedItem) {
    console.log("selectedItem", selectedItem);

    const initialObject = selectedItem.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {});
    console.log("initialObject", initialObject);
    setAllRoles(initialObject);
    // setInfluencerContactEmail()
    setAllRoles(selectedItem);
    // selectedItem.forEach((item) => {
    //   hasContact.forEach((data) => {
    //     if (item.id == data.id) {
    //       data.handler(item.value);
    //     }
    //   });
    // });
  }

  function inviteToggle() {
    setInviteIsOpen(!inviteIsOpen);
  }
  function inviteHeader() {
    return (
      <CustomText p text={"Invite Member"} className="side-bar-heading-text" />
    );
  }
  function inviteFooter() {
    return <CustomText p text={"Invite"} className="side-bar-heading-text" />;
  }
  function renderInviteMember() {
    return (
      <>
        <Sidebar
          isOpen={inviteIsOpen}
          sidebarWidth={"30%"}
          onClose={inviteToggle}
          Header={inviteHeader}
          Footer={inviteFooter}>
          <div className="p-20">
            <div className="">
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select User"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={""}
                  placeholder="Enter Email or Phone Number"
                  onChange={(e) => {}}
                />
              </div>
            </div>
            <div className="m-t-16">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Select role"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={true}
                options={hasAllRoles}
                values={allRoles}
                onChange={(value) => updateRole(value)}
                itemRenderer={({ item, methods }) => (
                  console.log("demo 22", item, methods),
                  (
                    <StyledItem>
                      <div onClick={() => methods.addItem(item)}>
                        <input
                          type="checkbox"
                          onChange={() => methods.addItem(item)}
                          checked={methods.isSelected(item)}
                        />
                        {item.label}
                      </div>
                    </StyledItem>
                  )
                )}
                placeholder="Select "
                autoFocus={false}
                color="#C8C2C2"
                style={{
                  border: "1px solid #cbd2d9",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  padding: 10,
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
          </div>
        </Sidebar>
      </>
    );
  }

  function renderRemoveMember() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={removeMemberModal}
        style={CancelReminder}>
        <div
          onClick={removeMemberModal}
          className="close-btn-icon-subscription">
          <Icon iconName="CrossBtnIcon" />
        </div>

        <div className="btn-accept-reject">
          <div className="confirm-cancelliation">
            <h2 className="cancel-text">Remove Member</h2>
            <p className="cancel-request m-b-10">
              Are you sure, you want to remove this member?
            </p>
            <div className="m-t-20">
              <PrimaryCta
                fifth
                onClick={() => postRemoveMembers()}
                fontSize={"font_size_regular"}
                className="blue-button"
                text={"Remove"}
                isLoading={isLoadingRemove}
              />

              <button
                className="confirm-btn-bid  m-l-32"
                onClick={() => removeMemberModal()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  function closeInviteMemberSidebar() {
    setInviteSidebarOpen(false);
  }
  const inviteSidebarHeader = () => <div>Invite Member</div>;
  const inviteSidebarFooter = () => (
    <div className="full-width">
      <PrimaryCta
        className="blue-button"
        fontSize={"font_size_large"}
        fullWidth={true}
        text={"Send Invite"}
        isLoading={loading}
        onClick={() => {
          postInviteMembers(updateProgress);
        }}
      />
    </div>
  );
  function inviteMember() {
    return (
      <div>
        <Sidebar
          isOpen={inviteSidebarOpen}
          sidebarWidth={"30%"}
          onClose={closeInviteMemberSidebar}
          Header={inviteSidebarHeader}
          Footer={inviteSidebarFooter}>
          <div className="p-20">
            <div className="m-b-10">
              <CustomText
                p
                text={"Name"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>

            <div className=" ">
              <CustomTextInput
                type="name"
                placeholder="Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.member_name}</h5>
            </div>
            <div className="m-b-10">
              <CustomText
                p
                text={"Email"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>
            <div className=" m-b-10">
              <CustomTextInput
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            <div className="m-b-10">
              <CustomText
                p
                text={"Phone Number"}
                textColor={"text_color_primary"}
                fontSize={"font_size_xl"}
              />
            </div>
            <div className="m-b-10 whatsapp-number">
              <PhoneInput
                defaultCountry="IN"
                value={number}
                onChange={setNumber}
                placeholder="Enter phone number"
              />
            </div>
          </div>
        </Sidebar>
      </div>
    );
  }

  const customBidStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "14px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };
  const inviteMemberTableSchema = [
    {
      name: "Name",
      selector: (row) => row["name"],
      width: "300px",
    },
    {
      name: "Email",
      selector: (row) => row["email"],
    },
    {
      name: "Status",
      selector: (row) =>
        row?.is_accepted == true ? "Accepted" : "Not Accepted",
      // width: "100px",
    },

    {
      name: "",
      paddingRight: "20px",
      cell: (item) => (
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}>
          <div className="">
            <PrimaryCta
              fifth
              onClick={() => removeMemberModal(item.invite_id)}
              text={"Remove"}
              fontSize={"font_size_small"}
              className="blue-button"
            />
          </div>
        </span>
      ),
    },
  ];
  return (
    <>
      <div className="dashboard-container background-color-primary">
        <SideMenu />
        <div className="main-screen">
          <Header
            onLoadHeader={(subscription_list) =>
              ActionOnLoadHandler(subscription_list)
            }
          />
          <div className="page-container">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="team-member-header">
                  <CustomText
                    p
                    text={"Team Members"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                  />
                  <PrimaryCta
                    fifth
                    className="blue-button"
                    fontSize={"font_size_regular"}
                    text={"Invite Member"}
                    onClick={() => inviteToggle()}
                  />
                </div>
                {memberList && memberList?.length == 0 ? (
                  <div className="fall-back-center-campaign">
                    <FallBackBtn
                      heading={"No Member available"}
                      title={"Active member will be shown here"}
                      // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                    />
                  </div>
                ) : (
                  <div class="">
                    <div style={{ paddingBottom: "20px" }}>
                      <CustomTable
                        className="CustomTable"
                        tableSchema={inviteMemberTableSchema}
                        tableData={memberList}
                        rowPaddingTop={16}
                        rowPaddingBottom={16}
                        customStyles={customBidStyles}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {modalIsOpenRemove && renderRemoveMember()}
          {modalIsOpen && (
            <SubscriptionModal
              subscriptionData={subscriptionList}
              closeModal={closeModal}
              orgData={org_deep_data?.org_data}
            />
          )}
          {inviteMember()}
          {renderInviteMember()}
          {/* <ReportSidebar /> */}
        </div>
      </div>
    </>
  );
}

export default TeamMembersList;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    height: "28%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
    padding: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
