import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Core from "../common/clientSdk";
import * as Actions from "../redux/actions";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  Search,
  NotificationsIcon,
  ProfileDropDownIcon,
  FilterIcons,
} from "../components/assets/icons";
import { FallBack } from "../components/complex_component/index";

import { Image, CustomText, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import MyCreditsScreen from "./myCredits/myCreditsScreen";
import utils from "../common/utils";
import BrandListScreen from "./brandSetiing/brandListScreen";
import { useSnackbar } from "react-simple-snackbar";

const ReportSidebar = (props) => {
  // Initialize Private Functions
  const [brandReport, setBrandReport] = useState({});
  const [openSnackbar] = useSnackbar();
  const [isLoading, setLoading] = useSnackbar(false);

  useEffect(() => {
    getBrandReports();
  }, []);

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  function getBrandReports() {
    if (redux_selector.UserData.brand_deep_data) {
      var params = {
        brand_id:
          redux_selector?.UserData?.brand_deep_data?.brand_data?.brand_id,
      };
      Core.getBrandReports(
        getBrandReportsSuccess,
        getBrandReportsFailed,
        setLoading,
        params
      );
    }
  }

  function getBrandReportsSuccess(response) {
    setBrandReport(response);
  }

  function getBrandReportsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function downloadAppPlayStore() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.reelax.ads",
      "_blank"
    );
  }
  function downloadAppStore() {
    window.open(
      "https://apps.apple.com/in/app/reelax-ads-for-brands/id6464460089",
      "_blank"
    );
  }

  return (
    <div
      style={{
        width: "13%",
        position: "fixed",
        right: 0,
        bottom: 0,
        top: 75,
        padding: 13,
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: "var(--general-secondary-color)",
      }}
    >
      <div className="m-b-30">
        <CustomText
          p
          text={"Brand Report"}
          fontSize={"font_size_extra_large"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
      </div>
      <div className="m-b-20">
        <CustomText
          p
          text={"Total Contracts"}
          fontSize={"font_size_medium"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
        <div style={{ padding: 10 }}>
          <CustomText
            p
            text={brandReport.total_contract}
            fontSize={"font_size_medium"}
            textAlign={"text_align_left"}
            fontWeight={"rubik_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      <div className="m-b-20">
        <CustomText
          p
          text={"Total Proposals"}
          fontSize={"font_size_medium"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
        <div style={{ padding: 10 }}>
          <CustomText
            p
            text={brandReport.total_bids}
            fontSize={"font_size_medium"}
            textAlign={"text_align_left"}
            fontWeight={"rubik_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      <div className="m-b-20">
        <CustomText
          p
          text={"Total Campaign Views"}
          fontSize={"font_size_medium"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
        <div style={{ padding: 10 }}>
          <CustomText
            p
            text={brandReport.total_view}
            fontSize={"font_size_medium"}
            textAlign={"text_align_left"}
            fontWeight={"rubik_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      <div className="m-b-20">
        <CustomText
          p
          text={"Total Content Reach"}
          fontSize={"font_size_medium"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
        <div style={{ padding: 10 }}>
          <CustomText
            p
            text={brandReport.total_reach}
            fontSize={"font_size_medium"}
            textAlign={"text_align_left"}
            fontWeight={"rubik_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      <div className="m-b-20">
        <CustomText
          p
          text={"Total Spent"}
          fontSize={"font_size_medium"}
          textAlign={"text_align_left"}
          fontWeight={"rubik_regular"}
          textColor={"text_color_primary"}
        />
        <div style={{ padding: 10 }}>
          <CustomText
            p
            text={brandReport.total_spent}
            fontSize={"font_size_medium"}
            textAlign={"text_align_left"}
            fontWeight={"rubik_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      {/* <div className="cursor-pointer" onClick={downloadAppPlayStore}>
        <Image
          width={140}
          source={"../../assets/images/play-store-light.svg"}
        />
      </div>
      <div className="cursor-pointer m-t-6" onClick={downloadAppStore}>
        <Image width={140} source={"../../assets/images/app-store-light.svg"} />
      </div> */}
    </div>
  );
};

export default ReportSidebar;
