import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Core from "../common/clientSdk";
import { data } from "jquery";
import validator from "validator";
import { Sidebar } from "../components";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../redux/actions";
import { toast, ToastContainer } from "react-toastify";
import { set } from "date-fns";
function UserDesignation({changeDesignationSidebarOpen, setChangeDesignationSidebarOpen}) {
  const [isLoading, setLoading] = useState(false);
  const [changeDesignation, setChangeDesignation] = useState("");
  const [error, set_error] = useState({
    designation: "",
  });
  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;

  useEffect(() => {
    setChangeDesignation(user_data.designation);
  }, []);

  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        designation: changeDesignation,
      }),
    };
    if (validateName(changeDesignation))
      Core.postUpdateUserProfile(
        postUpdateUserProfileSuccess,
        postUpdateUserProfileFailed,
        progress_cb,
        params
      );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    setChangeDesignationSidebarOpen(false);
    toast.success("Designation changed successfully!");
  }

  function postUpdateUserProfileFailed(errorList) {
    toast.error("Designation not changed ");
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, designation: "Please enter name" });
    } else {
      set_error({ ...error, designation: "" });
      return true;
    }
  }
  const changeFullNameSidebarHeader = () => {
    return <CustomText p text={"Change Designation"} />;
  };
  const changeFullNameSidebarFooter = () => {
    return (
        <PrimaryCta
          fontSize={"font_size_large"}
          fullWidth100
          className="blue-button"
          text={"Update"}
          isLoading={isLoading}
          onClick={() => {
            postUpdateUserProfile(updateProgress);
          }}
        />
    );
  };
  return (
    <div className="">
      <Sidebar
        sidebarWidth={"30%"}
        isOpen={changeDesignationSidebarOpen}
        onClose={() => setChangeDesignationSidebarOpen(false)}
        Header={changeFullNameSidebarHeader}
        Footer={changeFullNameSidebarFooter}
        headerAlignment="left"
      > 
        <div className="p-20">
        <div className="">
          <CustomText p text={"Designation"} />
        </div>

        <div style={{ marginTop: "10px" }}>
          <div className="full-width">
            <CustomTextInput
              type="name"
              value={changeDesignation}
              height={51}
              onChange={(e) => setChangeDesignation(e.target.value)}
              onBlur={(e) => validateName(e.target.value)}
            />
            <h5 className="error">{error.designation}</h5>
          </div>
          <div className="m-l-15"></div>
        </div>
        </div>
      </Sidebar>
      <ToastContainer />
    </div>
  );
}

export default UserDesignation;
