import React, { useState } from "react";
import { useSelector } from "react-redux";
import Utils from "../common/utils";
import { CustomText, Image } from "../components";
import InfluencerContentSocialMedia from "./influencerContentSocialMedia";
import Icon from "../components/core_component/icon";

function InfluencerContentCard(props) {
  console.log("sandeep", props.item);

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const {
    imageSource,
    influencerDp,
    influencerUsername,
    influencerHandle,
    likes,
    comments,
    views,
    date,
    videoUrl,
    heartIconProps,
    commentIconProps,
    viewIconProps,
  } = props;
  const handlePlatformClick = (url) => {
    // Open the platform URL here
    window.open(url, "_blank");
  };
  return (
    <div
      onClick={() => window.open(props.item.url, "_blank")}
      className="content-media-card-container cursor-pointer"
    >
      <div className="upper-content-media-card-container">
        <div className="upper-content-media-card">
          <div className="content-media-card-image">
            {/* <Image height="100%" width="100%" source={imageSource} /> */}
            <video
              width="100%"
              height="100%"
              controls={false}
              poster={imageSource}
            >
              <source
                src={Utils.constructContentMediaUrl(
                  props.item.assignmentId,
                  props.item.imageSource
                )}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="influencer-detail-analytics-container">
            <Image
              className="influencer-detail-analytics-dp"
              source={
                props.item?.imageSource
                  ? Utils.generateInfluencerDpUrl(
                      props.item?.influencerId,
                      props.item?.influencerDp
                    )
                  : "../assets/images/user-fallback.svg"
              }
              fallBack={require("../resources/images/user-copy.png")}
            />
            <div>
              <CustomText
                p
                text={props.item.influencerUsername}
                className="button2_light m-l-3"
                textColor={"text_color_light"}
              />
              <CustomText
                p
                text={`@${props.item.influencerHandle}`}
                className="caption_2 m-l-3"
                textColor={"text_color_light"}
              />
            </div>
          </div>
          <div className="social-media-icon-analytics">
            <Icon iconName="reelstIcon" />
          </div>
        </div>
        <>
          <InfluencerContentSocialMedia
            brand_deep_data={brand_deep_data}
            item={props.item}
          />
        </>
      </div>
    </div>
  );
}

export default InfluencerContentCard;
