import React, { useState, useRef, useEffect } from "react";
// import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import Icon from "../../components/core_component/icon";
import { BrandCard, CustomText } from "../../components";
import { PieChart } from "react-minimal-pie-chart";
import Utils from "../../common/utils";
import InfluencerProfileContentCard from "./influencerProfileContentCard";
import contentList from "./../../resources/data/content_data.json";
import { avatarClasses } from "@mui/material";
import { Loader } from "../loader";
function InfluencerInstagramTab(props) {
  const [selectedSection, setSelectedSection] = useState("overview");
  const [influencerContentSort, setInfluencerContentSort] = useState([]);
  const [contentToFilter, setContentToFilter] = useState("remove_filter");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  console.log("contentLoading", props.contentLoading);
  console.log("contentLoading 2", props);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterMediaByType = (mediaList, typeMappings) => {
    const images = [];
    const videos = [];
    mediaList.forEach((media) => {
      if (typeMappings[media["content_type_id"]] == "IMAGE") {
        images.push(media);
      }
      if (typeMappings[media["content_type_id"]] == "VIDEO") {
        videos.push(media);
      }
    });
    return { images, videos };
  };

  const { images, videos } = filterMediaByType(
    props.influencerContentList,
    Utils.typeMappings()
  );

  const calculateAverageLikesAndComments = (posts) => {
    const totalLikes = posts.reduce((sum, post) => sum + post.like_count, 0);
    const totalComments = posts.reduce(
      (sum, post) => sum + post.comment_count,
      0
    );
    const totalView = posts.reduce((sum, post) => sum + post.view_count, 0);

    const avgLikes = totalLikes / posts.length;
    const avgComments = totalComments / posts.length;
    const avgView = totalView / posts.length;

    const cotentEngagementRate = ((avgLikes + avgComments) / avgView) * 100;

    return {
      totalView,
      avgLikes: avgLikes.toFixed(1),
      avgComments: avgComments.toFixed(1),
      avgView: avgView.toFixed(1),
      cotentEngagementRate: cotentEngagementRate.toFixed(1),
    };
  };

  const calculateTotalEngagementRatio = (posts) => {
    const totalEngagement = posts.reduce(
      (sum, post) => sum + post.like_count + post.comment_count,
      0
    );

    const totalViews = posts.reduce((sum, post) => sum + post.view_count, 0);

    const engagementRatio = ((totalEngagement / totalViews) * 100).toFixed(1); // Percentage
    return engagementRatio;
  };

  const averagesImages = calculateAverageLikesAndComments(images);
  const averagesVideos = calculateAverageLikesAndComments(videos);

  const engVideo = calculateTotalEngagementRatio(videos);

  useEffect(() => {
    setInfluencerContentSort(props.influencerContentList);
  }, [props.influencerContentList]);

  const sectionsRef = {
    overview: useRef(null),
    engagement: useRef(null),
    content: useRef(null),
    audience: useRef(null),
    brand: useRef(null),
    url: useRef(null),
  };

  const getMediaType = (contentTypeId) => {
    const content = contentList.find(
      (item) => item.content_data.content_type_id === contentTypeId
    );
    return content ? content.content_data.media_type : null;
  };

  function sortContentByRemoveFilter() {
    setInfluencerContentSort(props.influencerContentList);
    setContentToFilter("remove_filter");
  }
  function sortContentByViewCount() {
    const sortedData = [...props.influencerContentList].sort(
      (a, b) => b.view_count - a.view_count
    );

    setInfluencerContentSort(sortedData);
    setContentToFilter("view_count");
    // return sortedData;
  }

  function sortContentByLatestDate() {
    const latestPost = [...props.influencerContentList].sort(
      (a, b) => new Date(b.content_posted_on) - new Date(a.content_posted_on)
    );

    setInfluencerContentSort(latestPost);
    setContentToFilter("latest_date");
    // return sortedData;
  }

  function youtubeEngDataList() {
    let engDataList = [];

    props.influencerContentList.forEach((item) => {
      if (Utils.typeMappings()[item["content_type_id"]] == "VIDEO") {
        engDataList.push({
          view_count: item.view_count || "-",
          like_count: item.like_count || "-",
          comment_count: item.comment_count.toFixed(0) || "-",
          engagement:
            (
              ((item?.comment_count + item?.like_count) / item?.view_count) *
              100
            ).toFixed(0) || "-",
          post_time: item.content_posted_on,
        });
      }
    });
    engDataList = Utils.sortByKey(engDataList, "post_time", "asc");
    return engDataList;
  }

  function scrollToSection(section) {
    setSelectedSection(section);
    sectionsRef[section]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  function renderSidebarInfluencer() {
    const sidebarItems = [
      { name: "Overview", key: "overview", iconName: "platformIcon" },
      { name: "Engagement", key: "engagement", iconName: "engHeartIcon" },
      { name: "Content", key: "content", iconName: "contentIcon" },
      // { name: "Audience", key: "audience", iconName: "audienceIcon" },
      // { name: "Brands", key: "brand", iconName: "brandIcon" },
      { name: "Visit Profile", key: "url", iconName: "instagramIcon" },
    ];

    const handleClick = (item) => {
      if (item.key === "url") {
        const url = Utils.getSocialUrl(
          props?.instagramEngagementData?.[0]?.platform_id,
          props?.instagramEngagementData?.[0]?.identifier_id
        );
        if (url) {
          window.open(url, "_blank", "noopener,noreferrer");
        } else {
          console.error("Invalid URL or data missing");
        }
      } else {
        scrollToSection(item.key);
      }
    };

    return (
      <div className="influencer-sidebar-profile-main-container  flex-column gap-30">
        {sidebarItems.map((item) => (
          <div
            onClick={() => handleClick(item)}
            key={item.name}
            className={`flex-row gap-10 cursor-pointer align-center ${
              selectedSection === item.key
                ? "selected-sidebar"
                : "gray-gray-300"
            }`}
          >
            <div
              style={{
                width: 18,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                iconName={item.iconName}
                height={20}
                width={20}
                color={selectedSection == item.key && "#1878f3"}
              />
            </div>

            <CustomText p text={item.name} className="button1" />
          </div>
        ))}
      </div>
    );
  }

  function likeCommentRatioFun() {
    let likeCount = 0;
    let commentCount = 0;

    props.influencerContentList.forEach((item) => {
      likeCount += Number(item.like_count) || 0;
      commentCount += Number(item.comment_count) || 0;
    });

    return likeCount + commentCount;
  }

  function renderInstagramContentData() {
    return (
      <>
        <div className="profile-engagement-view-container m-b-20">
          <div>
            <div className="flex-row gap-10">
              <CustomText
                p
                text={"Performance Metrics"}
                className="Caption-3 m-b-20"
              />
            </div>
            <div
              style={{ borderBottom: "1px solid #e6e7e8" }}
              className="profile-engagement-view-inner"
            >
              <div className="instagram-data-view">
                <Icon
                  iconName={
                    props?.instagramEngagementData?.[0]?.platform_id == "btll"
                      ? "reelsIconInstagram"
                      : "youtubeIcon"
                  }
                />
                <CustomText p text={"Video"} className="button2" />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. Views"} className="caption_2" />
                <CustomText
                  p
                  text={Utils.changeNumberFormate(averagesVideos?.avgView)}
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. Likes"} className="caption_2" />
                <CustomText
                  p
                  text={Utils.changeNumberFormate(averagesVideos?.avgLikes)}
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. Comments"} className="caption_2" />
                <CustomText
                  p
                  text={Utils.changeNumberFormate(averagesVideos.avgComments)}
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText
                  p
                  text={"Content Engagement Rate"}
                  className="caption_2"
                />
                <CustomText
                  p
                  text={
                    isNaN(averagesVideos.cotentEngagementRate)
                      ? 0
                      : averagesVideos.cotentEngagementRate
                  }
                  className="button2"
                />
              </div>
            </div>
            {images.length > 0 && (
              <div className="profile-engagement-view-inner">
                <div className="instagram-data-view">
                  <Icon iconName="instagramIcon" />
                  <CustomText p text={"Image"} className="button2" />
                </div>

                <div className="instagram-data-view">
                  <CustomText p text={"Avg. Likes"} className="caption_2" />
                  <CustomText
                    p
                    text={Utils.changeNumberFormate(averagesImages?.avgLikes)}
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText p text={"Avg. Comments"} className="caption_2" />
                  <CustomText
                    p
                    text={Utils.changeNumberFormate(averagesImages.avgComments)}
                    className="button2"
                  />
                </div>
              </div>
            )}
          </div>
          {/* <div className="wrap-data-engagements-view">
          <div className="flex-row gap-10">
            <CustomText p text={"POST FREQUENCY"} className="button2_light" />
          </div>
          <div className="wrap-data-engagements-view-child">
            <CustomText p text={"57777"} className="h6Text" />
            <CustomText
              p
              text={"Average ratio for similar influencers is around 0.96."}
              className="button2_light"
            />
          </div>
        </div> */}
        </div>

        <div className="profile-engagement-view-container m-b-20">
          <div className="wrap-data-engagements-view m-b-20">
            <div className="flex-row gap-10">
              <CustomText
                p
                text={"LIKES - COMMENTS RATIO"}
                className="button2_light"
              />
              {/* <Icon iconName={"infoCircleIcon"} /> */}
            </div>
            <div className="wrap-data-engagements-view-child">
              <CustomText
                p
                text={(
                  averagesVideos?.avgLikes / averagesVideos?.avgComments
                ).toFixed(1)}
                className="h6Text"
              />
            </div>
          </div>
          <div className="wrap-data-engagements-view">
            <div className="flex-row gap-10">
              <CustomText
                p
                text={"REEL VIEWS TO FOLLOWERS RATIO "}
                className="button2_light"
              />
              {/* <Icon iconName={"infoCircleIcon"} /> */}
            </div>
            <div className="wrap-data-engagements-view-child">
              <CustomText
                p
                text={(
                  averagesVideos.avgView /
                  (props?.instagramEngagementData[0]?.followers / 100)
                ).toFixed(1)}
                className="h6Text"
              />
              {/* <CustomText
              p
              text={"Average ratio for similar influencers is around 0.96."}
              className="button2_light"
            /> */}
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderInstagramContent() {
    return (
      <>
        <div className="m-b-20">
          {getHashTag().length > 0 && (
            <div className="profile-engagement-view-content-container">
              <div className="heading-info">
                <CustomText p text={"HASHTAGS"} className="Caption-3 m-b-20" />
                {/* <Icon iconName="infoCircleIcon" /> */}
              </div>

              <div className="influencer-profile-hag-tag-container m-b-15">
                {getHashTag().map((item) => {
                  return (
                    <div className="influencer-profile-hag-tag">
                      <CustomText p text={item} className=" typography14" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="m-b-20 profile-engagement-view-content-container">
          <div className="heading-info">
            <CustomText p text={"CONTENT ANALYTICS"} className="Caption-3 " />
            {/* <Icon iconName="infoCircleIcon" /> */}
          </div>
          <div className="filter-influencer-content-cta-container ">
            <div
              className={
                contentToFilter == "remove_filter"
                  ? "cursor-pointer filter-influencer-content-cta"
                  : "cursor-pointer"
              }
              onClick={() => sortContentByRemoveFilter()}
            >
              {" "}
              <CustomText p text={"All"} className="typography14" />
            </div>
            <div
              className={
                contentToFilter == "view_count"
                  ? "cursor-pointer filter-influencer-content-cta"
                  : "cursor-pointer"
              }
              onClick={() => sortContentByViewCount("")}
            >
              {" "}
              <CustomText p text={"Top Posts"} className="typography14" />
            </div>
            <div
              className={
                contentToFilter == "latest_date"
                  ? "cursor-pointer filter-influencer-content-cta"
                  : "cursor-pointer"
              }
              onClick={() => sortContentByLatestDate()}
            >
              {" "}
              <CustomText p text={"Latest Posts"} className="typography14" />
            </div>
          </div>
          <div className="flex-row gap-10 cursor-pointer  brands-card-container">
            {influencerContentSort.map((item) => {
              return (
                <InfluencerProfileContentCard
                  item={item}
                  influencerData={props?.influencerData}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }

  function renderInstagramAudience() {
    return (
      <div className="profile-engagement-view-container">
        <div>
          <CustomText p text={"AUDIENCE"} className="Caption-3 m-b-20" />

          <div className="audi-count-main-container">
            <div className="upper-audi-cart-data">
              <div className="lower-audi-cart-data">
                <div>
                  <CustomText p text={"TOP CITY"} className="Caption-3" />
                </div>
                <div>
                  <CustomText p text={"Delhi"} className="button2_light" />
                </div>
                <div>
                  <CustomText p text={"11M"} className="button1" />
                </div>
              </div>
            </div>
            <div className="upper-audi-cart-data">
              <div className="lower-audi-cart-data">
                <div>
                  <CustomText p text={"TOP CITY"} className="Caption-3" />
                </div>
                <div>
                  <CustomText p text={"Delhi"} className="button2_light" />
                </div>
                <div>
                  <CustomText p text={"11M"} className="button1" />
                </div>
              </div>
            </div>
            <div className="upper-audi-cart-data">
              <div className="lower-audi-cart-data">
                <div>
                  <CustomText p text={"TOP CITY"} className="Caption-3" />
                </div>
                <div>
                  <CustomText p text={"Delhi"} className="button2_light" />
                </div>
                <div>
                  <CustomText p text={"11M"} className="button1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function chartInstagram() {
    return (
      <div>
        <PieChart
          totalValue={100}
          data={[
            { title: "One", value: 70, color: "green" },
            { title: "Two", value: 30, color: "red" },
          ]}
          lineWidth={18} // Controls the thickness of the donut ring
          radius={50} // Adjusts the size of the chart
          label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
          labelStyle={{
            fontSize: "5px",
            fill: "#fff",
          }}
        />
      </div>
    );
  }
  function renderBarChart(data) {
    const minEngagement = Math.min(...data.map((d) => d.engagement));
    const maxEngagement = Math.max(...data.map((d) => d.engagement));

    // Add some buffer for better visualization
    const buffer = 2;
    const yAxisDomain = [
      Math.max(0, minEngagement - buffer),
      maxEngagement + buffer,
    ];

    return (
      <div className="profile-engagement-view-container">
        <div className="m-b-20">
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={"Content Progress"}
            height={"line_height_22_5"}
            fontWeight={"Inter_medium_500"}
          />
        </div>
        <div className="engagement-column-chart-containe text-align ">
          <div className="engagement-column-chart-inner-container">
            <div
              className="engagement-column-chart"
              style={{ transform: "rotate(270deg)" }}
            >
              <CustomText
                p
                fontSize={"font_size_medium"}
                text={"Content Engagement Rate %"}
                height={"line_height_22_5"}
                fontWeight={"Inter_medium_500"}
              />
            </div>
            <div className="chart-line-container">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={data}
                  barCategoryGap={0}
                  margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
                >
                  {/* X-Axis for Posts */}
                  <XAxis
                    dataKey="post_time"
                    name="Posted On"
                    interval={0} // Force display all ticks
                    tickFormatter={Utils.convertUnixToHumanReadable}
                    tick={{ angle: -45, textAnchor: "end" }} // Rotates labels to prevent overlap
                  />
                  {/* Y-Axis for Engagement Count */}
                  <YAxis
                    dataKey="engagement"
                    type="number"
                    interval={0}
                    domain={yAxisDomain}
                  />
                  {/* Tooltip to show engagement on hover */}
                  {/* <Tooltip /> */}
                  {/* Bar for Engagement Count */}
                  {/* <Bar dataKey="engagement" fill="#1977f2" /> */}
                  <Line
                    type="monotone"
                    name="Content Engagement Rate %"
                    dataKey="engagement"
                    stroke="#1977f2"
                    connectNulls={true} // Connects missing values
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={"Latest Posts"}
            height={"line_height_22_5"}
            fontWeight={"Inter_medium_500"}
          />
        </div>
      </div>
    );
  }

  function renderCardCount(text, value) {
    return (
      <div className="card-count-container  gap-20">
        <div>
          <CustomText p text={text} className="button1 gray-gray-400 m-b-15" />
        </div>
        <div>
          <CustomText p text={value} className="typography_18 gray-gray-500" />
        </div>
      </div>
    );
  }

  // function renderOverview() {
  //   return (
  //     <div className="influencer-reelax-score-main-container ">
  //       <div
  //         style={{
  //           width: "40%",
  //           display: "flex",
  //           flexDirection: "column",
  //         }}
  //       >
  //         <div className="m-b-20">
  //           <CustomText p text={"REELAX SCORE"} className="text-13" />
  //         </div>
  //         {chartInstagram()}
  //       </div>
  //       <div>
  //         <div className="m-b-20">
  //           <CustomText p text={"INSIGHTS FOR YOU"} className="text-13" />
  //         </div>
  //         <div className="overview-influencer-container">
  //           <div className="overview-left-side">
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>

  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="overview-left-side">
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //             <div className="influencer-reelax-score-container">
  //               <div>
  //                 <Icon iconName="plusGreenIcon" />
  //               </div>

  //               <div>
  //                 <CustomText
  //                   p
  //                   text={"REELAX SCORE"}
  //                   className="text-16  m-b-10"
  //                 />
  //                 <CustomText
  //                   p
  //                   text={
  //                     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et dolor voluptatem natus animi voluptatibus non officia, rerum iusto ab molestiae.</span>"
  //                   }
  //                   className="text-14"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  function getHashTag() {
    // Step 1: Extract and split hashtags
    const hashtagsArray = props?.influencerContentList.map((item) =>
      item?.hash_tags?.split(",").map((tag) => tag.trim())
    );

    // Step 2: Flatten manually using reduce
    const flattened = hashtagsArray.reduce((acc, curr) => acc.concat(curr), []);

    // Step 3: Remove duplicates using indexOf
    const uniqueHashTags = flattened.filter(
      (tag, index, arr) => arr.indexOf(tag) === index
    );

    return uniqueHashTags;
  }

  const handlePlatformClick = (url) => {
    // Open the platform URL here
    window.open(url, "_blank");
  };
  return (
    <div className="profile-tab-main-container">
      {renderSidebarInfluencer()}
      {!props.influencerContentList || props.contentLoading ? (
        <div style={{ minHeight: 500, margin: "auto", display: "flex" }}>
          <Loader />
        </div>
      ) : (
        <div className="flex-1 p-l-20 profile-overflow-container">
          <div
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          >
            <div
              ref={sectionsRef.overview}
              className="card-count-main-container"
            >
              {renderCardCount(
                "FOLLOWERS",
                Utils.changeNumberFormate(
                  props?.instagramEngagementData[0]?.followers
                )
              )}
              {renderCardCount(
                "ENGAGEMENT RATE %",
                props?.instagramEngagementData[0]?.engagement_rate
              )}
              {renderCardCount(
                "TOTAL CONTENTS",
                props?.instagramEngagementData[0]?.content_count
              )}
            </div>
            {/* <div ref={sectionsRef.engagement}>{renderOverview()}</div> */}
            <div ref={sectionsRef.engagement}>
              {renderInstagramContentData()}
              <div className="m-t-10">
                {" "}
                {renderBarChart(youtubeEngDataList())}
              </div>
            </div>
            <div ref={sectionsRef.content}>{renderInstagramContent()}</div>
            {/* <div ref={selectedSection.brand}>{renderInstagramAudience()}</div> */}
            <div
              ref={selectedSection.url}
              onClick={() =>
                handlePlatformClick(
                  Utils.getSocialUrl(
                    props?.instagramEngagementData[0]?.platform_id,
                    props?.instagramEngagementData[0]?.identifier_id
                  )
                )
              }
            ></div>

            {/* {chartInstagram()} */}
          </div>
        </div>
      )}
    </div>
  );
}

export default InfluencerInstagramTab;
