import React, { useState, useEffect } from "react";
import { CustomTextInput, ModalComponent } from "../../components";
import Modal from "react-modal";
import { CustomText, PrimaryCta, TeamMembers } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useDispatch, useSelector } from "react-redux";
import Sdk from "../../common/core/module";
import * as Action from "../../redux/actions";
import * as CampaignData from "../../common/data/campaign";
import WebStorage from "../../common/web_storage";
import { BRAND_ID_KEY } from "../../common/web_storage_keys";
import { useSnackbar } from "react-simple-snackbar";
import { Progress } from "reactstrap";
import SubscriptionModal from "../../modal/subscriptionModal";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import AddBrandModal from "./addBrandModal";
import Utils from "../../common/utils";
import { useToast } from "../../features/toastContainer";

function BrandListScreen(props) {
  const {
    closeModal = {},
    checkOrgSubUser = {},
    subscriptionData = {},
    setAddNewBrandModalOpen,
    checkAddressPayment,
  } = props;
  const [brandList, setBrandList] = useState([]);
  const [brandSwitchSheetVisible, setBrandSwitchSheetVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [brand, setBrand] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addBrand, setAddBrand] = useState("11");
  const showToast = useToast();
  var navigate = useNavigate();

  useEffect(() => {
    getBrandList(updateProgress);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  var redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const { UserData = {} } = redux_selector;

  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { roles_and_permission = {} } = UserData;
  const { brand_id = {} } = brand_deep_data.brand_data;

  function getBrandList(progressCb) {
    var params = {
      org_id: Sdk.User.getUserOrgId(UserData.user_data.org_id),
    };
    Core.brandList(brandListSuccess, brandListFailed, progressCb, params);
  }

  function brandListSuccess(response) {
    if (
      UserData.user_data?.role == "ADMIN" ||
      roles_and_permission?.brand_ids.includes("all")
    ) {
      setBrandList(response?.brand_list);
    } else {
      let roleBrand = response?.brand_list.filter((item) => {
        return roles_and_permission?.brand_ids.includes(
          item?.brand_data?.brand_id
        );
      });

      setBrandList(roleBrand);
    }

    setLoading(false);
  }

  function brandListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getBrandData(brand_id) {
    var params = {
      brand_id: brand_id,
      org_id: Sdk.User.getUserOrgId(UserData.user_data.org_id),
    };
    Core.getBrandData(
      getBrandDataSuccess,
      getBrandDataFailed,
      (status) => {
        setLoading(status);
      },
      params,
      redux_selector.Auth.auth_token
    );
  }

  function getBrandDataSuccess(response_data) {
    //update brand data in redux
    redux_dispatch({
      type: Action.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.b_d },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.b_d.brand_data.brand_id
    );
    window.location.reload();
  }
  console.log("WebStorage", WebStorage.getDataFromWebStorage(BRAND_ID_KEY));
  function getBrandDataFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      height: "80%",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-around",
      minWidth: "400px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  };

  function maxBidCountCheck() {
    if (checkOrgSubUser?.feature?.max_brand_count) {
      return checkOrgSubUser?.feature?.max_brand_count;
    }
    return 0;
  }

  function SwitchBrandAdd() {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["setting"]["permissions"]["brand_setting"]
      )
    ) {
      showToast();
    } else {
      if (!checkOrgSubUser?.feature?.max_brand_count) {
        openModalSubscription();
        // checkAddressPayment();
      } else if (
        brandList.length >= checkOrgSubUser?.feature?.max_brand_count
      ) {
        closeModal();
        toast.info(
          `you can add upto ${checkOrgSubUser?.feature?.max_brand_count} brands`
        );
      } else {
        console.log("demo1");
        addNewBrand();
        console.log("demo2");
      }
    }
  }

  const openModalSubscription = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModalSubscription = () => {
    setModalIsOpen(false);
  };

  const addNewBrand = () => {
    console.log("demo3");
    setAddNewBrandModalOpen(true);
    closeModal();
  };
  return (
    <>
      <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
        <div className="header-modal-container">
          <CustomText
            p
            text={"Switch Brand"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
          <div onClick={closeModal} className="close-icon-brand-list">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="brand-list-height">
              {brandList &&
                brandList.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        getBrandData(item.brand_data.brand_id);
                      }}
                      className={
                        brand_id == item.brand_data.brand_id
                          ? "brand-list-warp-active"
                          : "brand-list-warp"
                      }
                    >
                      <TeamMembers
                        invert
                        source={
                          item.brand_data.image_id
                            ? utils.generateBrandLogoUrl(
                                item.brand_data.org_id,
                                item.brand_data.image_id
                              )
                            : require("../../resources/images/fallback.png")
                        }
                        Name={item.brand_data.name}
                        title={`#${item.brand_data.brand_id}`}
                        Email={item.brand_data.note}
                        stateColor={""}
                        // state={"ACTIVE"}
                        // iconName={"DeleteIcons"}
                      />
                    </div>
                  );
                })}
            </div>

            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                className="blue-button"
                text={"Add a new Brand"}
                isLoading={isLoading}
                // disabled={Utils.isNull(creditId)}
                onClick={() => SwitchBrandAdd()}
              />
              {/* <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Add Brand"}
                isLoading={isLoading}
                // disabled={Utils.isNull(creditId)}
                onClick={() => addNewBrand()}
              /> */}
            </div>
          </>
        )}
      </Modal>

      {
        <SubscriptionModal
          subscriptionData={subscriptionData}
          closeModal={closeModalSubscription}
          orgData={org_deep_data?.org_data}
          modalIsOpen={modalIsOpen}
        />
      }
    </>
  );
}

export default BrandListScreen;
