import React, { useState } from "react";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import { CustomText, Image } from "../../components";
import Icon from "../../components/core_component/icon";

function InfluencerProfileContentCard(props) {
  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const {
    thumbnail,
    content_title,
    caption,
    owner_name,
    platform_id,
    comment_count,
    like_count,
    content_posted_on,
    shortcode,
    view_count,
    content_type_id,
  } = props.item;

  console.log("props.item", props);

  const handlePlatformClick = (url) => {
    // Open the platform URL here
    window.open(url, "_blank");
  };

  const contentType = Utils.typeMappings()[content_type_id];
  let influencerDp = props.influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        props.influencerData?.influencer_id,
        props.influencerData?.profile_picture
      )
    : require("../../resources/images/user-copy.png");

  return (
    <div
      onClick={() =>
        handlePlatformClick(Utils.getEmbedUrl(platform_id, shortcode))
      }
      className="content-media-card-container"
    >
      <div className="upper-content-media-card-container">
        <div className="upper-content-media-card">
          <div className="content-media-card-image">
            <Image
              height="100%"
              width="100%"
              source={thumbnail}
              fallBack={require("../../resources/images/user-copy.png")}
            />
          </div>
          <div className="influencer-detail-analytics-container">
            <Image
              className="influencer-detail-analytics-dp"
              source={influencerDp}
              fallBack={require("../../resources/images/user-copy.png")}
            />
            <div>
              <CustomText
                p
                text={owner_name}
                className="button2_light m-l-3"
                textColor={"text_color_light"}
              />
              <CustomText
                p
                text={`@${owner_name}`}
                className="caption_2 m-l-3"
                textColor={"text_color_light"}
              />
            </div>
          </div>
          <div className="social-media-icon-analytics">
            <Icon iconName="reelstIcon" />
          </div>
        </div>
        <>
          <div className="content-media-card-icons-main">
            <div className="content-media-card-icons">
              <div className="flex-row">
                <Icon iconName="heartIcon" height={15} width={15} />
                <CustomText
                  p
                  text={Utils.convertToK(like_count)}
                  className="caption_3 m-l-3"
                />
              </div>
              <div className="flex-row">
                <Icon iconName="commentCount" height={15} width={15} />
                <CustomText
                  p
                  text={Utils.convertToK(comment_count)}
                  className="caption_3 m-l-3"
                />
              </div>
              {contentType == "VIDEO" && (
                <div className="flex-row">
                  <Icon iconName="viewEyes" height={15} width={15} />
                  <CustomText
                    p
                    text={Utils.convertToK(view_count)}
                    className="caption_3 m-l-3"
                  />
                </div>
              )}
            </div>
            <div>
              <CustomText
                p
                text={Utils.formatUnixDateToCustom(content_posted_on)}
                className="caption_3 m-l-3"
              />
            </div>
          </div>
          <div className="content-text-analytic-container">
            <CustomText
              p
              text={caption ? caption : content_title}
              className="caption_2 m-l-3"
              textColor={"text_color_light"}
            />
          </div>
        </>
      </div>
    </div>
  );
}

export default InfluencerProfileContentCard;
