import React, { useEffect, useState } from "react";
import {
  Image,
  CustomText,
  Video,
  PrimaryCta,
  CustomButton,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import { toast } from "react-toastify";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import Modal from "react-modal";
import { Loader } from "../loader";
import { useSelector } from "react-redux";
import Icon from "../../components/core_component/icon";
import CustomTable from "../../components/customTable";
import { useNavigate } from "react-router-dom";
import ExpandedComponent from "./expandedComponent";
import { isMobile } from "react-device-detect";

const limit = 10;

function ContentData() {
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [contentAnalyticsData, setContentAnalyticsData] = useState("");

  const navigate = useNavigate();

  const openModal = (item) => {
    setCurrentItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  useEffect(() => {
    AssignmentContentList(start, updateProgress);
  }, []);

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;

  function updateProgress(status) {
    // setLoading(status);
  }

  function AssignmentContentList(start, updateProgress) {
    const params = {
      // limit: limit,
      // start: start,
      brand_id: brand_deep_data.brand_data.brand_id,
    };
    Core.AssignmentContentList(
      AssignmentContentListSuccess,
      AssignmentContentListFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentListSuccess(response) {
    setLoading(false);
    setContentList(response.assignment_list);
    // if (next == 0 || next == null) {
    //   setContentList(
    //     response.assignment_list.filter((item) => {
    //       return item.status == "COMPLETED";
    //     })
    //   );
    // } else {
    //   setContentList([
    //     ...contentList,
    //     ...response.assignment_list.filter((item) => {
    //       return item.status == "COMPLETED";
    //     }),
    //   ]);
    // }
    // setNext(response.pagination_data.next);
  }

  function AssignmentContentListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }
  function contractDetail(influencer_id) {
    navigate(`/contracts/${influencer_id}`);
  }

  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a new window to open the URL, triggering the download
    const newWindow = window.open(mediaUrl, "_blank");

    if (newWindow) {
      newWindow.focus();
    } else {
      // For cases where the popup blocker prevents opening a new window
      alert("Please allow popups for this website");
    }
  };

  const tableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],

      cell: (data) => (
        <span
          onClick={(e) => {
            influencerDetail(data?.influencer_data.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data.influencer_data.influencer_profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data.influencer_data.influencer_id,
                    data.influencer_data.influencer_profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            fallback={require("../../resources/images/user-copy.png")}
            className="proposal-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data.influencer_data.influencer_name}
                  className="Body-1"
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              {/* <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              /> */}
            </div>
          </div>
        </span>
      ),
      width: isMobile ? "150px" : "280px",
    },
    {
      name: "Contract ID",
      omit: isMobile,
      cell: (date) => (
        <div
          onClick={() =>
            contractDetail(date["assignment_data"]["assignment_id"])
          }
        >
          {date["assignment_data"]["assignment_id"]}
        </div>
      ),
      width: "130px",
    },

    {
      name: "Type",
      omit: isMobile,
      selector: (row) => {
        return Utils.getCampaignType(row["campaign_data"]["campaign_type"]);
      },
      width: "150px",
    },
    {
      name: "Platform",
      omit: isMobile,
      selector: (row) => {
        return row["platform_data"]["platform_name"];
      },
    },
    {
      name: "",
      lastCol: true,
      paddingRight: "20px",
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            justifyContent: "right",
          },
        },
      ],
      cell: (data) => (
        <span
          style={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <PrimaryCta
            fifth
            onClick={() => openModal(data)}
            className="blue-button"
            fontSize={"font_size_small"}
            text={"Preview"}
          />
        </span>
      ),
    },
  ];

  // const ExpandedComponent = ({ data }) => {
  //   const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  //   console.log("data", data);
  //   useEffect(() => {
  //     if (!analyticsLoaded) {
  //       AssignmentContentAnalytics(data);
  //       setAnalyticsLoaded(true);
  //     }
  //   }, [analyticsLoaded, data]);

  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         gap: 30,
  //         alignItems: "center",
  //         //  justifyContent: "center",
  //       }}
  //     >
  //       <div className="flex-row">
  //         <Icon iconName="heartIcon" />
  //         <CustomText
  //           p
  //           text={contentAnalyticsData.like_count}
  //           className="subtitle1"
  //         />
  //       </div>
  //       <div className="flex-row">
  //         <Icon iconName="commentCount" />
  //         <CustomText
  //           p
  //           text={contentAnalyticsData.comment_count}
  //           className="subtitle1"
  //         />
  //       </div>
  //       <div className="flex-row">
  //         <Icon iconName="viewEyes" />
  //         <CustomText
  //           p
  //           text={contentAnalyticsData.play_count}
  //           className="subtitle1"
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  function socialMedialEmbed(item) {
    const platform = Utils.socialMediaPlatform(
      item?.assignment_data.content_type_id
    );
    const mediaType =
      item?.assignment_data.media_id?.split(".").pop() === "mp4";

    return (
      <>
        {item.assignment_data.url && (
          <div className="embed-container">
            {platform === "Instagram" && (
              <InstagramEmbed url={item?.assignment_data.url} width={325} />
            )}
            {platform === "youtube" && (
              <YouTubeEmbed
                url={item.assignment_data.url}
                width={325}
                height={620}
              />
            )}
            {platform === "twitter" && (
              <XEmbed url={item.assignment_data.url} width={325} />
            )}
          </div>
        )}
        {item.assignment_data.media_id &&
          mediaType &&
          !item.assignment_data.url && (
            <div className="download-content">
              <Video
                source={Utils.constructContentMediaUrl(
                  item.assignment_data.assignment_id,
                  item.assignment_data.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                onClick={() =>
                  handleDownload(
                    item?.assignment_data?.media_id,
                    true,
                    item?.assignment_data?.assignment_id
                  )
                }
                fontSize={"font_size_large"}
                text={"Download"}
                className="blue-button"
              />
            </div>
          )}
        {item.assignment_data.media_id &&
          !mediaType &&
          !item.assignment_data.url && (
            <div className="download-content">
              <Image
                source={Utils.constructContentMediaUrl(
                  item.assignment_data.assignment_id,
                  item.assignment_data.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                onClick={() =>
                  handleDownload(
                    item?.assignment_data?.media_id,
                    false,
                    item?.assignment_data?.assignment_id
                  )
                }
                fontSize={"font_size_large"}
                text={"Download"}
                className="blue-button"
              />
            </div>
          )}
      </>
    );
  }

  function ExpandedComponentData(item) {
    return <ExpandedComponent item={item} brand_deep_data={brand_deep_data} />;
  }
  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList min-height-500">
          <div className=" m-b-16" style={{ marginLeft: "16px" }}>
            <CustomText
              h5
              text={"Creatives"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
              height={"line_height_24"}
            />
          </div>
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="custom-table">
              <CustomTable
                className="CustomTable"
                tableSchema={tableSchema}
                tableData={contentList}
                rowPaddingTop={16}
                paginationPerPage={15}
                rowPaddingBottom={16}
                customStyles={customStyles}
                expandableRowsComponent={(item) => ExpandedComponentData(item)}
                expandableRows
                pagination
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        style={CancelReminder}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
        {currentItem && socialMedialEmbed(currentItem)}
      </Modal>
    </div>
  );
}

export default ContentData;

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)",
      color: "var(--gray-gray-300)",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    minHeight: "500px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
