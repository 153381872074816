import React, { useEffect, useState } from "react";
import { CustomText } from "../components";
import Core from "../common/clientSdk";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function ContactDetailInfluencer(props) {
  const [influencerData, setInfluencerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { org_deep_data = {} } = UserData;
  useEffect(() => {
    if (props?.influencer_id) {
      getInfluencerContactRequested();
    }
  }, [props.isLoadingContact, props.influencerData]);
  function getInfluencerContactRequested() {
    var params = {
      influencer_id: props?.influencer_id,
      org_id: brand_deep_data?.brand_data?.org_id,
    };

    Core.getInfluencerContactRequested(
      getInfluencerContactRequestedSuccess,
      getInfluencerContactRequestedFail,
      (status) => setIsLoading(status),
      params
    );
  }

  function getInfluencerContactRequestedSuccess(demo, response) {
    props.getContactSuccess(response);
    setInfluencerData(response?.influencer_deep_data?.influencer_data);
  }

  function getInfluencerContactRequestedFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function getContactNumberShow(influencerData) {
    if (influencerData?.has_phone_number) {
      if (influencerData?.phone) {
        return `+91 ${influencerData.phone}`;
      } else {
        return `+91 **********`;
      }
    } else {
      return "Not available";
    }
  }
  function getContactEmailShow(influencerData) {
    if (influencerData?.has_email) {
      if (influencerData?.email) {
        return ` ${influencerData.email}`;
      } else {
        return `**********`;
      }
    } else {
      return "Not available";
    }
  }
  console.log("demo 1", influencerData);

  return (
    <>
      <div className="influencer_address_detail m-b-16">
        <div className="flex-row " style={{ gap: "4px" }}>
          <CustomText
            p
            text={"Phone Number"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
            height={"line_height_19_2"}
          />
          <div
            className="new-content-box"
            style={{ position: "relative", bottom: "4px" }}
          >
            <p> New </p>
          </div>
        </div>
        {isLoading ? (
          <div>
            {" "}
            <Lottie options={defaultOptions} height={15} width={100} />
          </div>
        ) : (
          <CustomText
            p
            text={getContactNumberShow(influencerData)}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        )}
      </div>
      <div className="influencer_address_detail m-b-16">
        <div className="flex-row " style={{ gap: "4px" }}>
          <CustomText
            p
            text={"Email Address"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
            height={"line_height_19_2"}
          />
          <div
            className="new-content-box"
            style={{ position: "relative", bottom: "4px" }}
          >
            <p> New </p>
          </div>
        </div>
        {isLoading ? (
          <div>
            {" "}
            <Lottie options={defaultOptions} height={15} width={100} />
          </div>
        ) : (
          <CustomText
            p
            text={getContactEmailShow(influencerData)}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        )}
      </div>
    </>
  );
}

export default ContactDetailInfluencer;
