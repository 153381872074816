import React, { useState, useEffect, useRef } from "react";
import Header from "../header";
import SideMenu from "../sideMenu";
import { useNavigate } from "react-router-dom";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import { CardComponent, Image, Sidebar } from "../../components";
import Utils from "../../common/utils";
import { useSelector } from "react-redux";
import { tiltedArrow } from "../../components/assets/icons";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import { useToast } from "../../features/toastContainer";
//icons used in category section
const categoryIcons = [
  {
    value: "4v4m",
    path: "assets/images/finance.png",
  },
  {
    value: "5c20",
    path: "assets/images/entertain.png",
  },
  {
    value: "9w6m",
    path: "assets/images/restaurant.png",
  },
  {
    value: "afie",
    path: "assets/images/auto.png",
  },
  {
    value: "cjsh",
    path: "assets/images/travel.png",
  },
  {
    value: "m0im",
    path: "assets/images/beauty.png",
  },
  {
    value: "m1im",
    path: "assets/images/fashion.png",
  },
  {
    value: "nsld",
    path: "assets/images/food.png",
  },
  {
    value: "p914",
    path: "assets/images/education.png",
  },
  {
    value: "txv6",
    path: "assets/images/photography.png",
  },
  {
    value: "w7s7",
    path: "assets/images/technology.png",
  },
  {
    value: "yngw",
    path: "assets/images/health.png",
  },
  {
    value: "agri",
    path: "assets/images/agriculture.png",
  },
  {
    value: "sprt",
    path: "assets/images/sports.png",
  },
  {
    value: "decr",
    path: "assets/images/homedecor.png",
  },
  {
    value: "pges",
    path: "assets/images/pages.png",
  },
];

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

function InflencerDiscovery() {
  const [activeTab, setActiveTab] = useState("category");
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("btll");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categoryListLoading, setCategoryListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [isDropdownSuggestionOpen, setIsDropdownSuggestionOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [influencerId, setInfluencerId] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [savedList, setSavedList] = useState([]);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const categoryRef = useRef(null);
  const locationRef = useRef(null);
  const savedlistRef = useRef(null);
  const windowWidth = useWindowWidth();
  const isSmallScreen = windowWidth < 400;
  const showToast = useToast();
  const Navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: "category", ref: categoryRef },
        { id: "location", ref: locationRef },
        { id: "savedlist", ref: savedlistRef },
      ];

      const tabsContainerHeight =
        document.querySelector(".tabs-container").offsetHeight;

      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.ref.current.offsetTop - tabsContainerHeight;
        const sectionHeight = section.ref.current.offsetHeight;

        if (
          window.scrollY >= sectionTop &&
          window.scrollY < sectionTop + sectionHeight
        ) {
          currentSection = section.id;
        }
      });

      if (currentSection && currentSection !== activeTab) {
        setActiveTab(currentSection); // Dynamically update the active tab
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeTab]);

  useEffect(() => {
    getCategoryList();
    getLocationList();
    savedListInfluencerDiscovery();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        suggestionList(searchQuery);
      }, 400);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchQuery]);

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { all_permissions = {} } = UserData;

  function suggestionList() {
    let params = {
      platform_id: selectedOption,
      search_text: searchQuery,
    };
    Core.suggestionList(
      suggestionListSuccess,
      suggestionListFailed,
      setLoading,
      params
    );
  }

  function suggestionListSuccess(response, response_influencer_list) {
    setSuggestions(response_influencer_list?.influencer_list);
  }

  function suggestionListFailed() {}

  function updateProgressCategoryList(status) {
    setCategoryListLoading(status);
  }

  function getCategoryList() {
    setCategoryListLoading(true);
    let params = {};
    Core.getCategoryList(
      getCategoryListSuccess,
      getCategoryListFailed,
      updateProgressCategoryList,
      params
    );
  }

  function getCategoryListSuccess(res, response) {
    setCategoryList(JSON.parse(response.response));
  }

  function getCategoryListFailed(errorList) {
    toast.error(errorList);
  }

  function updateProgressLocationList(status) {
    setLocationListLoading(status);
  }

  function getLocationList() {
    setLocationListLoading(true);
    let params = {};
    Core.getLocationList(
      getLocationListSuccess,
      getLocationListFailed,
      updateProgressLocationList,
      params
    );
  }

  function getLocationListSuccess(res, response) {
    setLocationList(JSON.parse(response.category_list));
  }
  function getLocationListFailed(errorList) {
    toast.error(errorList);
  }

  function savedListInfluencerDiscovery() {
    let params = {};
    Core.savedList(savedListSuccess, savedListFailed, () => {}, params);
  }

  function savedListSuccess(response, res) {
    setSavedList(res.collection_list);
  }
  function savedListFailed() {}

  const scrollToSection = (section) => {
    const sectionRef = {
      category: categoryRef,
      location: locationRef,
      savedlist: savedlistRef,
    }[section];

    if (sectionRef && sectionRef.current) {
      window.scrollTo({
        top:
          sectionRef.current.offsetTop -
          document.querySelector(".tabs-container").offsetHeight,
        behavior: "smooth",
      });
    }

    setActiveTab(section);
  };

  const stateLabels = locationList.filter((item) => item.type === "state");

  const options = [
    { value: "btll", label: "Instagram", iconName: "instagram" },
    { value: "nxjo", label: "Youtube", iconName: "youtube" },
  ];
  const mergedData = categoryList.map((item) => {
    const matchingIcon = categoryIcons.find(
      (icon) => icon.value === item.value
    );
    return {
      ...item,
      image: matchingIcon?.path || "", // Add the image path if it matches
    };
  });

  const avatars = [
    "https://randomuser.me/api/portraits/men/11.jpg",
    "https://randomuser.me/api/portraits/women/11.jpg",
    "https://randomuser.me/api/portraits/men/22.jpg",
    "https://randomuser.me/api/portraits/women/22.jpg",
    "https://randomuser.me/api/portraits/men/11.jpg",
  ];

  const count = avatars.length - 4;

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);

    if (!influencerFlyOut) {
      setSearchQuery("");
      setSuggestions([]);
    }
  };

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />

      <div className="main-screen">
        <Header hideHeaderLeft={true} />
        <div
          style={{ backgroundColor: "#fff" }}
          className="page-container-campaignList">
          <div className="">
            <div className="influencer-discovery-top-header">
              Browse from
              <span style={{ color: "#1977F2" }}>
                &nbsp;<u>Largest Database</u>&nbsp;
              </span>
              of Influencers
            </div>
            <div className="search-bar-influencer-discovery-container">
              <div
                className="search-bar-influencer-discovery"
                style={{
                  borderBottomRightRadius: isDropdownSuggestionOpen
                    ? "0"
                    : "8px",
                  transition: "border-radius 0.3s ease",
                }}>
                <div
                  className={`custom-select ${isDropdownOpen ? "open" : ""}`}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <div className="custom-select-trigger">
                    <span className="trigger-content">
                      <Icon
                        iconName={
                          options.find((opt) => opt.value === selectedOption)
                            .iconName
                        }
                      />
                      {!isSmallScreen &&
                        options.find((opt) => opt.value === selectedOption)
                          .label}
                    </span>
                    <span className="dropdown-arrow">
                      <Icon iconName="downArrow" />
                    </span>
                  </div>
                  <ul className="custom-options">
                    {options.map((option) => (
                      <li
                        key={option.value}
                        className="custom-option"
                        onClick={() => {
                          setSelectedOption(option.value);
                          setIsDropdownOpen(false);
                        }}>
                        <Icon iconName={option.iconName} />
                        {!isSmallScreen && option.label}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="search-input-influencer-discovery">
                  <input
                    className="search-input-suggestionList"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                      const query = e.target.value; 
                      setSearchQuery(query);

                      if (query.length > 2) {
                        setIsDropdownSuggestionOpen(true);
                        setLoading(true);
                        suggestionList({
                          platform: selectedOption,
                          query,
                        });
                      } else {
                        setSuggestions([]);
                        setIsDropdownSuggestionOpen(false);
                      }
                    }}
                    onFocus={() => setIsDropdownSuggestionOpen(true)}
                    onBlur={() =>
                      setTimeout(() => setIsDropdownSuggestionOpen(false), 200)
                    }
                    placeholder="Search Influencers by Name"
                  />
                  {isDropdownSuggestionOpen && (
                    <div className="suggestion-dropdown">
                      {loading ? (
                        <div style={{ maxHeight: "100px" }}>
                          <Loader />
                        </div>
                      ) : suggestions.length > 0 ? (
                        suggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="suggestion-option"
                            onClick={() => {
                              setSearchQuery(suggestion.influencer_data.name);
                              toggleFlyoutDropdown();
                              setInfluencerId(
                                !Utils.isEmpty(suggestion.platforms_data)
                                  ? suggestion?.influencer_data.influencer_id
                                  : null
                              );
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            onMouseDown={(e) => e.preventDefault()}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}>
                              <Image
                                source={
                                  suggestion.influencer_data.profile_picture
                                    ? Utils.generateInfluencerDpUrl(
                                        suggestion.influencer_data
                                          .influencer_id,
                                        suggestion.influencer_data
                                          .profile_picture
                                      )
                                    : require("../../resources/images/user-copy.png")
                                }
                                fallBack={require("../../resources/images/user-copy.png")}
                                className="influencer-discovery-suggestion-image"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                <span>{suggestion.influencer_data.name}</span>
                                <p>
                                  @{suggestion.platforms_data[0].identifier_id}
                                </p>
                              </div>
                            </div>
                            <div className="tiltedArrow">
                              {hoveredIndex === index ? (
                                <Icon iconName="tiltedArrowActive" />
                              ) : (
                                <Icon iconName="tiltedArrow" />
                              )}
                            </div>
                          </div>
                        ))
                      ) : searchQuery.length > 0 ? (
                        <div className="dropdown-container">
                          <div className="no-results-icon">
                            <img
                              src="assets/images/EmptyState.png"
                              alt="Search Icon"
                            />
                          </div>
                          <div className="no-results-text">
                            <h4>No Results Found</h4>
                            <p>
                              Try adjusting your search <br />
                              to find what you are looking for
                            </p>
                          </div>
                        </div>
                      ) : (
                        setIsDropdownSuggestionOpen(false)
                      )}
                    </div>
                  )}

                  <div className="search-icon-influencer-discovery">
                    <Icon iconName="searchIcon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-container">
              <div
                className={`tab ${activeTab === "category" ? "active" : ""}`}
                onClick={() => scrollToSection("category")}>
                <Icon
                  iconName={
                    activeTab === "category"
                      ? "categoryIconActive"
                      : "categoryIcon"
                  }
                />
                Category
              </div>
              <div
                className={`tab ${activeTab === "location" ? "active" : ""}`}
                onClick={() => scrollToSection("location")}>
                <Icon
                  iconName={
                    activeTab === "location"
                      ? "locationIconActive"
                      : "locationIcon"
                  }
                />
                Location
              </div>
              <div
                className={`tab ${activeTab === "savedlist" ? "active" : ""}`}
                onClick={() => scrollToSection("savedlist")}>
                <Icon
                  iconName={
                    activeTab === "savedlist"
                      ? "savedListIconActive"
                      : "savedListIcon"
                  }
                />
                Saved List
              </div>
            </div>
            <div id="category" className="content-section" ref={categoryRef}>
              <h2 className="influencer-discovery-top-section-title">
                Browse by Category
              </h2>
              <div>
                <div>
                  {categoryListLoading ? (
                    <div className="loader">
                      <Loader />
                    </div>
                  ) : (
                    <div className="influencer-discovery-cards">
                      {mergedData.map((item) => (
                        <CardComponent
                          key={item.value}
                          image={item.image}
                          heading={item.label}
                          borderRadius="12px"
                          padding="12px"
                          subheading={`${item.influencer_count} Influencers`}
                          headingTextColor="##090A14"
                          headingMargin="0 0 4px 0"
                          headingFontWeight="600"
                          headingTextAlign="left"
                          headingFontSize="14px"
                          subheadingTextColor="#5A5B62"
                          subheadingFontWeight="400"
                          subheadingFontSize="14px"
                          onClick={() =>
                            Navigate("/influencers", {
                              state: { categoryId: item.value },
                            })
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id="location" className="content-section" ref={locationRef}>
              <h2 className="section-title">Browse by Location</h2>
              <div>
                {locationListLoading ? (
                  <div className="loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="influencer-discovery-cards">
                    {stateLabels.map((item) => (
                      <CardComponent
                        key={item.id}
                        heading={item.label}
                        borderRadius="40px"
                        padding="12px"
                        headingTextColor="##090A14"
                        hoverHeadingFontWeight="600"
                        headingFontWeight="500"
                        topSectionAlignment="center"
                        headingFontSize="14px"
                        onClick={() =>
                          Navigate("/influencers", {
                            state: { locationId: item.id },
                          })
                        }
                        // subheading={item.subheading}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div id="savedlist" className="content-section" ref={savedlistRef}>
              <h2 className="section-title">Browse by Saved List</h2>
              <div className="cards-container-influencer-discovery">
                {savedList.map((item) => (
                  <CardComponent
                    key={item.collection_id}
                    heading={item.collection_name}
                    borderRadius="12px"
                    padding="12px"
                    onClick={() => {
                      Navigate(`/collection-influencer/${item.collection_id}`, {
                        state: {
                          collectionName: item.collection_name,
                        },
                      });
                    }}
                    subheading={item.description ? item.description : ""}
                    background="linear-gradient(100.28deg, #FFFFFF 4.76%, #E8F1FE 100%)"
                    extraContent={
                      <>
                        <div className="influencer-discovery-avatars-container">
                          {avatars.slice(0, 4).map((avatar, index) => (
                            <img
                              key={index}
                              src={avatar}
                              alt={`avatar-${index}`}
                              className="influencer-discovery-avatar"
                            />
                          ))}
                          {avatars.length > 4 && (
                            <div className="extra-count">+{count}</div>
                          )}
                        </div>
                        {item.platform_id === "btll" ? (
                          <Icon iconName="instagram" />
                        ) : (
                          <Icon iconName="youtube" />
                        )}
                      </>
                    }
                    headingTextColor="#024399"
                    headingBorder="1px solid #024399"
                    headingBorderRadius="20px"
                    headingMargin="0 0 10px 0"
                    headingFontWeight={500}
                    hoverBackground="#E8F1FE"
                    headingTextAlign="left"
                    headingMaxWidth="70%"
                    headingFontSize="14px"
                    headingPadding="6px 12px"
                    headingBackgroundColor="white"
                    subheadingTextColor="#090A14"
                    subheadingFontWeight="600"
                    subheadingFontSize="14px"
                    subheadingMargin="0 0 32px 0"
                  />
                ))}
              </div>
            </div>
            {
              <InfluencerDetailModal
                influencerFlyOut={influencerFlyOut}
                toggleFlyoutDropdown={toggleFlyoutDropdown}
                influencer_id={influencerId}
                org_id={brand_deep_data.brand_data.org_id}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default InflencerDiscovery;
