import React, { useEffect, useState } from "react";
import {
  CustomCheckBoxCircular,
  CustomText,
  Image,
  PrimaryCta,
} from "../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideMenu from "../../screens/sideMenu";
import Header from "../../screens/header";
import "react-best-tabs/dist/index.css";
import { useDispatch } from "react-redux";
import CampaignDetailsScreen from "./campaignDetailsScreen";
import BidListScreen from "./bidListScreen";
import AssignmentListScreen from "./assignmentListScreen";
import EditCampaignDetailsScreen from "../editCampaign/editCampaignDetailsScreen";
import { useSelector } from "react-redux";
import Core from "../../common/clientSdk";
import Toggle from "react-toggle";
import { Loader } from "../loader";
import InviteList from "./inviteList";
import ContentList from "./contentList";
import AnalyticsCampaign from "./analyticsCampaign";
import { toast } from "react-toastify";
import * as Actions from "../../redux/actions";
import CollectionInfluencerCampaignList from "./collectionInfluencerCampaign";
import { isMobile } from "react-device-detect";
function CampaignDetailsParentScreen(props) {
  const [campaignDetail, setCampaignDetail] = useState({});
  const [activeTabPage, setActiveTabPage] = useState("");
  const [activeTab, setActiveTab] = useState("proposal-page");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [published, setPublished] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [bidId, setBidId] = useState();
  const [bidValue, setBidValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [bidListSort, setBidListSort] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [influencerList, setInfluencerList] = useState(null);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    profession: [],
    counter: null,
    invite_mark: null,
  });
  const [isInviteLoading, setInviteLoading] = useState({});
  const [modalCampaignDetailsIsOpen, setModalCampaignDetailsIsOpen] =
    useState(false);
  const campaignId = useParams().id;

  const location = useLocation();
  const campaignName = location.state || "";
  const { data } = location.state || {};
  console.log("campaignDetail", campaignDetail);
  var redux_dispatch = useDispatch();

  useEffect(() => {
    // Set the initial activeTab based on is_marketplace
    if (campaignDetail?.campaign_data?.is_marketplace === 0) {
      setActiveTab("invite-page");
    } else {
      setActiveTab("proposal-page");
    }
  }, [campaignDetail?.campaign_data?.is_marketplace]);
  useEffect(() => {
    getCampaignUserDetail();
    getCollectionList();
    // getBidList();
  }, []);

  function updateProgress(status) {}

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  function getBidList() {
    var params = {
      campaign_id: campaignId,
    };
    if (searchName != null) {
      params.search = searchName;
    }
    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }

    Core.getBidList(
      getBidListSuccess,
      getBidListFailed,
      updateProgress,
      params
    );
  }

  function getBidListSuccess(response_data) {
    setBidListSort(response_data["bid_list"]);
  }

  function getBidListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCampaignUserDetail() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      (status) => setLoading(status),
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  //collection list api

  function getCollectionList() {
    const params = {
      campaign_id: campaignId,
      org_id: brand_data.org_id,
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    if (response_data.collection_list.length > 0) {
      setSelectedCollectionId(response_data.collection_list[0].collection_id);
    }
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function extendDataCampaign() {
    const params = {
      campaign_id: campaignId,
      brand_id: brand_data.brand_id,
      extend_by_days: 30,
    };
    Core.extendDataCampaign(
      extendDataCampaignSuccess,
      extendDataCampaignFailed,
      updateProgress,
      params
    );
  }

  function extendDataCampaignSuccess(response_data) {}

  function extendDataCampaignFailed(errorList) {}

  function postPublishedCampaign() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postPublishedCampaign(
      postPublishedCampaignSuccess,
      postPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail();
  }

  function postPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postUnPublishedCampaign() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postUnPublishedCampaign(
      postUnPublishedCampaignSuccess,
      postUnPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postUnPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail();
  }

  function postUnPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getInfluencerList(bidList, page) {
    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 50 * Number(page) - 50;
    }

    let filter_data = {
      location: filterData.state,
      gender: filterData.gender,
      profession: filterData.profession,
      is_invited: filterData?.invite_mark?.toString(),
    };
    var params = {
      campaign_id: campaignId,
      limit: 50,
      start: isNaN(newPage) ? 0 : newPage,
      filter_data: JSON.stringify(filter_data),
    };

    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerList(
      (...args) => getInfluencerListSuccess(bidList, ...args),
      getInfluencerListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerListSuccess(bidList, response_data) {
    if (bidList) {
      let influencerId =
        bidList && bidList?.map((item) => item.bid_data.influencer_id);
      if (response_data.invite_list) {
        const updateList = response_data.invite_list.filter(
          (item) => !influencerId?.includes(item.influencer_data?.influencer_id)
        );
        setInfluencerList(updateList);
      }
    }

    setLoading(false);
    setTotalRows(response_data.total_count);
  }

  function getInfluencerListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    getInfluencerList(bidList, page);
  };

  function getInfluencerInviteList() {
    var params = {
      campaign_id: campaignId,
    };
    Core.getInfluencerInviteList(
      getInfluencerInviteListSuccess,
      getInfluencerInviteListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerInviteListSuccess(response_data) {
    setInfluencerInviteList(response_data.invite_list);
  }

  function getInfluencerInviteListFailed(errorList) {
    toast.error(errorList);
  }

  function postInfluencerInvite(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInvite(
      postInfluencerInviteSuccess,
      postInfluencerInviteFailed,
      (status) => setInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postInfluencerInviteSuccess(response_data) {
    toast.success("Invitation sent to Influencer");
    userSelfData();
    getInfluencerList(updateProgress, 10);
    getInfluencerInviteList(updateProgress);
  }

  function postInfluencerInviteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postInfluencerInviteWithdraw(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInviteWithdraw(
      postInfluencerInviteWithdrawSuccess,
      postInfluencerInviteWithdrawFailed,
      () => {},
      params
    );
  }

  function postInfluencerInviteWithdrawSuccess(response_data) {
    toast.success("Invite withdraw");

    getInfluencerList(updateProgress);
    getInfluencerInviteList(updateProgress);
  }

  function postInfluencerInviteWithdrawFailed(errorList) {
    toast.error(errorList);
  }

  function userSelfData() {
    var params = { org_id: brand_deep_data.brand_data.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response_data) {
    //update brand user data in redux

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });
  }

  function userSelfDataFailed(errorList) {}

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleAddInfluencersClick = () => {
    setActiveTab("invite-page");
  };

  const openCampaignDetailsModal = () => {
    if (modalCampaignDetailsIsOpen == false) {
      setModalCampaignDetailsIsOpen(true);
    } else {
      setModalCampaignDetailsIsOpen(false);
    }
  };

  const closeCampaignDetailsModal = () => {
    setModalCampaignDetailsIsOpen(false);
  };

  function PublishedAndUnpublished() {
    if (campaignDetail?.campaign_data?.status !== "ACTIVE") {
      postPublishedCampaign();
    } else {
      postUnPublishedCampaign();
    }
    getCampaignUserDetail();
  }

  return (
    <div>
      <div className="dashboard-container background-color-primary">
        <SideMenu />
        <div className="main-screen" style={{ marginRight: "26px" }}>
          <Header />

          <div className="internal-page-container min-height-500">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div
                  className="campaign-text-heading"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "25px",
                  }}
                >
                  <div>
                    <CustomText
                      h5
                      text={campaignDetail?.campaign_data?.name}
                      className="typographyH7"
                    />
                  </div>

                  <div>
                    {campaignDetail?.campaign_data?.status !== "COMPLETED" && (
                      <PrimaryCta
                        fifth
                        textColor={"text_color_light"}
                        iconName={"EditIcon"}
                        fontSize={"font_size_large"}
                        className={"blue-button"}
                        text={"Edit"}
                        onClick={() => setIsPopupOpen(true)}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    activeTab === "proposal-page"
                      ? "proposal-detail-container"
                      : "campaign-detail-container"
                  }
                >
                  <div className="campaign-detail-upper-container">
                    <div className="campaign-detail-tab-header">
                      {!isMobile && (
                        <>
                          <div
                            className={
                              activeTab === "invite-page" ? "active" : ""
                            }
                            onClick={() => handleTabClick("invite-page")}
                          >
                            <div className="campaign-detail-btn-tabs">
                              <CustomText
                                p
                                // textColor={"text_color_primary"}
                                fontSize={"font_size_extra_large"}
                                text={"Suggested"}
                              />
                            </div>
                          </div>

                          <div
                            className={
                              activeTab === "collection-page" ? "active" : ""
                            }
                            onClick={() => handleTabClick("collection-page")}
                          >
                            <div className="campaign-detail-btn-tabs">
                              <CustomText
                                p
                                // textColor={"text_color_primary"}
                                fontSize={"font_size_extra_large"}
                                text={"Shortlisted"}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        className={
                          activeTab === "proposal-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("proposal-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Proposals"}
                          />
                        </div>
                      </div>

                      {!isMobile && (
                        <>
                          <div
                            className={
                              activeTab === "contracts-page" ? "active" : ""
                            }
                            onClick={() => handleTabClick("contracts-page")}
                          >
                            <div className="campaign-detail-btn-tabs">
                              <CustomText
                                p
                                // textColor={"text_color_primary"}
                                fontSize={"font_size_extra_large"}
                                text={"Contracts"}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              activeTab === "content-page" ? "active" : ""
                            }
                            onClick={() => handleTabClick("content-page")}
                          >
                            <div className="campaign-detail-btn-tabs">
                              <CustomText
                                p
                                // textColor={"text_color_primary"}
                                fontSize={"font_size_extra_large"}
                                text={"Creatives"}
                              />
                            </div>
                          </div>

                          {campaignDetail?.campaign_data?.type !==
                            "content" && (
                            <div
                              className={
                                activeTab === "analytics-page" ? "active" : ""
                              }
                              onClick={() => handleTabClick("analytics-page")}
                            >
                              <div className="campaign-detail-btn-tabs">
                                <CustomText
                                  p
                                  // textColor={"text_color_primary"}
                                  fontSize={"font_size_extra_large"}
                                  text={"Analytics"}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="m-t-12">
                      {/* {activeTab === "detail-page" && (
                        <div className="campaign-detail-edit-toggle-btn">
                          {campaignDetail?.campaign_data?.status !==
                            "COMPLETED" &&
                            campaignDetail?.campaign_data?.status !==
                              "IN_REVIEW" && (
                              <Toggle
                                className="custom-toggle"
                                checked={
                                  campaignDetail?.campaign_data?.status ===
                                  "ACTIVE"
                                    ? true
                                    : false
                                }
                                icons={false}
                                onChange={PublishedAndUnpublished}
                              />
                            )}
                        </div>
                      )} */}
                    </div>
                  </div>

                  <>
                    <InviteList
                      isActive={activeTab === "invite-page"}
                      campaignId={campaignId}
                      PublishedAndUnpublished={PublishedAndUnpublished}
                      campaignDetail={campaignDetail}
                      handleUpdateCampaign={getCampaignUserDetail}
                      selectedCollectionId={selectedCollectionId}
                    />
                  </>

                  <BidListScreen
                    isActive={activeTab === "proposal-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                    setBidListSort={setBidListSort}
                    bidListSort={bidListSort}
                    searchName={searchName}
                    setSearchName={setSearchName}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    user_data={user_data}
                    handleUpdateCampaign={getCampaignUserDetail}
                    selectedCollectionId={selectedCollectionId}
                  />

                  <AssignmentListScreen
                    isActive={activeTab === "contracts-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                    user_data={user_data}
                  />

                  <ContentList
                    isActive={activeTab === "content-page"}
                    campaignId={campaignId}
                  />

                  <AnalyticsCampaign
                    isActive={activeTab === "analytics-page"}
                    campaignId={campaignId}
                    brandId={brand_data.brand_id}
                    campaignDetail={campaignDetail}
                    orgId={brand_data.org_id}
                  />
                  <CollectionInfluencerCampaignList
                    isActive={activeTab === "collection-page"}
                    campaignId={campaignId}
                    PublishedAndUnpublished={PublishedAndUnpublished}
                    campaignDetail={campaignDetail}
                    brandId={brand_data.brand_id}
                    orgId={brand_data.org_id}
                    handleAddInfluencersClick={handleAddInfluencersClick}
                    selectedCollectionId={selectedCollectionId}
                  />
                </div>
              </>
            )}
            {
              <EditCampaignDetailsScreen
                successCampaignDetail={getCampaignUserDetail}
                campaignId={campaignId}
                isPopupOpen={isPopupOpen}
                setIsPopupOpen={setIsPopupOpen}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetailsParentScreen;
