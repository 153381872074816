import React, { useEffect, useState, useRef } from "react";
import Icon from "../../components/core_component/icon";
import { BrandCard, CustomText, Image } from "../../components";
import * as CampaignData from "../../common/data/campaign";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import InfluencerProfileContentCard from "./influencerProfileContentCard";
import ContactDetailInfluencer from "../../modal/contactDetailInfluencer";

function InfluencerProfileTab(props) {
  const [currentSelectedPlatform, setCurrentSelectedPlatform] =
    useState("btll");
  const [selectedSection, setSelectedSection] = useState("contact");
  const [portfolio, setPortfolio] = useState([]);
  const platformsRef = useRef(null);
  const contentRef = useRef(null);
  const brandRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const contactTop = contactRef.current?.getBoundingClientRect().top;
      const platformTop = platformsRef.current?.getBoundingClientRect().top;

      if (contactTop <= 0) {
        setSelectedSection("contact");
      } else if (platformTop <= 10) {
        setSelectedSection("platform");
      }
    };

    const container = contactRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  function getContactSuccess(requestedContactResponse) {
    props.setContactRequest(requestedContactResponse);
  }

  function influencerPortfolio() {
    var params = { influencer_id: "6getns1ff2e51ndkse5a2ufnf" };
    Core.influencerPortfolio(
      influencerPortfolioSuccess,
      influencerPortfolioFailed,
      () => {},
      params
    );
  }

  function influencerPortfolioSuccess(response) {
    setPortfolio(response["portfolio_list"]);
  }
  function scrollToSection(ref, select) {
    setSelectedSection(select);

    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  function influencerPortfolioFailed() {}

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };
  const getSelectedPlatformData = (platforms_data) => {
    if (!Utils.isEmpty(platforms_data)) {
      return platforms_data?.find(
        (item) => item?.platform_id === currentSelectedPlatform
      );
    }
  };

  function renderSidebarInfluencer() {
    return (
      <div className=" flex-column gap-30  ">
        <div
          onClick={() => scrollToSection(contactRef, "contact")}
          className={`flex-row gap-10 cursor-pointer align-center ${
            selectedSection === "contact" ? "selected-sidebar" : "gray-gray-300"
          }`}
        >
          <div
            style={{
              minWidth: 20,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName={"contactIcon"}
              color={selectedSection === "contact" && "#1878f3"}
            />
          </div>

          <CustomText p text={"Contact"} className="button1 " />
        </div>
        <div
          onClick={() => scrollToSection(platformsRef, "platform")}
          className={`flex-row gap-10 cursor-pointer ${
            selectedSection === "platform"
              ? "selected-sidebar"
              : "gray-gray-300"
          }`}
        >
          <div
            style={{
              minWidth: 20,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName={"platformIcon"}
              color={selectedSection === "platform" && "#1878f3"}
            />
          </div>
          <CustomText p text={"Platform"} className="button1 " />
        </div>
        {/* <div
          onClick={() => scrollToSection(contentRef, "content")}
          className={`flex-row gap-10 cursor-pointer ${
            selectedSection === "content" ? "selected-sidebar" : ""
          }`}
        >
          <Icon
            iconName={"contentIcon"}
            color={selectedSection === "content" && "#1878f3"}
          />
          <CustomText p text={"Content"} className="button1" />
        </div> */}
        {/* <div
          onClick={() => scrollToSection(brandRef, "brand")}
          className={`flex-row gap-10 cursor-pointer ${
            selectedSection === "brand" ? "selected-sidebar" : ""
          }`}
        >
          <Icon
            iconName={"brandIcon"}
            color={selectedSection === "brand" && "#1878f3"}
          />
          <CustomText p text={"Brand"} className="button1" />
        </div> */}
      </div>
    );
  }

  const instagramEngagementData = props?.platformsData.filter(
    (item) => item?.platform_id == "btll"
  );

  const youtubeEngagementData = props?.platformsData.filter(
    (item) => item?.platform_id == "nxjo"
  );
  function renderProfileInstagramTab() {
    return (
      <>
        {instagramEngagementData.map((item) => {
          return (
            <div className="profile-engagement-view-container">
              <div className="instagram-data-handle">
                <Icon iconName="instagramIcon" />
                <CustomText
                  p
                  text={`@${item?.identifier_id}`}
                  className="button2 instagram-data-handle-text"
                />
              </div>
              <div className="engagement-view-inner ">
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Followers"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.followers)
                        ? "-"
                        : Utils.changeNumberFormate(item.followers)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Total Contents"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.content_count)
                        ? "-"
                        : Utils.changeNumberFormate(item.content_count)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Views"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_views)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_views)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Likes"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_likes)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_likes)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Comments"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_comments)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_comments)
                    }
                    className="button2"
                  />
                </div>

                <div className="instagram-data-view">
                  <CustomText p text={"E.R."} className="typography14_left" />
                  <CustomText
                    p
                    text={
                      !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                        ? item.engagement_rate.toFixed(2) + " %"
                        : "-"
                    }
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Fake Followers"}
                    className="typography14_left"
                  />
                  {item.effective_followers ? (
                    <CustomText
                      p
                      text={(100 - item?.effective_followers).toFixed(0) + "%"}
                      className={
                        props.checkOrgSub?.feature?.max_brand_count > 1
                          ? "button2"
                          : "blur-text"
                      }
                    />
                  ) : (
                    <CustomText
                      p
                      text={"-"}
                      className={
                        props.checkOrgSub?.feature?.max_brand_count > 1
                          ? "button2"
                          : "blur-text"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function renderProfileYoutubeTab() {
    return (
      <>
        {youtubeEngagementData.map((item) => {
          return (
            <div className="profile-engagement-view-container">
              <div className="instagram-data-handle">
                <Icon iconName="youtubeNewIcon" />
                <CustomText
                  p
                  text={
                    !item?.full_name
                      ? item.identifier_id
                      : `@${item?.full_name}`
                  }
                  className="button2 instagram-data-handle-text"
                />
              </div>
              <div className="engagement-view-inner ">
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Subscribers"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.followers)
                        ? "-"
                        : Utils.changeNumberFormate(item.followers)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Total Contents"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.content_count)
                        ? "-"
                        : Utils.changeNumberFormate(item.content_count)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Total Views"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.content_views)
                        ? "-"
                        : Utils.changeNumberFormate(item.content_views)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Views"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_views)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_views)
                    }
                    className="button2"
                  />
                </div>

                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Likes"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_likes)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_likes)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText
                    p
                    text={"Avg. Comments"}
                    className="typography14_left"
                  />
                  <CustomText
                    p
                    text={
                      Utils.isNullOrUndefinedOrZero(item.avg_comments)
                        ? "-"
                        : Utils.changeNumberFormate(item.avg_comments)
                    }
                    className="button2"
                  />
                </div>
                <div className="instagram-data-view">
                  <CustomText p text={"E.R."} className="typography14_left" />
                  <CustomText
                    p
                    text={
                      !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                        ? item.engagement_rate + " %"
                        : "-"
                    }
                    className="button2"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function renderInfluencerContact() {
    return (
      <>
        <ContactDetailInfluencer
          influencer_id={props?.influencerData?.influencer_id}
          influencerData={props.influencerData}
          getContactSuccess={getContactSuccess}
          isLoadingContact={props.isLoadingContact}
        />
      </>
    );
  }
  return (
    <div className="profile-tab-main-container">
      <div className="influencer-sidebar-profile-main-container">
        {renderSidebarInfluencer()}
      </div>

      <div className="flex-1 m-t-20 p-l-20 profile-overflow-container">
        <div
          ref={contactRef}
          className="profile-engagement-view-container m-t-20"
        >
          <ContactDetailInfluencer
            influencer_id={props?.influencerData?.influencer_id}
            influencerData={props?.influencerData}
            getContactSuccess={getContactSuccess}
            isLoadingContact={props.isLoadingContact}
          />
        </div>
        <div ref={platformsRef}>
          {renderProfileInstagramTab()}
          {renderProfileYoutubeTab()}
        </div>
      </div>
    </div>
  );
}

export default InfluencerProfileTab;
