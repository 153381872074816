import React, { useState, useEffect } from "react";
import Styles from "./style.js";
import PropTypes from "prop-types";
import Icon from "./icon.js";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
//import Theme from '../../resources/themes/light.js'

import { isMobile } from "react-device-detect";
export const CustomText = function (props) {
  // Function for text styling
  function textStyle(props) {
    var style;
    if (props.textColor) {
      style = Styles[props.textColor];
    }
    if (props.fontSize) {
      style = { ...style, ...Styles[props.fontSize] };
    }
    if (props.fontWeight) {
      style = { ...style, ...Styles[props.fontWeight] };
    }
    if (props.textAlign) {
      style = { ...style, ...Styles[props.textAlign] };
    }
    if (props.width) {
      style = { ...style, ...Styles[props.width] };
    }
    if (props.height) {
      style = { ...style, ...Styles[props.height] };
    }
    if (props.lineHeight) {
      style = { ...style, ...Styles[props.lineHeight] };
    }
    if (props.noOfLine) {
      style = { ...style, ...Styles[props.noOfLine] };
    }
    if (props.ellipsis) {
      style = { ...style, ...Styles["ellipsisText"] };
    }
    return style;
  }

  // Define the class name based on props
  let className = props.className ? props.className : "";

  if (props.h1) {
    return <h1 style={textStyle(props)}>{props.text}</h1>;
  } else if (props.h2) {
    return <h2 style={textStyle(props)}>{props.text}</h2>;
  } else if (props.h3) {
    return <h3 style={textStyle(props)}>{props.text}</h3>;
  } else if (props.h4) {
    return <h4 style={textStyle(props)}>{props.text}</h4>;
  } else if (props.h5) {
    return (
      <h5 className={className} style={textStyle(props)}>
        {props.text}
      </h5>
    );
  }
  if (props.h6) {
    return <h6 style={textStyle(props)}>{props.text}</h6>;
  } else if (props.label) {
    return <label style={textStyle(props)}>{props.text}</label>;
  } else if (props.p) {
    return (
      <p className={className} style={textStyle(props)}>
        {props.text}
      </p>
    );
  } else if (props.del) {
    return (
      <del className={className} style={textStyle(props)}>
        {props.text}
      </del>
    );
  }
};

export const Sidebar = ({
  isOpen,
  onClose,
  Header,
  Footer,
  children,
  headerAlignment = "left",
  headerPaddingLeft,
  headerPaddingRight,
  sidebarWidth,
  overflow,
  headerClassName,
}) => {
  const [currentWidth, setCurrentWidth] = useState(sidebarWidth);

  const updateSidebarWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768 && screenWidth > 480) {
      setCurrentWidth("50%"); // Small screen width
    } else if (screenWidth <= 480) {
      setCurrentWidth("98%"); // Small screen width
    } else {
      setCurrentWidth(sidebarWidth); // Default width
    }
  };

  useEffect(() => {
    updateSidebarWidth(); // Set initial width
    window.addEventListener("resize", updateSidebarWidth); // Update on resize

    return () => {
      window.removeEventListener("resize", updateSidebarWidth); // Cleanup
    };
  }, [sidebarWidth]);

  const alignmentStyles = {
    left: { justifyContent: "flex-start" },
    center: { justifyContent: "center" },
    right: { justifyContent: "flex-end" },
  };

  return (
    <div className={`sidebar-container-component${isOpen ? " open" : ""}`}>
      <div className="sidebar-overlay-component" onClick={onClose}></div>
      <div
        className="sidebar-content-component"
        style={{
          width: currentWidth, // Dynamically calculated width
          overflow: overflow ? overflow : "auto",
        }}
      >
        {Header && (
          <div
            className={headerClassName ? headerClassName : "sidebar-header"}
            style={{
              ...alignmentStyles[headerAlignment],
              paddingLeft: headerPaddingLeft,
              paddingRight: headerPaddingRight,
            }}
          >
            <Header />
            <div
              className="close-btn"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            >
              <Icon iconName="crossCloseIcon" height={14} width={14} />
            </div>
          </div>
        )}
        <div className="sidebar-body">{children}</div>
        {Footer && <div className="sidebar-footer">{<Footer />}</div>}
      </div>
    </div>
  );
};

export const CardComponent = ({
  image,
  heading,
  subheading,
  extraContent,
  onClick,
  borderRadius,
  padding,
  background,
  hoverBackground,
  headingTextColor,
  headingBorder,
  headingPadding,
  headingMargin,
  headingBackgroundColor,
  headingBorderRadius,
  headingTextAlign = "left",
  headingMaxWidth,
  headingFontWeight,
  hoverHeadingFontWeight,
  headingFontSize,
  subheadingTextColor,
  subheadingFontSize,
  subheadingBorder,
  subheadingMaxWidth,
  subheadingPadding,
  subheadingBackgroundColor,
  subheadingFontWeight,
  hoverSubheadingFontWeight,
  subheadingMargin,
  topSectionAlignment = "left",
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const renderImage = () => {
    if (image) {
      return (
        <div className="card-image" style={{ borderRadius }}>
          <img src={image} alt={heading} style={{ borderRadius }} />
        </div>
      );
    }
    return null;
  };

  const renderHeading = () => {
    if (heading) {
      return (
        <h3
          style={{
            color: headingTextColor,
            border: headingBorder,
            padding: headingPadding,
            margin: headingMargin,
            backgroundColor: headingBackgroundColor,
            borderRadius: headingBorderRadius,
            maxWidth: headingMaxWidth, // Respect maxWidth
            textAlign: headingTextAlign,
            fontWeight: isHovered
              ? hoverHeadingFontWeight || headingFontWeight
              : headingFontWeight,
            fontSize: headingFontSize,
            whiteSpace: "nowrap", // Ensure text doesn't wrap
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis", // Add ellipsis for overflow
            display: "inline-block", // Respect content size and maxWidth
          }}
        >
          {heading}
        </h3>
      );
    }
    return null;
  };

  const renderSubheading = () => {
    if (subheading) {
      return (
        <p
          style={{
            color: subheadingTextColor,
            border: subheadingBorder,
            padding: subheadingPadding,
            backgroundColor: subheadingBackgroundColor,
            fontWeight: isHovered
              ? hoverSubheadingFontWeight || subheadingFontWeight
              : subheadingFontWeight,
            fontSize: subheadingFontSize,
            margin: subheadingMargin,
            maxWidth: subheadingMaxWidth,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
          }}
        >
          {subheading}
        </p>
      );
    }
    return null;
  };

  const renderExtraContent = () => {
    if (extraContent) {
      return (
        <div
          className={`card-extra-content ${
            subheading ? "with-subheading" : ""
          }`}
        >
          {extraContent}
        </div>
      );
    }
    return null;
  };

  // If no props are passed, render nothing
  if (!image && !heading && !subheading && !extraContent) {
    return null;
  }

  const singleProp = !image + !heading + !subheading === 2;

  return (
    <div
      className={`card-component ${singleProp ? "center-content" : ""}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        borderRadius,
        background: isHovered ? hoverBackground || background : background,
        padding,
      }}
    >
      <div
        className="card-component-top-section"
        style={{
          display: "flex",
          justifyContent:
            topSectionAlignment === "center"
              ? "center"
              : topSectionAlignment === "right"
              ? "flex-end"
              : "flex-start", // Dynamically align content
        }}
      >
        <div className="card-component-top-section-image">{renderImage()}</div>
        <div className="card-component-top-section-content">
          <div className="card-component-top-section-heading">
            {renderHeading()}
          </div>
          <div className="card-component-top-section-subheading">
            {renderSubheading()}
          </div>
        </div>
      </div>
      <div className="card-component-bottom-section">
        {renderExtraContent()}
      </div>
    </div>
  );
};

export const H1 = function (props) {
  return (
    <h1
      style={
        props.type === "primary"
          ? Styles.h1Primary
          : props.type === "disabled"
          ? Styles.h1Disabled
          : props.type === "green"
          ? Styles.h1Green
          : Styles.h1Default
      }
    >
      {props.text}
    </h1>
  );
};

export const H2 = function (props) {
  return (
    <h2
      style={
        props.type === "primary"
          ? Styles.h2Primary
          : props.type === "disabled"
          ? Styles.h2Disabled
          : Styles.h2Default
      }
    >
      {props.text}
    </h2>
  );
};

export const H3 = function (props) {
  return (
    <h3
      style={
        props.type === "primary"
          ? Styles.h3Primary
          : props.type === "disabled"
          ? Styles.h3Disabled
          : Styles.h3Default
      }
    >
      {props.text}
    </h3>
  );
};

export const Medium = function (props) {
  return (
    <p
      style={
        props.type === "primary"
          ? Styles.mediumPrimary
          : props.type === "disabled"
          ? Styles.mediumDisabled
          : props.type === "light"
          ? Styles.mediumLight
          : Styles.mediumDefault
      }
    >
      {props.text}
    </p>
  );
};

export const Image = function (props) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageError = () => {
    setImageLoaded(true);
  };
  return (
    <img
      className={props.className}
      onClick={props.onClick}
      style={props.style}
      alt={props.alt}
      src={imageLoaded ? props.fallBack : props.source}
      width={props.width}
      height={props.height}
      onError={handleImageError}
    />
  );
};

// export const Video = function (props) {
//   return (
//     <div>
//       <Video
//         source={props.source}
//         type="video/mp4"
//         className={props.className}
//         controls
//         width={props.width}
//         height={props.height}
//       />
//     </div>
//   );
// };

export const Video = function (props) {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default right-click behavior
  };
  return (
    <div>
      <video
        controlsList="nodownload"
        className={props.className}
        onClick={props.onClick}
        style={props.style}
        controls
        muted={props.muted}
        autoPlay={props.autoPlay}
        loop={props.loop}
        preload={props.preload}
        width={props.width}
        height={props.height}
        onContextMenu={handleContextMenu}
      >
        <source src={props.source} type={props.type} />
      </video>
    </div>
  );
};

export const CustomTextInput = function (props) {
  const [passwordType, setPasswordType] = useState(true);

  const togglePassword = () => {
    setPasswordType(!passwordType);
  };

  return (
    <div
      className={`customTextBoxMainContainer ${props.newWidth || ""}`}
      style={{
        width: props.width || "100%",
        borderRadius: props.borderRadius || "6px",
        position: "relative",
        display: "flex",
        flexWrap: "wrap", // Ensures content wraps properly
        alignItems: "center",
        padding: "5px",
        border: props.disabled ? "none" : "1px solid #ccc",
        backgroundColor: props.disabled ? "#f0f0f0" : "white",
      }}
    >
      {/* Render Chips (Wrap to Next Line if Needed) */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          width: "100%",
        }}
      >
        {Array.isArray(props.chips) &&
          props.chips.map((chip, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                fontSize: "14px",
                borderRadius: "6px",
                padding: "5px 10px",
                maxWidth: "100%",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => props.onRemoveChip(chip)} // Remove chip when clicked
            >
              <span style={{ marginRight: "5px", overflow: "hidden", textOverflow: "ellipsis" }}>
                {chip}
              </span>
              <span
               
              >
                <Icon iconName="crossCloseIconSmall"  />
              </span>
            </div>
          ))}
      </div>
      <input
        {...props}
        className={props.className}
        style={{
          width: "100%",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          color: props.disabled ? "#a0a0a0" : "black",
          cursor: props.disabled ? "not-allowed" : "text",
        }}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        onCut={props.onCut}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onKeyDown={props.onKeyDown}
        value={props.value}
        type={passwordType ? props.type : "text"}
      />

      {/* Password Toggle Button */}
      {props.type === "password" && (
        <button className="toggle-password-btn" onClick={togglePassword}>
          {passwordType ? (
            <Icon iconName={"ClosedEye"} width={17} height={14.806} />
          ) : (
            <Icon iconName={"OpenEye"} width={17} height={14.806} />
          )}
        </button>
      )}

      {/* Right-side Button */}
      {props.hideVerifyBtn === 0 && props.rightBtn && (
        <div className={props.verifyClass}>
          <LinkCta
            text={props.text}
            onClick={props.onClickVerify}
            textColor={"text_color_register"}
          />
        </div>
      )}
    </div>
  );
};


export const CustomTextInput480Width = function (props) {
  const [passwordType, setPasswordType] = useState(true);
  const togglePassword = () => {
    if (passwordType === true) {
      setPasswordType(false);
      return;
    }
    setPasswordType(true);
  };
  return (
    <div
      className={
        props.newWidth ? props.newWidth : `customTextBoxMainContainer480Width`
      }
    >
      <input
        {...props}
        className={props.className}
        style={
          props.invert
            ? Styles.invert_input_field
            : Styles.custom_textbox_main_wrapper
        }
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        onCut={props.onCut}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        value={props.value}
        color={props.color}
        type={passwordType ? props.type : "text"}
      />

      {props.type === "password" && (
        <button
          style={{
            position: "absolute",
            zIndex: 1,
            top: 1,
            right: 73,
            bottom: 17,
            backgroundColor: "transparent",
            borderColor: "transparent",
            cursor: "pointer",
          }}
          onClick={togglePassword}
        >
          {passwordType === true ? (
            <Icon iconName={"ClosedEye"} width={17} height={14.806} />
          ) : (
            <Icon iconName={"OpenEye"} width={17} height={14.806} />
          )}
        </button>
      )}
      {props.hideVerifyBtn == 0 && (
        <>
          {props.rightBtn && (
            <div className={props.verifyClass}>
              <LinkCta
                text={props.text}
                onClick={props.onClickVerify}
                textColor={"text_color_register"}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export const CustomTextInputNew = function (props) {
  const [passwordType, setPasswordType] = useState(true);
  const togglePassword = () => {
    if (passwordType === true) {
      setPasswordType(false);
      return;
    }
    setPasswordType(true);
  };
  return (
    <div
      className={
        props.newWidth ? props.newWidth : `customTextBoxMainContainerNew`
      }
    >
      <input
        {...props}
        className={props.className}
        style={
          props.invert
            ? Styles.invert_input_field
            : Styles.custom_textbox_main_wrapper
        }
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        onCut={props.onCut}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        value={props.value}
        color={props.color}
        type={passwordType ? props.type : "text"}
      />

      {props.type === "password" && (
        <button
          style={{
            position: "absolute",
            zIndex: 1,
            top: 14,
            right: 10,
            // bottom: 15,
            backgroundColor: "transparent",
            borderColor: "transparent",
            cursor: "pointer",
          }}
          onClick={togglePassword}
        >
          {passwordType === true ? (
            <Icon iconName={"ClosedEye"} width={17} height={14.806} />
          ) : (
            <Icon iconName={"OpenEye"} width={17} height={14.806} />
          )}
        </button>
      )}

      {props.hideVerifyBtn == 0 && (
        <>
          {props.rightBtn && (
            <div className={props.verifyClass}>
              <LinkCta
                text={props.text}
                onClick={props.onClickVerify}
                textColor={"text_color_register"}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const CustomTextArea = function (props) {
  return (
    <div
      className={props.className}
      style={
        props.invert
          ? Styles.invert_custom_text_area
          : Styles.customtextarea_box_main_container
      }
    >
      <textarea
        className={props.className}
        style={
          props.invert
            ? Styles.custom_textarea_box_main_wrapper
            : Styles.custom_textarea_box_main_wrapper_box
        }
        rows={props.rows}
        cols={props.cols}
        required={props.required}
        wrap={props.wrap}
        disabled={props.disabled}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        name={props.name}
        resize={props.resize}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
      />
    </div>
  );
};

export const PrimaryCta = function (props) {
  var style = Styles.primaryCtaContainer;
  var textColor = "text_color_light";

  if (props.fullWidth) {
    style = Styles.fullWidthPrimaryCtaContainer;
  }
  if (props.no_width) {
    style = Styles.no_widthCtaContainer;
  }
  if (props.fullWidth100) {
    style = Styles.fullWidthPrimaryCtaContainer100;
  }
  if (props.disabled) {
    style = { ...style, ...Styles.primaryCtaDisabledContainer };
  }
  if (props.secondary) {
    style = { ...style, ...Styles.primaryCtaSecondaryContainer };
  }
  if (props.invert) {
    textColor = props.textColor ? props.textColor : "white";
    style = { ...style, ...Styles.primaryCtaInvertContainer };
  }
  if (props.third) {
    style = { ...style, ...Styles.primaryCtaThirdContainer };
  }
  if (props.borderCta) {
    style = { ...style, ...Styles.primaryCtaBorderContainer };
  }
  if (props.fourth) {
    textColor = "text_color_black";
    style = { ...style, ...Styles.primaryCtaFourthContainer };
  }
  if (props.fifth) {
    textColor = "text_color_light";
    style = { ...style, ...Styles.primaryCtaFifthContainer };
  }
  if (props.sixth) {
    textColor = "text_color_light";
    style = { ...style, ...Styles.primaryCtaSixthContainer };
  }
  if (props.white) {
    style = { ...style, ...Styles.primaryCtaWhiteContainer };
  }

  return (
    <button
      className={`primary-cta ${props.className}`}
      style={style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {!props.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {props.iconPosition === "left" && props.iconName && (
                <div style={{ marginRight: "6px" }}>
                  <Icon iconName={props.iconName} height={12} width={12} />
                </div>
              )}
              {
                <CustomText
                  p
                  className="button-text"
                  text={props.text}
                  fontSize={props.fontSize || "inherit"}
                  textColor={props.textColor ? props.textColor : textColor}
                  // fontWeight={"Poppins_regular"}
                />
              }

              {props.iconPosition === "right" && props.iconName && (
                <div style={{ marginLeft: "6px" }}>
                  <Icon iconName={props.iconName} height={12} width={12} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            props.invert ? "loader-back-color" : "loader-back-color-white"
          }
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 2,
            fontSize: 15,
          }}
        >
          <i className="fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
    </button>
  );
};

export const Popup = ({
  isOpen,
  onClose,
  headerText,
  headerAlign,
  children,
  footerButton1Text,
  footerButton1Action,
  footerButton2Text,
  footerButton2Action,
  isLoading = false,
  showFooter = true,
  width,
  height,
  maxHeight,
  useBottomSheet = true,
  bottomSheetMaxHeight = 0.9,
}) => {
  const isMobile = window.innerWidth <= 480; // Detect mobile devices

  if (!isOpen) return null;

  // Render BottomSheet for Mobile if enabled
  if (useBottomSheet && isMobile) {
    return (
      <BottomSheet
        className="custom-bottom-sheet"
        open={isOpen}
        onDismiss={onClose}
        snapPoints={({ maxHeight }) => [
          bottomSheetMaxHeight * maxHeight,
          bottomSheetMaxHeight * maxHeight,
        ]} // Use dynamic max height
        style={{ zIndex: 1050, position: "absolute", overflow: "visible" }}
      >
        <div style={{ width: "100%", maxWidth: "480px" }}>
          {/* Header */}
          <div className="bottomsheet-header">{headerText}</div>

          {/* Content with scroll */}
          <div className="bottomsheet-content">{children}</div>

          {/* Footer */}
          {showFooter && (
            <div className="bottomsheet-footer">
              {footerButton1Text && (
                <button
                  className="bottomsheet-footer-button"
                  onClick={footerButton1Action}
                >
                  {footerButton1Text}
                </button>
              )}
              {footerButton2Text && (
                <button
                  className="bottomsheet-footer-button primary"
                  onClick={footerButton2Action}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="spinner" /> : footerButton2Text}
                </button>
              )}
            </div>
          )}
        </div>
      </BottomSheet>
    );
  }

  // Render regular popup for non-mobile devices or when BottomSheet is disabled
  return (
    <div className="popup-overlay">
      <div className="popup-container" style={{ width, height, maxHeight }}>
        {/* Header */}
        <div className="popup-header" style={{ textAlign: headerAlign }}>
          {headerText}
          <button
            onClick={onClose}
            style={{
              cursor: "pointer",
              background: "none",
              border: "none",
              marginTop: 5,
            }}
            aria-label="Close"
          >
            <Icon iconName="crossCloseIcon" height={14} width={14} />
          </button>
        </div>

        {/* Content */}
        <div className="popup-content">{children}</div>

        {/* Footer */}
        {showFooter && (
          <div className="popup-footer">
            {footerButton1Text && (
              <button className="footer-button" onClick={footerButton1Action}>
                {footerButton1Text}
              </button>
            )}
            {footerButton2Text && (
              <button
                className="footer-button primary"
                onClick={footerButton2Action}
                disabled={isLoading}
              >
                {isLoading ? <div className="spinner" /> : footerButton2Text}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  headerAlign: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
  footerButton1Text: PropTypes.string,
  footerButton1Action: PropTypes.func,
  footerButton2Text: PropTypes.string,
  footerButton2Action: PropTypes.func,
  isLoading: PropTypes.bool,
  showFooter: PropTypes.bool,
  width: PropTypes.string,
  useBottomSheet: PropTypes.bool,
};

export const LinkCta = function (props) {
  //const[is_active, set_is_active] = useState(props.isActive)

  return (
    <button
      style={Styles.linkCtaContainer}
      className={props.className}
      onClick={props.onClick}
    >
      <div>
        <CustomText
          h2
          fontSize={"font_size_small"}
          text={props.text}
          textColor={props.textColor}
          fontWeight={"Poppins_regular"}
        />
      </div>
    </button>
  );
};

export const CustomButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    borderRadius: props.borderRadius || 9,
    maxWidth: 369,
    width: props.width || "100%",
    textAlign: "center",
    paddingRight: props.padding ? props.padding.right : 48,
    paddingLeft: props.padding ? props.padding.left : 48,
    paddingTop: props.padding ? props.padding.top : 15,
    paddingBottom: props.padding ? props.padding.bottom : 15,
    outline: "none",
    border: props.border || 0,
    borderColor: props.borderColor || "none",
    userSelect: "none",
    cursor: "pointer",
    background:
      props.background ||
      "var(--gradient, linear-gradient(90deg, #A271FE 0%, #F75774 100%))",
    boxShadow: props.boxShadow || "0px 4px 61px 0px rgba(77, 71, 195, 0.40)",
    height: props.height || "auto",
    ...(isHovered && {
      background: props.hoverColor || "none",
    }),
  };

  return (
    <button
      style={buttonStyle}
      onClick={props.onClick}
      disabled={props.disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!props.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textWrap: "nowrap",
            }}
          >
            {props.iconName && (
              <div style={{ marginTop: "4px", marginRight: "6px" }}>
                <Icon iconName={props.iconName} height={12} width={12} />
              </div>
            )}

            <CustomText
              h3
              text={props.text}
              fontSize={props.fontSize}
              // fontSize={"font_size_large"}
              textColor={props.textColor}
              fontWeight={"Poppins_regular"}
            />
            {props.iconNameRight && (
              <div style={{ marginTop: "4px", marginLeft: "6px" }}>
                <Icon iconName={props.iconNameRight} height={12} width={12} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 2,
            paddingBottom: 2,
            color: "white",
            fontSize: 15,
          }}
        >
          <i className="fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
    </button>
  );
};

export const FilterBtn = function (props) {
  // const [isClicked, setIsClicked] = useState(false);

  // const handleClick = () => {
  //   setIsClicked(true);
  // };

  return (
    <div
      // style={{ backgroundColor: isClicked ? "red" : "blue", color:  "white" }}
      className="filterBtn"
      // onClick={handleClick}

      width={props.width}
      height={props.height}
      backgroundColor={props.backgroundColor}
    >
      <div>
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={props.text}
          textColor={props.textColor}
          // fontWeight={"rubik_regular"}
        />
      </div>
    </div>
  );
};

// export const BackButton = function (props) {
//   return (
//     <button
//       className="filterBtn"
//       onClick={props.onClick}
//       width={props.width}
//       height={props.height}
//       backgroundColor={props.backgroundColor}
//     >
//       <div>
//         <CustomText
//           p
//           fontSize={"font_size_medium"}
//           text={props.text}
//           textColor={props.textColor}
//         />
//       </div>
//     </button>
//   );
// };

export const CampaignList = function (props) {
  return (
    <div className="Campaign-list" key={props.key} onClick={props.onClick}>
      <div className="Campaign-list-data-profile">
        <div>
          <Image source={props.source} className="campaign-list-profile" />
        </div>
        <div className="campaign-list-data m-r-16">
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={props.title}
            textColor={"text_color_primary"}
            noOfLine={"twoLines"}
          />
        </div>
      </div>
      {/* <div className="Campaign-list-data">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Type"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.type}
          textColor={"text_color_primary"}
        />
      </div> */}
      {!isMobile && (
        <>
          <div className="Campaign-list-data">
            <CustomText
              p
              fontSize={"font_size_medium"}
              text={"Views"}
              textColor={"text_light_weight_color"}
            />
            <CustomText
              p
              fontSize={"font_size_large"}
              text={props.View}
              textColor={"text_color_primary"}
            />
          </div>

          <div className="Campaign-list-data">
            <CustomText
              p
              fontSize={"font_size_medium"}
              text={"Contracts"}
              textColor={"text_light_weight_color"}
            />
            <CustomText
              p
              fontSize={"font_size_large"}
              text={props.Contract}
              textColor={"text_color_primary"}
            />
          </div>
          <div className="Campaign-list-data">
            <CustomText
              p
              fontSize={"font_size_medium"}
              text={"Status"}
              textColor={"text_light_weight_color"}
            />
            <CustomText
              p
              fontSize={"font_size_large"}
              text={props.Status}
              // textColor={"text_color_primary"}
            />
          </div>
        </>
      )}
      <div className="Campaign-list-data">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Proposals"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.Bids}
          textColor={"text_color_primary"}
        />
      </div>
      {!isMobile && (
        <div className="Campaign-list-data">
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={"Expire on"}
            textColor={"text_light_weight_color"}
          />
          <CustomText
            p
            fontSize={"font_size_large"}
            text={props.Posted}
            textColor={"text_color_primary"}
          />
        </div>
      )}
    </div>
  );
};

export const AssignmentList = function (props) {
  return (
    <div
      className={`Campaign-list ${props.className}`}
      key={props.key}
      onClick={props.onClick}
    >
      <div className="Campaign-list-data-profile">
        <div>
          <Image source={props.source} className="campaign-list-profile" />
        </div>
        <div className="campaign-list-data m-r-16">
          <CustomText
            p
            fontSize={"font_size_medium"}
            text={props.influencerName}
            textColor={"text_color_primary"}
            noOfLine={"twoLines"}
          />
        </div>
      </div>
      {!isMobile && (
        <>
          <div className="Campaign-list-data">
            <CustomText
              p
              fontSize={"font_size_medium"}
              text={"Reward"}
              textColor={"text_light_weight_color"}
            />
            <CustomText
              p
              fontSize={"font_size_large"}
              text={props.rewardText}
              textColor={"text_color_primary"}
            />
          </div>
          <div style={{ minWidth: "350px" }} className="Campaign-list-data">
            <CustomText
              p
              fontSize={"font_size_medium"}
              text={"Deliverables"}
              textColor={"text_light_weight_color"}
            />
            <CustomText
              p
              fontSize={"font_size_large"}
              text={props.deliverablesText}
              textColor={"text_color_primary"}
            />
          </div>
        </>
      )}

      <div style={{ minWidth: 180 }} className="Campaign-list-data">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Status"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.status}
          textColor={"text_color_primary"}
        />
      </div>
    </div>
  );
};

export const TransactionsList = function (props) {
  return (
    <div className="transaction-list-main-container">
      <div className="">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Type"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.type}
          textColor={"text_color_primary"}
        />
      </div>
      <div className="">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Amount"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.amount}
          textColor={"text_color_primary"}
        />
      </div>
      <div className="">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Order ID"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.orderId}
          textColor={"text_color_primary"}
        />
      </div>
      {/* <div className="">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Status"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.status}
          // textColor={props.statusColor}
        />
      </div> */}
      <div className="">
        <CustomText
          p
          fontSize={"font_size_medium"}
          text={"Time"}
          textColor={"text_light_weight_color"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.time}
          textColor={"text_color_primary"}
        />
      </div>
    </div>
  );
};

export const UnderLinedLinkCta = function (props) {
  return (
    <button style={Styles.underlinedLinkCtaContainer} onClick={props.onClick}>
      <div>
        <CustomText
          h2
          fontSize={props.fontSize}
          text={props.text}
          textColor={props.textColor}
          fontWeight={"Poppins_regular"}
        />
      </div>
    </button>
  );
};

export const CustomCheckBox = function (props) {
  return (
    <input
      style={Styles.CustomCheckBox}
      type={props.type}
      id={props.id}
      name={props.name}
      value={props.value}
      checked={props.checked}
      indeterminate={props.indeterminate}
      onChange={props.onChange}
      disabled={props.disabled}
      isInvalid={props.inValid}
      appearance={props.appearance}
    />
  );
};

export const CustomDropdown = function (props) {
  return (
    <select style={Styles.customDropdown} name={props.name} id={props.brand}>
      {props.children}
    </select>
  );
};

export const BorderedDropdown = function (props) {
  return (
    <select
      onBlur={props.onBlur}
      onChange={props.onChange}
      onSelect={props.onSelect}
      placeholder={props.placeholder}
      style={
        props.invert ? Styles.invertBorderDropDown : Styles.borderedDropdown
      }
      name={props.name}
      id={props.id}
    >
      {props.children}
    </select>
  );
};

export const CustomDropdownOptions = function (props) {
  return (
    <option style={Styles.dropDownOption} value={props.value}>
      {props.title}
    </option>
  );
};

export const CustomBox = function (props) {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 1px 2px #00000029",
        borderRadius: props.borderRadious,
        opacity: 1,
        padding: props.padding,
        display: props.display,
        flexDirection: props.flexDirection,
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        paddingTop: props.paddingTop,
        paddingRight: props.paddingRight,
        paddingBottom: props.paddingBottom,
        paddingLeft: props.paddingLeft,
      }}
    >
      {props.children}
    </div>
  );
};

export const InviteTeammate = function (props) {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
        {props.content}
      </div>
    </div>
  );
};

export const SearchBar = function (props) {
  return (
    <div>
      <input type="text" style={props.style} maxLength={props.maxLength} />
    </div>
  );
};

export const ViewCards = function (props) {
  return (
    <>
      <div style={{ flexDirection: "column" }}>
        <div>
          <CustomText
            p
            text={props.title}
            textAlign={"text_align_left"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
          />
        </div>
        <div
          style={props.view_cards ? Styles.view_cards : Styles.view_cards_main}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export const InfluencerDataCard = function (props) {
  return (
    <div className="influencer-data-card">
      <div>
        <div>
          <CustomText />
        </div>
        <div>
          <div>
            <Image source={props.source} height={40} width={40} />
          </div>
        </div>
      </div>
    </div>
  );
};

const categoryCard = ({ children }) => {
  const cardStyle = {
    borderRadius: "20px",
    border: "1px solid #090A14",
    padding: "2px 8px",
    margin: "10px",
  };

  return <div className="categoryCard">{children}</div>;
};

export const AnalyticsCard = function (props) {
  return (
    <div
      style={{ backgroundColor: props.backgroundColor }}
      className="analytics-main-container"
    >
      <div className="analytics-influencer-data">
        <CustomText h5 text={props.title} />

        <div style={{ gap: 3 }} className="flex-row">
          <CustomText
            h2
            text={props.value}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            className="typographyH7"
          />
          <CustomText
            p
            text={props.secondValue}
            className="typography14"
            textAlign={"text_align_left"}
            textColor={"text_color_secondary"}
          />
        </div>
      </div>
      {props.iconName && (
        <div className="analytics-icon">
          <Icon iconName={props.iconName} />
        </div>
      )}
      {props.source && (
        <div className="analytics-icon">
          <Image source={props.source} />
        </div>
      )}
    </div>
  );
};

export const CustomRadioButton = function (props) {
  return (
    <label className={`custom-radio-container ${props.className}`}>
      <input
        className="custom-radio-input"
        type="radio"
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <span className="radio-label">{props.label}</span>
    </label>
  );
};

// export const CampaignListAllData = function (props) {
//   return (
//     <div className="Campaign-list" key={props.key} onClick={props.onClick}>
//       <div className="Campaign-list-data-profile">
//         <div className="campaign-list-data m-r-16">
//           <CustomText
//             p
//             fontSize={"font_size_medium"}
//             text={props.series}
//             textColor={"text_color_primary"}
//             noOfLine={"twoLines"}
//           />
//         </div>
//         <div
//           style={{
//             borderRadious: "40px",
//           }}
//         >
//           <Image source={props.source} height={40} width={40} />
//         </div>
//         <div className="campaign-list-data m-r-16">
//           <CustomText
//             p
//             fontSize={"font_size_medium"}
//             text={props.title}
//             textColor={"text_color_primary"}
//             noOfLine={"twoLines"}
//           />
//         </div>
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.type}
//           textColor={"text_color_primary"}
//         />
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.View}
//           textColor={"text_color_primary"}
//         />
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.Bids}
//           textColor={"text_color_primary"}
//         />
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.Contract}
//           textColor={"text_color_primary"}
//         />
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.Status}
//           textColor={"text_color_primary"}
//         />
//       </div>
//       <div className="Campaign-list-data">
//         <CustomText
//           p
//           fontSize={"font_size_large"}
//           text={props.Posted}
//           textColor={"text_color_primary"}
//         />
//       </div>
//     </div>
//   );
// };
