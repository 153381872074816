import React from "react";
import {
  CustomBox,
  CustomText,
  FallBack,
  Image,
  TopHeading,
  PrimaryCta,
  CampaignList,
  AssignmentList,
  FilterBtn,
  Video,
  AnalyticsCard,
  CustomRadioButton,
} from "../components";
import SideMenu from "./sideMenu";
import Header from "./header";
import MathPlus from "../resources/images/math-plus.svg";
import RightArrowO from "../resources/images/arrow-right-circle-thin.svg";
import Instagram from "../resources/images/instagram.svg";
import Creator from "../resources/images/creator.png";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import InfiniteScroll from "react-infinite-scroll-component";
import utils from "../common/utils";
import Core from "../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import Style from "../components/core_component/style";
import * as PreviewCampaign from "../common/data/preview_campaign";
import { Crisp } from "crisp-sdk-web";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import Sdk from "../common/core/module";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import CampaignListingScreen from "./campaignListingScreen";
import CustomTable from "../components/customTable";
import { Loader } from "./loader";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import InfluencerDetailModal from "../modal/influencerDetialModal";
import * as CampaignData from "../common/data/campaign";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import SubscriptionModal from "../modal/subscriptionModal";
import { useToast } from "../features/toastContainer";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const limit = 10;

const videooSample = require("../resources/video/beauty.mp4");

const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];

const DashboardNewScreen = function (props) {
  const [assignmentList, setAssignmentList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [suggestedLoading, setSuggestedLoading] = useState(false);
  const [next, setNext] = useState(0);
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [creditsDetail, setCreditsDetail] = useState({});
  const [activeTab, setActiveTab] = useState("instagram");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredVideo, setHoveredVideo] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [modalIsOpenQuick, setModalIsOpenQuick] = useState(false);
  const [bundleType, setBundleType] = useState("FASHION_BEAUTY");
  const [platformId, setPlatformId] = useState("btll");
  const [influencerList, setInfluencerList] = useState([]);
  const [youtubeInfluencerList, setYoutubeInfluencerList] = useState([]);
  const [instagramInfluencerList, setInstagramInfluencerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [brandReport, setBrandReport] = useState({});
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openSnackbar] = useSnackbar();
  const [currentSelectedPlatform, setCurrentSelectedPlatform] =
    useState("btll");
  const [previousSelectedPlatform, setPreviousSelectedPlatform] =
    useState("btll");
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);
  const [searchBio, setSearchBio] = useState(null);
  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    profession: [],
    language: [],
    counter: null,
    invite_mark: null,
    verified: null,
  });
  const [statusColors, setStatusColors] = useState({
    ACTIVE: "#E89822",
    INACTIVE: "red",
    COMPLETED: "#68D184",
    IN_REVIEW: "#1977f2",
  });

  const showToast = useToast();
  const toggleFlyoutDropdown = () => {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["view_profile"]
      )
    ) {
      showToast();
    } else {
      setInfluencerFlyOut(!influencerFlyOut);
    }
  };

  var navigate = useNavigate();

  useEffect(() => {
    getBrandReports();
    subscriptionUserDetail();
    // getInfluencerDiscoveryList();
  }, []);

  useEffect(() => {
    getInfluencerDiscoveryList();
  }, [currentSelectedPlatform]);

  const getSelectedPlatformData = (platforms_data) => {
    console.log("demo3", platforms_data);

    //   console.log("data?.", Utils.isObjectOrNullUndefinedZero(platforms_data));
    if (!Utils.isEmpty(platforms_data)) {
      return platforms_data?.find(
        (item) => item?.platform_id === currentSelectedPlatform
      );
    }
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  // const getEngagementRate = (platforms_data) => {
  //   const selectedPlatformData = getSelectedPlatformData(platforms_data);
  //   var eng_data = null;

  //   var eng_rate = null;
  //   if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
  //     eng_data =
  //       Number(selectedPlatformData?.avg_likes) +
  //       Number(selectedPlatformData.avg_comments);

  //     eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
  //   }

  //   return eng_rate?.toFixed(1);
  // };

  useEffect(() => {
    Crisp.chat.show();
    return () => Crisp.chat.hide();
  }, []);

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModalQuick = (item) => {
    if (modalIsOpenQuick == false) {
      setBundleType(item);
      setModalIsOpenQuick(true);
    } else {
      setModalIsOpenQuick(false);
    }
  };

  const closeModalQuick = () => {
    setModalIsOpenQuick(false);
  };

  //data campaign call start

  function createCampaign() {
    // if (address && is_verified) {
    if (org_deep_data?.org_data?.credits < 10) {
      openSnackbar("Not enough credits for create campaign");
      return false;
    } else {
      navigate("/create-campaign");
    }
  }

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
    all_permissions = {},
  } = redux_selector.UserData;

  function getCampaignListUser(startPage, progress_cb) {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      limit: limit,
      start: startPage,
      // invert: "true",
      // status: "COMPLETED",
    };
    Core.getCampaignListUser(
      getCampaignListUserSuccess,
      getCampaignListUserFailed,
      progress_cb,
      params
    );
  }

  function getCampaignListUserSuccess(response_data) {
    if (next == 0) {
      setCampaignList(response_data["campaign_list"]);
    } else {
      setCampaignList([...campaignList, ...response_data["campaign_list"]]);
    }
    setNext(response_data?.pagination_data?.next);
  }

  function getCampaignListUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function currencyConverter(value) {
    return Utils.convertFromINR(value, user_data.country);
  }

  function subscriptionUserDetail() {
    var params = {};
    Core.subscriptionUserDetail(
      subscriptionUserDetailSuccess,
      subscriptionUserDetailFailed,
      updateProgress,
      params
    );
  }

  function subscriptionUserDetailSuccess(response_data) {
    const plans = response_data?.subscription_list;
    setSubscriptionData(plans);
    props.onLoadHeader && props.onLoadHeader(plans);
  }

  function subscriptionUserDetailFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }
  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data.credit_data);
    setLoading(false);
  }

  function getCreditDetailFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getBrandReports() {
    setIsLoading(true);
    if (redux_selector.UserData.brand_deep_data) {
      var params = {
        brand_id:
          redux_selector?.UserData?.brand_deep_data?.brand_data?.brand_id,
      };
      Core.getBrandReports(
        getBrandReportsSuccess,
        getBrandReportsFailed,
        () => {},
        params
      );
    }
  }

  function getBrandReportsSuccess(response) {
    setBrandReport(response);
    getCampaignListUser(0, updateProgress);
    getAssignmentBrandDetails(0, updateProgress);
    setIsLoading(false);
  }

  function getBrandReportsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: redux_selector.UserData.brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getAssignmentBrandDetails(startPage, progress_cb) {
    var params = {
      brand_id: redux_selector.UserData.brand_deep_data.brand_data.brand_id,
      limit: limit,
      invert: true,
      status: "COMPLETED",
    };
    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      progress_cb,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
  }

  function getAssignmentBrandDetailsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function getInfluencerDiscoveryList() {
    // var platformId = currentSelectedPlatform === "Youtube" ? "nxjo" : "btll";
    console.log("demo77");
    var params = {
      platform_id: currentSelectedPlatform,
    };

    Core.getSuggestedInfluencerListDiscovery(
      (...args) => getInfluencerDiscoveryListSuccess(...args),
      getInfluencerDiscoveryListFailed,
      () => {},
      params
    );
  }

  function getInfluencerDiscoveryListSuccess(response_data) {
    console.log("dem2", currentSelectedPlatform);

    if (
      response_data?.suggested_list &&
      Array.isArray(response_data.suggested_list)
    ) {
      console.log(
        "Data filter in suggested_list",
        response_data.suggested_list
      );

      if (currentSelectedPlatform == "nxjo") {
        setYoutubeInfluencerList(response_data.suggested_list);
      } else {
        setInstagramInfluencerList(response_data.suggested_list);
      }

      setInfluencerList(response_data.suggested_list);
    } else {
      console.log("suggested_list is empty or not an array");
    }

    //   setSuggestedLoading(false);
  }

  console.log("demo 88", youtubeInfluencerList);
  console.log("demo 89", instagramInfluencerList);

  function filteringData() {
    if (currentSelectedPlatform == "nxjo") {
      const filteredData = youtubeInfluencerList.filter((item) =>
        item.platforms_data.some(
          (platform) => platform.platform_id === currentSelectedPlatform
        )
      );
      return filteredData;
    } else {
      const filteredData = instagramInfluencerList.filter((item) =>
        item.platforms_data.some(
          (platform) => platform.platform_id === currentSelectedPlatform
        )
      );
      return filteredData;
    }
  }
  // console.log("filterd", filteringData() );
  // console.log("filt state" , currentSelectedPlatform);

  console.log("lists ", influencerList);

  function getInfluencerDiscoveryListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  function CampaignClick(campaignId) {
    navigate(`/campaigns/${campaignId}`);
  }
  function navigateToAssignmentDetails(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }

  let completedAssignment = 0;

  let totalAssignment;

  function getDataDeliverables(contents_deep_data) {
    totalAssignment = Object.keys(contents_deep_data).length;

    Object.keys(contents_deep_data).map((item) => {
      const { status } = contents_deep_data[item].content_data;
      if (status == "COMPLETED") {
        completedAssignment++;
      }

      return (
        completedAssignment +
        " " +
        "out of " +
        totalAssignment +
        " images ready to review"
      );
    });
    return (
      completedAssignment +
      " out of " +
      totalAssignment +
      " images ready to review"
    );
  }
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const columns = [
    {
      name: "Creator Name",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            fallBack={require("../resources/images/fallback.png")}
            source={
              row.influencer_data.profile_picture
                ? utils.generateInfluencerDpUrl(
                    row.influencer_data.influencer_id,
                    row.influencer_data.profile_picture
                  )
                : require("../resources/images/fallback.png")
            }
            className="table-profile"
          />
          <div className="influencer-table-profile">
            {row.influencer_data.name}
          </div>
        </div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row.reward_data.value.amount,
    },
    {
      name: "Deliverables",
      selector: (row) => getDataDeliverables(row.contents_deep_data),
    },
    {
      name: "Status",
      selector: (row) => row.assignment_data.assignment_status,
      conditionalCellStyles: [
        {
          when: (row) => row.assignment_data.assignment_status === "ACTIVE",
          style: Style.text_active_color,
        },
        {
          when: (row) =>
            row.assignment_data.assignment_status === "IN_PROGRESS",
          style: Style.text_active_color,
        },
        {
          when: (row) => row.assignment_data.assignment_status === "CANCELLED",
          style: Style.text_pending_color,
        },
        {
          when: (row) =>
            row.assignment_data.assignment_status === "PAYMENT_PENDING",
          style: Style.text_pending_color,
        },
        {
          when: (row) => row.assignment_data.assignment_status === "COMPLETED",
          style: Style.text_completed_color,
        },
      ],
    },
  ];
  function navigateToContractDetails(assignment_id) {
    navigate("/contract/" + assignment_id);
  }
  const { is_kyc_done, is_verified } = orgData;

  const quickCreateCampaignData = [
    {
      video: require("../resources/video/reel.mp4"),
      post_name: "Instagram Reel Bundle",
      post_pack: "(Pack of 50 Reels)",
      bundleType: "FASHION_BEAUTY",
      description:
        "50 Nano Influencers of any gender and age will \n create  Instagram Reel and post on Instagram ",
    },
    {
      video: require("../resources/video/story.webp"),
      post_name: "Instagram Story Share",
      post_pack: "(Pack of 50 Stories)",
      bundleType: "FASHION_BEAUTY_STORY",
      description:
        "50 Nano Influencers of any gender  and \n age will  add your content on their stories ",
    },
    {
      video: require("../resources/video/ugc.mp4"),
      post_name: "UGC Videos Bundle ",
      post_pack: "(Pack of 20 Reels)",
      bundleType: "FASHION_BEAUTY_CONTENT",
      description:
        "20 UGC video mp4 files from nano \n influencers of any age and any category",
    },
    {
      video: require("../resources/video/beauty.mp4"),
      post_name: "Instagram Beauty Queens ",
      post_pack: "(Pack of 10 Reels)",
      bundleType: "FASHION_BEAUTY_MICRO",
      description:
        " 10  Instagram Reels from Good quality \n Beauty and Fashion category nano Influencers ",
    },
    {
      video: require("../resources/video/tech.mp4"),
      post_name: "Tech Influencers ",
      post_pack: "(Pack of 5 Reels)",
      bundleType: "TECHNOLOGY_MICRO",
      description:
        "5 Instagram Reels from Good quality \n Tech category nano Influencers ",
    },
    {
      video: require("../resources/video/food.mp4"),
      post_name: "Restaurant Visit",
      post_pack: "(Pack of 50 Reels)",
      bundleType: "FOOD_DRINK_MICRO",
      description:
        "6 store visit from Micro Influencers you will get \n good quality content on their instagram profile.",
    },
  ];

  const tableSchema = [
    {
      name: "Campaign Name",
      selector: (row) => row["campaign_data"]["name"],
      // sortable: true,
      cell: (data) => (
        <span
          style={{ textAlign: "left" }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {utils.truncateWithEllipsis(data.campaign_data.name, 60)}
        </span>
      ),
    },
    {
      name: "Views",
      selector: (row) => row["campaign_data"]["seen_count"],
      // sortable: true,
      maxWidth: "121px",
      omit: isMobile,
    },
    {
      name: "Proposals",
      selector: (row) => row["campaign_data"]["bid_count"],
      maxWidth: "121px",
      textAlign: "right",
      // sortable: true,
    },
    {
      name: "Contracts",
      selector: (row) => row["campaign_data"]["assignment_count"],
      maxWidth: "121px",
      omit: isMobile,
    },
    {
      name: "Status",
      selector: (row) =>
        utils.assignmentStatusToString(row["campaign_data"]["status"]),
      maxWidth: "195px",
      omit: isMobile,
      cell: (data) => (
        <span
          style={{ color: statusColors[data.campaign_data.status] }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {utils.assignmentStatusToString(data.campaign_data.status)}
        </span>
      ),
    },
    {
      name: "Type",
      selector: (row) => utils.getCampaignType(row["campaign_data"]["type"]),
      // sortable: true,
      maxWidth: "149px",
      omit: isMobile,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  const contractTableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => {
        return row["influencer_data"]["name"];
      },
    },

    {
      name: "Rewards",
      maxWidth: "160px",
      selector: (row) => {
        return row["reward_data"]["value"]["amount"] == 0
          ? "-"
          : Utils.convertFromINR(
              row["reward_data"]["value"]["amount"],
              user_data.country
            );
      },
    },

    {
      name: "Deliverables",
      selector: (row) => {
        return utils.generateAssignmentSubText(row);
      },
      omit: isMobile,

      maxWidth: "500px",
    },
    {
      name: "Delivery Date",
      selector: (row) => {
        return utils.convertUnixToDate(
          row?.campaign_data?.delivery_date,
          user_data.time_zone
        );
      },
      omit: isMobile,
      maxWidth: "140px",
    },

    {
      name: "Status",
      omit: isMobile,
      selector: (row) => {
        return utils.assignmentStatusToString(
          row["assignment_data"]["assignment_status"]
        );
      },

      maxWidth: "185px",
      cell: (data) => (
        <span
          style={{
            color: statusColors[data.assignment_data.assignment_status],
          }}
        >
          {utils.assignmentStatusToString(
            data.assignment_data.assignment_status
          )}
        </span>
      ),
    },
  ];

  // TABLE CSS

  const ContractcustomStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  //  UI REnder -------

  function getInflencerLocation(influencer_data) {
    if (!influencer_data) {
      return "-";
    }

    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  console.log("state name", filteringData());

  const InfluencerCards = ({ influencerList }) => {
    if (influencerList) {
      return (
        <div className="suggest-influcener-card-section cursor-pointer">
          {filteringData()
            .slice(0, 6)
            .map((influencer, index) => (
              <div
                className="suggest-influcener-card"
                key={index}
                onClick={(e) => {
                  toggleFlyoutDropdown();
                  setInfluencerId(
                    !Utils.isEmpty(influencer.platforms_data)
                      ? influencer?.influencer_data.influencer_id
                      : null
                  );
                }}
              >
                <div
                  className="suggest-influcener-card-img "
                  style={{ cursor: "pointer" }}
                >
                  {/* <img
                    src={
                      influencer?.influencer_data?.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            influencer?.influencer_data?.influencer_id,
                            influencer?.influencer_data?.profile_picture
                          )
                        : "../assets/images/user-fallback.svg"
                    }
                    alt="Profile"
                    height={200}
                  /> */}

                  <Image
                    source={
                      influencer?.influencer_data?.profile_picture
                        ? Utils.generateInfluencerDpUrl(
                            influencer?.influencer_data?.influencer_id,
                            influencer?.influencer_data?.profile_picture
                          )
                        : "../assets/images/user-fallback.svg"
                    }
                    fallBack={"../assets/images/user-fallback.svg"}
                    alt="Profile"
                    height={200}
                  />

                  {/*                
                <div className="request-price" onClick={ ""}>
                  <div className="inner-request-price" style={{fontSize : "14px" , display: 'flex'}}> 
  
                  Request Price
                  <Icon iconName="rightArrow" width={16} height={16} />
                  </div>
                </div> */}
                </div>
                <div className="suggest-influcner-card-content">
                  <div>
                    <CustomText
                      p
                      text={influencer?.influencer_data?.name}
                      className="text-new-blue text-16 font-600"
                    />
                    <CustomText
                      p
                      text={
                        influencer?.influencer_data?.state_id
                          ? `${
                              getInflencerLocation(influencer.influencer_data)
                                ? getInflencerLocation(
                                    influencer.influencer_data
                                  )
                                : ""
                            }, India`
                          : "-"
                      }
                      className="text-new-gray font-medium m-t-4 text-14"
                    />
                  </div>
                  <div className="suggest-card-analytics">
                    <div>
                      <p className="text-12 font-600 text-new-gray">
                        {currentSelectedPlatform == "btll"
                          ? "Followers"
                          : "Subscriber"}
                      </p>
                      <p className="text-14 text-new-blue font-600 m-t-4">
                        {/* {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.followers || "-"
                        )} */}

                        {formatPlatformData(
                          influencer?.platforms_data,
                          "followers"
                        )}
                      </p>
                    </div>
                    <div className="average-views-in-card">
                      <p className="text-12 font-600 text-new-gray">
                        Average Views
                      </p>
                      <p className="text-14 text-new-blue m-t-4 text-align font-600">
                        {Utils.changeNumberFormate(
                          influencer?.platforms_data[0]?.avg_views || "-"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="text-12 font-600 text-new-gray text-right">
                        ER
                      </p>
                      <p className="text-14 m-t-4 text-new-blue font-600">
                        {influencer?.platforms_data[0]?.engagement_rate != null
                          ? `${influencer.platforms_data[0].engagement_rate.toFixed(
                              1
                            )}%`
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    }
  };

  const rightCardSection = () => {
    return (
      <div className="right-dashboard-container">
        <div className="total-campaign-view-card-credit">
          <div className="total-campaign-view-credit">
            {/* <div className="analytics-card-img">
              <Image source={"../assets/images/Campaign-icon.png"} />
            </div> */}
            <div>
              <CustomText
                p
                text="Credits"
                className="text-new-gray-500 font-medium text-16"
              />
              <CustomText
                p
                text={org_deep_data?.org_data?.credits}
                className="text-new-blue m-t-6 text-16 font-600"
              />
            </div>
          </div>
          <div>
            <PrimaryCta
              invert
              className="sidebar-credit-btn-campaign"
              onClick={() => navigate("/checkout")}
              textColor={"text_color_btn"}
              text={"Buy Credits"}
            />
          </div>
        </div>
        <div className="right-dashboard-analytics-card">
          <CustomText
            p
            text="Quick Overview"
            className="text-16 font-medium text-new-gray"
          />

          <div className="total-campaign-view-card">
            <div className="analytics-card-img">
              <Icon iconName="totalCampaignView" />
            </div>
            <div>
              <CustomText
                p
                text="Total Campaign Views"
                className="text-new-gray-500 font-medium text-14"
              />
              <CustomText
                p
                text={brandReport?.total_view}
                className="text-new-blue m-t-6 text-16 font-600"
              />
            </div>
          </div>
          <div
            className="total-campaign-view-card"
            style={{ backgroundColor: "#F6E3FF" }}
          >
            <div className="analytics-card-img">
              <Icon iconName="totalProposal" />
            </div>
            <div>
              <CustomText
                p
                text="Total Proposal "
                className="text-new-gray-500 font-medium text-14"
              />
              <CustomText
                p
                text={brandReport?.total_bids}
                className="text-new-blue m-t-6 text-16 font-600"
              />
            </div>
          </div>
          <div
            className="total-campaign-view-card"
            style={{ backgroundColor: "#DEFFE9" }}
          >
            <div className="analytics-card-img">
              <Icon iconName="totalSpent" />
            </div>
            <div>
              <CustomText
                p
                text="Total Spent"
                className="text-new-gray-500 font-medium text-14"
              />
              <CustomText
                p
                text={
                  brandReport?.total_spent
                    ? ` ${currencyConverter(brandReport.total_spent)}`
                    : 0
                }
                className="text-new-blue m-t-6 text-16 font-600"
              />
            </div>
          </div>
          <div
            className="total-campaign-view-card"
            style={{ backgroundColor: "#FFF0DD" }}
          >
            <div className="analytics-card-img">
              <Icon iconName="totalContracts" />
            </div>
            <div>
              <CustomText
                p
                text="Total Contracts"
                className="text-new-gray-500 font-medium text-14"
              />
              <CustomText
                p
                text={brandReport?.total_contract}
                className="text-new-blue m-t-6 text-16 font-600"
              />
            </div>
          </div>
        </div>

        <div className="right-dashboard-demo-card">
          <CustomText
            p
            text={"How Reelax Works?"}
            className="text-new-gray font-medium text-16 m-b-12 "
          />

          <div>
            <CustomText
              p
              text="Schedule a demo with Brand Manager"
              className="text-20 text-new-blue  font-600 m-b-12"
            />
            <CustomText
              p
              text="You dedicated brand manager will guide you how to use Reelax platform and help you to setup your campaigns."
              className="text-16 text-new-gray "
            />
          </div>

          <div
            className="schedule-demo cursor-pointer"
            onClick={() =>
              window.open("https://calendly.com/reelax/onboarding")
            }
          >
            <CustomText
              p
              text="Schedule a Demo"
              className="text-13 text-new-blue font-600"
            />
            <div className="header-icon-center">
              <Icon iconName="rightArrowDark" />
            </div>
          </div>
        </div>
        <div className="right-dashboard-connect-card">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "6px" }}>
              <CustomText
                p
                text="Contact Your Support Buddy"
                className="text-16 font-medium text-new-gray m-b-16"
              />
              <div
                className="new-content-box"
                style={{ position: "relative", top: "3px" }}
              >
                <p> New </p>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <div className="support-buddy-img">
                <img src={"../assets/images/campaign-manger.jpg"} />
              </div>
              <div>
                <CustomText
                  p
                  text="Sakshi Goyal"
                  className="text-20 text-new-blue"
                />
                <CustomText
                  p
                  text="Campaign Manager"
                  className="text-16 text-new-gray m-t-4"
                />
              </div>
            </div>
            <div className="br-line"></div>
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Image source={"../assets/images/phone-call.png"} />
                  <CustomText
                    p
                    text={"9653524213"}
                    className="text-14 text-new-gray"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "4px",
                  }}
                >
                  <Image source={"../assets/images/mail-send.png"} />
                  <CustomText
                    p
                    text={"sakshi.goyal@getreelax.com"}
                    className="text-14 text-new-gray"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleRoleChange = (e) => {
    const selectedPlatform = e.target.value;
    console.log("value de", selectedPlatform);
    setCurrentSelectedPlatform(selectedPlatform);
  };

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="">
        <Header />
      </div>
      {loading ? (
        <div className="loader-mid">
          {" "}
          <Loader></Loader>{" "}
        </div>
      ) : (
        <>
          <div className="page-container-dashboard-wrap">
            <div className="page-container-dashboard">
              <div className="suggest-inflencer-box">
                <div className="suggested-influencers">
                  <CustomText
                    p
                    text={"Suggested Influencers"}
                    className="text-16 text-gray font-600 responsive-text"
                  />
                  <div className="new-content-box">
                    <p> New </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px",
                    marginRight: "15px",
                    fontSize: "14px",
                    color: "#3A3B43",
                  }}
                >
                  <div className="m-w-85" style={{ cursor: "pointer" }}>
                    <CustomRadioButton
                      name="platform"
                      value="btll"
                      checked={currentSelectedPlatform === "btll"}
                      label="Instagram"
                      onChange={handleRoleChange}
                    />
                  </div>
                  <div className="m-w-85" style={{ cursor: "pointer" }}>
                    <CustomRadioButton
                      name="platform"
                      value="nxjo"
                      checked={currentSelectedPlatform === "nxjo"}
                      label="Youtube"
                      onChange={handleRoleChange}
                    />
                  </div>
                </div>
              </div>

              {/* ---- Influencer  Card Section ----- */}
              <InfluencerCards
                influencerList={
                  currentSelectedPlatform == "nxjo"
                    ? youtubeInfluencerList
                    : instagramInfluencerList
                }
              />

              <div className="dashboard-table" style={{ padding: "0 16px" }}>
                <div className="responsive-gap-for-heading">
                  <CustomText
                    p
                    text="Active Campaigns"
                    className="text-gray text-16px font-medium m-b-12"
                  />
                </div>
                {campaignList.length !== 0 ? (
                  <>
                    <div className="custom-tabl">
                      <CustomTable
                        className="CustomTable"
                        tableSchema={tableSchema}
                        tableData={campaignList.slice(0, 5)}
                        paginationPerPage={15}
                        rowPaddingTop={16}
                        rowPaddingBottom={16}
                        customStyles={customStyles}
                        onRowClicked={(item) =>
                          CampaignClick(item.campaign_data.campaign_id)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className="fallback">
                    <FallBack
                      heading={"No live campaigns"}
                      title={"Launch a new campaign"}
                    />

                    <div className="m-t-15" style={{ marginBottom: "16px" }}>
                      <PrimaryCta
                        third
                        fontSize={"font_size_regular"}
                        className="create-campaign"
                        text={"Create Campaign"}
                        onClick={() => createCampaign()}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="dashboard-table" style={{ padding: "0 16px" }}>
                <div className="responsive-gap-for-heading">
                  <CustomText
                    p
                    text="Active Contracts"
                    className="text-gray text-16px font-medium m-b-12"
                  />
                </div>
                {assignmentList.length !== 0 ? (
                  <>
                    <div className="m-b-12">
                      <CustomTable
                        className="CustomTable"
                        tableSchema={contractTableSchema}
                        tableData={assignmentList.slice(0, 5)}
                        rowPaddingTop={16}
                        rowPaddingBottom={16}
                        customStyles={ContractcustomStyles}
                        onRowClicked={(item) =>
                          navigateToAssignmentDetails(
                            item.assignment_data.assignment_id
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className="fallback">
                    <FallBack
                      heading={"No Active Contracts"}
                      title={"No active contracts available for you"}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* ----- Right side ----- */}

            {rightCardSection()}
          </div>
        </>
      )}
      {
        <SubscriptionModal
          subscriptionData={subscriptionData}
          closeModal={closeModal}
          orgData={org_deep_data?.org_data}
          modalIsOpen={modalIsOpen}
        />
      }
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
    </div>
  );
};

export default DashboardNewScreen;
