import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, FormGroup, Label, Input, Util } from "reactstrap";
import { CustomText, Image, PrimaryCta } from "../../components";

import { ToastContainer, toast } from "react-toastify";
import Utils from "../../common/utils";

function CampaignType(props) {
  const campaign_detail = props.campaignDetail;
  const navigate = useNavigate();
  const campaignType = campaign_detail?.type;
  const [selectedRole, setSelectedRole] = useState();
  const [isMarketplace, setIsMarketplace] = useState(1);
  const [isOffline, setIsOffLine] = useState(0);
  const [selectedReward, setSelectedReward] = useState("");
  const [selectedEngagement, setSelectedEngagement] = useState("AUCTION");
  const [isMarketplaceSelected, setIsMarketplaceSelected] = useState("");

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleRewardChange = (event) => {
    setSelectedReward(event.target.value);
  };

  const handleMarketplaceChange = (is_marketplace, is_offline, checked) => {
    setIsMarketplaceSelected(checked);
    setIsMarketplace(is_marketplace);
    setIsOffLine(is_offline);
  };

  // const handleRoleChange = (event) => {
  //   setSelectedRole(event.target.value);
  // };
  // const handleMarketplaceChange = (is_marketplace, is_offline, checked) => {
  //   setIsMarketplaceSelected(checked);
  //   setIsMarketplace(is_marketplace);
  //   setIsOffLine(is_offline);
  // };

  console.log("isOffline", isOffline, isMarketplace);

  // const handleRewardChange = (event) => {
  //   setSelectedReward(event.target.value);
  // };

  const handleEngagementChange = (event) => {
    setSelectedEngagement(event.target.value);
  };

  const handleNext = () => {
    if (!formValidate()) {
      return false;
    }
    props.setCampaignDeepData((prev_state) => {
      return {
        ...prev_state,

        campaign_data: {
          ...prev_state.campaign_data,
          type: selectedRole,
          collab_type: selectedReward,
          engagement_type: selectedEngagement,
          is_offline: isOffline,
          is_marketplace: isMarketplace,
        },
      };
    });
    props.nextStep();
  };
  console.log("demo 11111m", isMarketplace);
  console.log("demo 1111o", isOffline);

  const handleFinish = () => {
    props.lastStep();
  };

  function formValidate() {
    if (!isMarketplaceSelected) {
      toast("Please select marketplace type");
      return false;
    }
    if (!selectedRole) {
      toast("Please select campaign type");
      return false;
    }
    if (!selectedEngagement) {
      toast("Please select engagement type");
      return false;
    }
    if (!selectedReward) {
      toast("Please select reward type");
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="type-campaign-container flex-column">
        <div>
          <div>
            <CustomText
              p
              className="button2"
              text={"Campaign Delivery"}
              fontWeight={"Poppins_semiBold"}
            />
          </div>
          <div
            style={{ flex: 1 }}
            className="flex-row m-t-8 wrap-campaign-type-container"
          >
            <label
              style={{ flex: 0.5 }}
              id="MARKETPLACE"
              className="label-cursor media-full-width"
            >
              <div
                className={
                  isMarketplaceSelected == "MARKETPLACE"
                    ? "type-campaign-active"
                    : "type-campaign"
                }
              >
                <input
                  className="select-radio-btn"
                  type="radio"
                  name="MARKETPLACE"
                  id="MARKETPLACE"
                  checked={isMarketplaceSelected === "MARKETPLACE"}
                  onChange={() => handleMarketplaceChange(1, 0, "MARKETPLACE")}
                />
                <div className="type-media">
                  <CustomText
                    p
                    className="button2"
                    text={"Inbound"}
                    fontWeight={"Poppins_semiBold"}
                  />
                  <CustomText
                    p
                    textColor={"text_color_black"}
                    fontSize={"font_size_regular"}
                    text={`Influencer will reach out to you, Good option for high volume Influencer campaign.`}
                    className="caption_2 "
                  />
                </div>
              </div>
            </label>
            <label
              id="NON_MARKETPLACE"
              className="label-cursor label-container-left media-full-width"
            >
              <div
                className={
                  isMarketplaceSelected == "NON_MARKETPLACE"
                    ? "type-campaign-active"
                    : "type-campaign"
                }
              >
                <input
                  className="select-radio-btn"
                  type="radio"
                  name="NON_MARKETPLACE"
                  value="NON_MARKETPLACE"
                  id="NON_MARKETPLACE"
                  checked={isMarketplaceSelected === "NON_MARKETPLACE"}
                  onChange={() =>
                    handleMarketplaceChange(0, 1, "NON_MARKETPLACE")
                  }
                />
                <div className="type-media">
                  <CustomText
                    p
                    className="button2"
                    text={"Outbound"}
                    fontWeight={"Poppins_semiBold"}
                  />
                  <CustomText
                    p
                    textColor={"text_color_black"}
                    fontSize={"font_size_regular"}
                    text={`You will get AI suggested list of influencers to choose from, Good for low volume campaign.`}
                    className="caption_2"
                  />
                </div>
              </div>
            </label>
          </div>
        </div>

        {isMarketplaceSelected && (
          <div>
            <div>
              <CustomText
                p
                className="button2"
                text={"Campaign Type"}
                fontWeight={"Poppins_semiBold"}
              />
            </div>
            <div
              style={{ flex: 1 }}
              className="flex-row m-t-8 wrap-campaign-type-container"
            >
              <label
                id="social_media"
                className="label-cursor label-container-right"
              >
                <div
                  className={
                    selectedRole == "social_media"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="social_media"
                    value="social_media"
                    id="social_media"
                    checked={selectedRole === "social_media"}
                    onChange={handleRoleChange}
                  />
                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"Social Media Campaign"}
                      fontWeight={"Poppins_semiBold"}
                    />
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`The influencer will share the post on their social media account and tag you on the post`}
                      className="caption_2 "
                    />
                  </div>
                </div>
              </label>
              <label
                style={{ flex: 0.5 }}
                id="content"
                className="label-cursor media-full-width"
              >
                <div
                  className={
                    selectedRole == "content"
                      ? "type-campaign-active "
                      : "type-campaign "
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="content"
                    value="content"
                    id="content"
                    checked={selectedRole === "content"}
                    onChange={handleRoleChange}
                  />
                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"UGC Campaign"}
                      fontWeight={"Poppins_semiBold"}
                    />
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`Influencer will share content file with copyright, you can use it in Meta Ads`}
                      className="caption_2 "
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}

        {selectedRole && (
          <div>
            <div>
              <CustomText
                p
                className="button2"
                text={"Payment Type"}
                fontWeight={"Poppins_semiBold"}
              />
            </div>
            <div
              style={{ flex: 1 }}
              className="flex-row m-t-8 wrap-campaign-type-container"
            >
              <label
                style={{ flex: 0.5 }}
                id="AUCTION"
                className="label-cursor media-full-width"
              >
                <div
                  className={
                    selectedReward == "AUCTION"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="AUCTION"
                    value="AUCTION"
                    id="AUCTION"
                    checked={selectedReward === "AUCTION"}
                    onChange={handleRewardChange}
                  />
                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"Cash "}
                      fontWeight={"Poppins_semiBold"}
                    />
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`Influencer will receive cash reward after content approval`}
                      className="caption_2 "
                    />
                  </div>
                </div>
              </label>
              <label
                id="BARTER"
                className="label-cursor label-container-left media-full-width"
              >
                <div
                  className={
                    selectedReward == "BARTER"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="BARTER"
                    value="BARTER"
                    id="BARTER"
                    checked={selectedReward === "BARTER"}
                    onChange={handleRewardChange}
                  />
                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"Barter "}
                      fontWeight={"Poppins_semiBold"}
                    />
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`Influencer will create content for delivered/purchased product`}
                      className="caption_2"
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}
      </div>

      {/* <hr /> */}
      <div className="divide-line"></div>
      <div className="type-campaign-btn" >
        <div style={{maxWidth:150}}>
        <PrimaryCta
          invert
          onClick={() => navigate("/")}
          fontSize={"font_size_large"}
          text={"Cancel"}
        />
        </div>
        

        <PrimaryCta
          fifth
          onClick={handleNext}
          fontSize={"font_size_large"}
          text={"Next"}
          className="blue-button"
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
      />
    </>
  );
}

export default CampaignType;
