import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Core from "../common/clientSdk";
import { useNavigate } from "react-router-dom";
import CustomTable from "../components/customTable";
import Tooltip from "@mui/material/Tooltip";
import { Loader } from "../screens/loader";
import {
  CustomButton,
  CustomText,
  FallBack,
  FallBackBtn,
  Image,
  PrimaryCta,
} from "../components";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import { useSelector } from "react-redux";
import InfluencerDetailModal from "./influencerDetialModal";
import { useToast } from "../features/toastContainer";
const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      minHeight: "60px",
      cursor: "pointer",
      fontSize: "14px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
      minHeight: "60px",
    },
  },
};

function CollectionCampaignInfluencerTable(props) {
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [isInviteLoading, setInviteLoading] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setListLoading] = useState(false);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState("");
  const campaignStatus = props.campaignDetail?.campaign_data?.status;

  const showToast = useToast();
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { user_data = {} } = UserData;
  const { all_permissions = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  const navigate = useNavigate();
  useEffect(() => {
    if (collectionInfluencerList?.campaignDetail?.campaign_data?.end_date) {
      const expired = isCampaignExpired(
        collectionInfluencerList.campaignDetail.campaign_data.end_date
      );
      if (expired) {
        setIsLoading(false); // Stop the loader
        return; // Exit early if the campaign is expired
      }
    }
    setIsLoading(true); // Start the loader only if the campaign is not expired
    getCollectionInfluencerList(); // Fetch the collection list
  }, []);

  useEffect(() => {
    if (props.collectionId) {
      getCollectionInfluencerList();
      getInfluencerInviteList();
    }
  }, [props.collectionId]);

  const isCampaignExpired = (endDate) => {
    if (!endDate) return false; // If no expiry date, assume it's not expired
    const currentDate = new Date();
    const campaignExpiryDate = Utils.convertUnixToDate(endDate);
    return currentDate > campaignExpiryDate; // Check if the current date is past the expiry date
  };

  function inviteInfluencer(influencer_id) {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["shortlist_influencer"]
      )
    ) {
      showToast();
      // toast.info("You are not authorised to sent request price");
    } else {
      postInfluencerInvite(influencer_id);
    }
  }

  function postInfluencerInvite(influencer_id) {
    var params = {
      campaign_id: props.campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInvite(
      postInfluencerInviteSuccess,
      postInfluencerInviteFailed,
      (status) => setInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postInfluencerInviteSuccess(response_data) {
    toast.success("Request Price sent to Influencer");

    getInfluencerInviteList();
  }

  function postInfluencerInviteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function getInfluencerInviteList() {
    var params = {
      campaign_id: props.campaignId,
    };
    Core.getInfluencerInviteList(
      getInfluencerInviteListSuccess,
      getInfluencerInviteListFailed,
      () => {},
      params
    );
  }

  function getInfluencerInviteListSuccess(response_data) {
    setInfluencerInviteList(response_data.invite_list);
  }

  function getInfluencerInviteListFailed(errorList) {
    toast.error(errorList);
    setListLoading(false);
  }
  function getCollectionInfluencerList() {
    setIsLoading(true);
    const params = {
      collection_id: props.collectionId,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (status) => setListLoading(status),
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data.collection_deep_data);
    setIsLoading(false);
  }

  function getCollectionInfluencerListFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
    setListLoading(false);
    setIsLoading(false);
  }

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );

    console.log("inviteId", inviteId);
    console.log("inviteId 2", influencerInviteList);

    return inviteId;
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const platformData = platforms_data.find(
      (item) => item.platform_id === props.platformId
    );

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find(
      (item) => item.platform_id === props.platformId
    );
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const toggleFlyoutDropdown = () => {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["view_profile"]
      )
    ) {
      showToast();
    } else {
      setInfluencerFlyOut(!influencerFlyOut);
    }
  };

  const tableInfluencerSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],
      width: "200px",
      cell: (data) => (
        <span
          onClick={() => {
            toggleFlyoutDropdown();
            setInfluencerId(data?.influencer_data?.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data?.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data?.influencer_data.influencer_id,
                    data?.influencer_data.profile_picture
                  )
                : require("../resources/images/user-copy.png")
            }
            className="collection-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data?.influencer_data.name}
                  className="Body-1"
                />
              </div>
            </div>
          </div>
        </span>
      ),
    },
    {
      name: "Social Presence",
      selector: (row) =>
        platFormName(row.platforms_data, row.influencer_data.influencer_id),
      // sortable: true,
    },

    {
      name: "Followers",
      selector: (row) => formatPlatformData(row?.platforms_data, "followers"),
      sortable: false,
    },
    {
      name: "Avg Views",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row?.platforms_data, "avg_views"),
      // width: "100px",
    },
    // {
    //   name: "Estimated",

    //   selector: (row) => row,
    //   width: "130px",
    //   cell: (data) => (
    //     <span>
    //       {data?.influencer_data?.expected_cost
    //         ? Utils.convertFromINR(
    //             data?.influencer_data?.expected_cost,
    //             user_data.country
    //           )
    //         : "-"}
    //     </span>
    //   ),
    // },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <span
    //       style={{
    //         color: Utils.colorStatus(row?.influencer_data.review_status),
    //       }}
    //     >
    //       {row?.influencer_data.review_status
    //         ? row?.influencer_data.review_status
    //         : "NOT REVIEW"}
    //     </span>
    //   ),
    //   sortable: false,
    // },
    {
      name: "ER",
      selector: (row) =>
        Utils.isNullOrUndefinedOrZero(
          formatPlatformData(row.platforms_data, "engagement_rate")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "engagement_rate"),
      // width: "130px",
      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(data?.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(data?.platforms_data, "avg_comments")}
                </div>
              </>
            }
          >
            {Utils.isNullOrUndefinedOrZero(
              Number(
                formatPlatformData(data?.platforms_data, "engagement_rate")
              )
            )
              ? "-"
              : Number(
                  formatPlatformData(data?.platforms_data, "engagement_rate")
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },
    // {
    //   name: "Price",
    //   selector: (row) =>
    //     row?.influencer_data?.price ? row?.influencer_data?.price : "...",
    //   sortable: false,

    //   cell: (data) => (
    //     <span style={{ color: "rgb(25, 119, 242)" }} className="flex-row">
    //       <CustomText
    //         p
    //         text={
    //           data?.influencer_data?.price
    //             ? data?.influencer_data?.price
    //             : "..."
    //         }
    //         className=" m-r-6"
    //       />
    //       <div>
    //         <Icon iconName="EditIconCounter" />
    //       </div>
    //     </span>
    //   ),
    // },
    {
      name: "",
      lastCol: true,
      paddingRight: "20px",
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            justifyContent: "right",
          },
        },
      ],
      cell: (data) => (
        <snap
          style={{
            display: "flex",
            gap: 24,
            alignItems: "center",
          }}
        >
          <>
            {inviteAndReject(data?.influencer_data?.influencer_id) ? (
              <PrimaryCta
                // onClick={() =>
                //   postInfluencerInviteWithdraw(
                //     data?.influencer_data?.influencer_id
                //   )
                // }
                fifth
                disabled={true}
                fontSize={"font_size_small"}
                text={"Price Requested"}
                className="blue-button"
                isLoading={""}
              />
            ) : (
              <PrimaryCta
                fifth
                className="blue-button"
                iconName="InviteIcon"
                onClick={() =>
                  inviteInfluencer(data?.influencer_data?.influencer_id)
                }
                fontSize={"font_size_small"}
                text={"Request Price"}
                isLoading={
                  isInviteLoading[data?.influencer_data?.influencer_id]
                }
                disabled={campaignStatus != "ACTIVE"}
              />
            )}
          </>
        </snap>
      ),

      // sortable: true,
    },
  ];
  function renderLoader() {
    return (
      <>
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      </>
    );
  }

  function renderFallback() {
    if (!isLoading && collectionInfluencerList.length == 0) {
      return (
        <div className="fall-back-center-campaign">
          <FallBackBtn
            heading={"!! Oops you don't have any Shortlisted Influencer..."}
            title={
              "Shortlist influencer to request pricing from suggested list"
            }
            widthIcon={100}
            heightIcon={100}
            onClick={props.handleAddInfluencersClick}
            text={"Shortlist Influencer"}
          />
        </div>
      );
    }
  }

  function customTableData() {
    return (
      <>
        <CustomTable
          className="CustomTable"
          tableSchema={tableInfluencerSchema}
          tableData={collectionInfluencerList?.collection_influencer_list}
          rowPaddingTop={16}
          rowPaddingBottom={16}
          customStyles={customStyles}
          noDataComponent={renderFallback()}
          progressComponent={renderLoader()}
          progressPending={isLoading}
        />
      </>
    );
  }

  return (
    <div className="">
      {customTableData()}
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
    </div>
  );
}

export default CollectionCampaignInfluencerTable;
