import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../screens/sideMenu";
import Header from "../screens/header";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import { useState } from "react";
import Core from "../common/clientSdk";
import { useEffect } from "react";
import validator from "validator";
import { Sidebar } from "../components";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../redux/actions";
import { toast, ToastContainer } from "react-toastify";
function UserPhoneNumber({
  changePhoneNumberSidebarOpen,
  setChangePhoneNumberSidebarOpen,
}) {
  const [changeNumber, setChangeNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    number: "",
  });
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;
  useEffect(() => {
    setChangeNumber(user_data.phone_number);
  }, []);

  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        phone_number: changeNumber,
      }),
    };
    if (validatePhone(changeNumber))
      Core.postUpdateUserProfile(
        postUpdateUserProfileSuccess,
        postUpdateUserProfileFailed,
        progress_cb,
        params
      );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    setChangePhoneNumberSidebarOpen(false)
    toast.success("User Phone Number Updated Successfully!");
  }

  function postUpdateUserProfileFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function validatePhone(value) {
    if (!validator.isMobilePhone(value, ["en-IN"])) {
      set_error({ ...error, number: "Please enter valid phone number" });
    } else {
      set_error({ ...error, number: "" });
      return true;
    }
  }
  const changeFullNameSidebarHeader = () => {
    return <CustomText p text={"Change Phone Number"} />;
  };
  const changeFullNameSidebarFooter = () => {
    return (
        <PrimaryCta
          fontSize={"font_size_large"}
          fullWidth100
          className="blue-button"
          text={"Update"}
          isLoading={isLoading}
          onClick={() => {
            postUpdateUserProfile(updateProgress)
          }}
        />
    );
  };
  return (
    <div>
    <Sidebar
      sidebarWidth={"30%"}
      isOpen={changePhoneNumberSidebarOpen}
      onClose={() => setChangePhoneNumberSidebarOpen(false)}
      Header={changeFullNameSidebarHeader}
      Footer={changeFullNameSidebarFooter}
      headerAlignment="left"
    >
      <div className="p-20">
      <div className="">
        <CustomText p text={"Phone Number"} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <div className=" full-width">
          <CustomTextInput
            value={changeNumber}
            height={"51px"}
            onChange={(e) => setChangeNumber(e.target.value)}
            onBlur={(e) => validatePhone(e.target.value)}
          />
          <h5 className="error">{error.number}</h5>
        </div>
      </div>
      </div>
    </Sidebar>
    <ToastContainer />
    </div>
  );
}

export default UserPhoneNumber;
