import React, { useState, useEffect } from "react";

// Import Redux State (Private scope)
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  CustomBox,
  CustomText,
  CustomTextInput,
  CustomCheckbox,
  PrimaryCta,
  CustomTextArea,
} from "../components";
import { useParams } from "react-router-dom";

import SideMenu from "./sideMenu";
import Header from "./header";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import toast, { Toaster } from "react-hot-toast";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utils from "../common/utils";
import Sdk from "../common/core/module";

import Core from "../common/clientSdk.js";
import Theme from "../resources/themes/light";
import * as CampaignData from "../common/data/campaign";
import Campaign from "../common/core/campaign";
import Style from "../resources/style";
import Wizard from "./createCampaignScreen/wizard";

const CreateCampaign = function (props) {
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const [loading, set_loading] = useState(false);
  const [edit_campaign, set_edit_campaign] = useState(false);
  const [category_list, set_category_list] = useState(null);
  const [content_list, set_content_list] = useState(null);
  const [campaign_deep_data, set_campaign_deep_data] = useState({
    campaign_data: Campaign.createCampaignData(
      //Sdk.User.getUserId(redux_selector.UserData.user_data),
      Sdk.Brand.getBrandId(redux_selector?.UserData?.brand_deep_data)
    ),
    reward_data: Campaign.createRewardData(),
  });
  const [selected_platform, set_selected_platform] = useState({});
  const [selected_content, set_selected_content] = useState({});

  const { id } = useParams();
  const campaignId = useParams().campaignId;

  var navigate = useNavigate();
  var location = useLocation();

  var folower_range = [
    {
      title: "Below 5k",
      value: getSelectedFollowerRange("below_5k"),
      onChange: (value) =>
        //set_follower_range({ ...follower_range, below_5k: value }),

        set_campaign_deep_data(
          Campaign.updateRewardByFollowersData(
            campaign_deep_data,
            "below_5k",
            value
          )
        ),
      //set_campaign_deep_data({...campaign_deep_data, reward_data:{...campaign_deep_data['reward_data'], {...campaign_deep_data['reward_data']['reward_by_follower'], below_5k: value}}})
    },
    {
      title: "5K to 10K",
      value: getSelectedFollowerRange("above_5k_below_10k"),
      onChange: (value) =>
        set_campaign_deep_data(
          Campaign.updateRewardByFollowersData(
            campaign_deep_data,
            "above_5k_below_10k",
            value
          )
        ),
    },
    {
      title: "10K to 25K",
      value: getSelectedFollowerRange("above_10k_below_25k"),
      onChange: (value) =>
        set_campaign_deep_data(
          Campaign.updateRewardByFollowersData(
            campaign_deep_data,
            "above_10k_below_25k",
            value
          )
        ),
    },
    {
      title: "25K to 1M",
      value: getSelectedFollowerRange("above_25k_below_1m"),
      onChange: (value) =>
        set_campaign_deep_data(
          Campaign.updateRewardByFollowersData(
            campaign_deep_data,
            "above_25k_below_1m",
            value
          )
        ),
    },
  ];

  function getSelectedFollowerRange(key) {
    if (
      "reward_data" in campaign_deep_data &&
      "reward_by_follower" in campaign_deep_data["reward_data"] &&
      key in campaign_deep_data["reward_data"]["reward_by_follower"]
    ) {
      return campaign_deep_data["reward_data"]["reward_by_follower"][key];
    }
  }

  useEffect(() => {
    if (id != undefined && id != null) {
      getCampaignUserData();
    }
  }, []);

  useEffect(() => {
    getCampaignContent();
    getCategory();
  }, []);

  /*********************** Get Category API START ************************/

  function getCategory() {
    var params = {
      //influencer_data: influencer_data
    };
    Core.getCategory(
      getCategorySuccess,
      getCategoryFailed,
      updateProgress,
      params
    );
  }

  function getCategorySuccess(api_response) {
    set_category_list(Object.values(api_response["category_list"]));
  }

  function getCategoryFailed(error_list) {}

  /*********************** Get Category API End ************************/

  /*********************** Get Get Campaign Content API START ************************/

  function getCampaignContent() {
    var params = {};
    Core.getCampaignContent(
      getCampaignContentSuccess,
      getCampaignContentFailed,
      updateProgress,
      params
    );
  }

  function getCampaignContentSuccess(api_response) {
    set_content_list(api_response.content_data);
  }

  function getCampaignContentFailed(error_list) {}

  /*********************** Get Get Campaign Content API END ************************/

  function getCampaignUserData() {
    var params = {
      brand_id: Sdk.Brand.getBrandId(redux_selector.UserData.brand_deep_data),
      campaign_id: id,
    };
    Core.getCampaignUserData(
      getCampaignUserDataSuccess,
      getCampaignUserDataFailed,
      updateProgress,
      params,
      redux_selector.Auth.auth_token
    );
  }

  function getCampaignUserDataSuccess(response) {
    set_campaign_deep_data(response.campaign_data);
    set_edit_campaign(true);
  }

  function getCampaignUserDataFailed(error_list) {}

  /*********************** End Campaign Data API START ************************/

  /*********************** Create Campaign API START **************************/

  function createCampaignUser(progress_cb) {
    campaign_deep_data["contents_deep_data"] = Object.values(
      campaign_deep_data["contents_deep_data"]
    );

    var params = { campaign_deep_data: campaign_deep_data };

    Core.createCampaignUser(
      createCampaignUserSuccess,
      createCampaignUserFailed,
      progress_cb,
      params
      //redux_selector.Auth.auth_token
    );
  }

  function createCampaignUserSuccess(respopnce) {
    navigate("/campaigns");
  }

  function createCampaignUserFailed(error_list) {
    toast.error(Utils.getErrorMessage(error_list));
  }

  function updateProgress(status) {
    set_loading(status);
  }

  /************************ Create Campaign API END ***************************/
  function platformOnPressHandler(item, selected) {
    set_selected_platform((prev_state) => {
      if (selected) {
        return {
          ...prev_state,
          [item["platform_id"]]: item,
        };
      }
      var prev_state_new = JSON.parse(JSON.stringify(prev_state));
      delete prev_state_new[item["platform_id"]];
      return prev_state_new;
    });
  }

  function updateCampaignContent(item, selected) {
    set_campaign_deep_data((prev_state) => {
      if (selected) {
        return {
          ...prev_state,
          contents_deep_data: {
            ...prev_state.contents_deep_data,
            [item["content_data"]["content_id"]]: item.content_data,
          },
        };
      }
      prev_state =
        delete prev_state["contents_deep_data"][
          item["content_data"]["content_id"]
        ];
      return prev_state;
    });
  }

  function contentTypeOnpressHandler(item, selected) {
    set_campaign_deep_data((prev_state) => {
      if (selected) {
        return {
          ...prev_state,
          contents_deep_data: {
            ...prev_state.contents_deep_data,
            [item["content_data"]["content_id"]]: {
              content_data: {
                content_id: item["content_data"]["content_id"],
              },
            },
          },
        };
      }

      prev_state =
        delete prev_state["contents_deep_data"][
          item["content_data"]["content_id"]
        ];
      return prev_state;
    });
  }

  /********************* Campaign Name Start ************************/

  function updateCampaignName(name) {
    // var campaign_data = Campaign.updateCampaignName(campaign_deep_data, name)
    // set_campaign_deep_data({...campaign_data})

    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        campaign_data: {
          ...prev_state.campaign_data,
          name: name,
        },
      };
    });
  }

  function renderCampaignName() {
    return (
      <>
        <label>
          <CustomText
            p
            text="CAMPAIGNS NAME"
            fontSize={"font_size_large"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16" style={{ maxWidth: "330px" }}>
          <CustomTextInput
            text="name"
            placeholder="Name"
            value={campaign_deep_data.campaign_data.name}
            onChange={(e) => updateCampaignName(e.target.value)}
          />
        </div>
      </>
    );
  }

  /********************** Campaign Name End *************************/

  /********************* Campaign Type Start ************************/

  function updateCampaignType(type) {
    // var campaign_data = Campaign.updateCampaignType(campaign_deep_data, type)
    // set_campaign_deep_data({...campaign_data})
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        campaign_data: {
          ...prev_state.campaign_data,
          type: type,
        },
      };
    });
  }

  function renderCampaignType() {
    return (
      <div className="m-t-24">
        <label>
          <CustomText
            p
            text="CAMPAIGNS TYPE"
            fontSize={"font_size_large"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16">
          <RadioGroup
            onChange={(e) => updateCampaignType(e)}
            value={campaign_deep_data["campaign_data"]["type"]}
            horizontal
          >
            {Object.values(CampaignData.CAMPAIGN_TYPE).map((item, index) => {
              return (
                <RadioButton
                  rootColor="#dddddd"
                  pointColor="#6C25FF"
                  padding={12}
                  value={Object.keys(CampaignData.CAMPAIGN_TYPE)[index]}
                >
                  {item.n}
                </RadioButton>
              );
            })}
          </RadioGroup>
        </div>
      </div>
    );
  }

  /********************** Campaign Type End *************************/

  // Render Platform
  function renderPlaform() {
    if (campaign_deep_data.campaign_data.type === "social_media") {
      return (
        <div style={{ marginTop: "22px" }}>
          <CustomBox width="720px">
            <div
              style={{
                paddingLeft: "26px",
                padding: "18px",
                paddingRight: "26px",
              }}
            >
              <div>
                <CustomText
                  p
                  text="PLATFORMS"
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
              <div style={{ width: "380px" }}>
                {Object.values(Campaign.getCampaignPlatform(content_list)).map(
                  (item, index) => {
                    return (
                      <div className="full-width m-r-32 m-t-16">
                        <CustomCheckbox
                          onClick={(selected) => {
                            platformOnPressHandler(item, selected);
                          }}
                          selected={
                            //(id != null && id != undefined) &&
                            //Object.keys(campaign_deep_data['platforms_data'])[index] === Object.keys(CampaignData.PLATFORM)[index]
                            item["platform_id"] in selected_platform
                          }
                          //selected = {true}
                          inputType="checkbox"
                          title={item.name}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </CustomBox>
        </div>
      );
    }
  }

  // Render Platform specific content type
  function renderContentType() {
    // if(Array.isArray(campaign_deep_data.platforms_data)){
    //   var platformDATA =  campaign_deep_data['platforms_data'].reduce((obj, cur) => ({...obj, [cur.name]: cur}), {})
    //   campaign_deep_data['platforms_data'] = platformDATA
    // }

    // if(
    //   campaign_deep_data.contents_deep_data != undefined &&
    //   campaign_deep_data.contents_deep_data != null &&
    //   !Utils.isEmpty(campaign_deep_data.contents_deep_data)&&
    //   campaign_deep_data.campaign_data.type === 'social_media'
    // ){

    return (
      <div style={{ marginTop: "22px" }}>
        <CustomBox width="720px">
          <div
            style={{
              paddingLeft: "26px",
              padding: "18px",
              paddingRight: "26px",
            }}
          >
            <div>
              <CustomText
                p
                text="CONTENT TYPE"
                fontSize={"font_size_large"}
                textAlign={"text_align_left"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
            </div>
            <div style={{ width: "380px" }}>
              {Object.keys(selected_platform).map((platform_id, index) => {
                return Campaign.getPlatformContents(
                  platform_id,
                  content_list
                ).map((content_deep_data, i) => {
                  return (
                    <div className="full-width m-r-32 m-t-16">
                      <CustomCheckbox
                        //selected = {true}

                        onClick={(selected) => {
                          contentTypeOnpressHandler(
                            content_deep_data,
                            //Object.keys(Object.values(CampaignData['PLATFORM'])[index]['content_types'])[i],
                            selected
                          );
                        }}
                        selected={
                          // (id != null && id != undefined) &&
                          // campaign_deep_data['platforms_data'][item]['content_types'][i] === Object.keys(Object.values(CampaignData['PLATFM'])[index]['content_types'])[i]

                          content_deep_data["content_data"]["content_id"] in
                          campaign_deep_data["contents_deep_data"]
                        }
                        inputType="checkbox"
                        title={content_deep_data["content_data"]["name"]}
                      />
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </CustomBox>
      </div>
    );
  }

  /************************* Age Range Start *****************************/

  function updateAudienceAgeRange(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        audience_data: {
          ...prev_state.audience_data,
          age: value,
        },
      };
    });
  }

  function updateInfluencerAgeRange(selectedList, selectedItem) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        influencer_data: {
          ...prev_state.influencer_data,
          age: selectedItem.id,
        },
      };
    });
  }

  function getDropdownSelectedValue(selected_data, key, data_type) {
    var selected_value = [];

    if (
      "audience_data" in campaign_deep_data &&
      data_type == "audience" &&
      key in campaign_deep_data.audience_data
    ) {
      campaign_deep_data.audience_data[key].forEach((item) => {
        if (item in selected_data) {
          selected_value.push(selected_data[item]);
        }
      });
    } else if (
      "influencer_data" in campaign_deep_data &&
      key in campaign_deep_data.influencer_data
    ) {
      campaign_deep_data.influencer_data[key].forEach((item) => {
        if (item in selected_data) {
          selected_value.push(selected_data[item]);
        }
      });
    }

    return selected_value;
  }

  function renderInfluenderAgeRange() {
    return (
      <>
        <div className="m-t-16">
          <label>Age</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.AGE_RANGE)}
            onSelect={(selectedList, selectedItem) =>
              updateInfluencerAgeRange(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateInfluencerAgeRange(selectedList, selectedItem)
            }
            //selectedValues={ getDropdownSelectedValue(CampaignData.AGE_RANGE, 'age', data_type)}
            singleSelect={true}
            showArrow={false}
            displayValue={"n"}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  function renderAudienceAgeRange() {
    return (
      <>
        <div className="m-t-16">
          <label>Age</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.AGE_RANGE)}
            onSelect={(selectedList, selectedItem) =>
              updateAudienceAgeRange(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateAudienceAgeRange(selectedList, selectedItem)
            }
            //selectedValues={ getDropdownSelectedValue(CampaignData.AGE_RANGE, 'age', data_type)}
            singleSelect={false}
            showArrow={false}
            displayValue={"n"}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /************************* Age Range End ******************************/

  /************************ Age Gender Start ****************************/

  function updateGender(selectedList, selectedItem, data_type) {
    if (data_type === "audience") {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          audience_data: {
            ...prev_state.audience_data,
            gender: selectedItem.id,
          },
        };
      });
    } else {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            gender: selectedItem.id,
          },
        };
      });
    }
  }

  function renderGender(data_type, key) {
    return (
      <>
        <div className="m-t-16">
          <label>Gender</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.GENDER)}
            onSelect={(selectedList, selectedItem) =>
              updateGender(selectedList, selectedItem, data_type)
            }
            onRemove={(selectedList, selectedItem) =>
              updateGender(selectedList, selectedItem, data_type)
            }
            selectedValues={
              key in campaign_deep_data &&
              "gender" in campaign_deep_data[key] && [
                CampaignData.GENDER[campaign_deep_data[key]["gender"]],
              ]
            }
            singleSelect={true}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /************************* Age Gender End *****************************/

  /*************************** Gender Start *****************************/

  function updateLocation(selectedList, selectedItem, data_type) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    if (data_type === "audience") {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          audience_data: {
            ...prev_state.audience_data,
            state: value,
          },
        };
      });
    } else {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            state: value,
          },
        };
      });
    }
  }

  function renderLocation(data_type) {
    return (
      <>
        <div className="m-t-16">
          <label>Location</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.LOCATIONS)}
            onSelect={(selectedList, selectedItem) =>
              updateLocation(selectedList, selectedItem, data_type)
            }
            onRemove={(selectedList, selectedItem) =>
              updateLocation(selectedList, selectedItem, data_type)
            }
            selectedValues={getDropdownSelectedValue(
              CampaignData.LOCATIONS,
              "state",
              data_type
            )}
            singleSelect={false}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /**************************** Gender End ******************************/

  /*************************** Language Start *****************************/

  function updateLanguage(selectedList, selectedItem, data_type) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    if (data_type === "audience") {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          audience_data: {
            ...prev_state.audience_data,
            language: value,
          },
        };
      });
    } else {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            language: value,
          },
        };
      });
    }
  }

  function renderLanguage(data_type) {
    return (
      <>
        <div className="m-t-16">
          <label>Language</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.LANGUAGE)}
            onSelect={(selectedList, selectedItem) =>
              updateLanguage(selectedList, selectedItem, data_type)
            }
            onRemove={(selectedList, selectedItem) =>
              updateLanguage(selectedList, selectedItem, data_type)
            }
            selectedValues={getDropdownSelectedValue(
              CampaignData.LANGUAGE,
              "language",
              data_type
            )}
            singleSelect={false}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /*************************** Language End ******************************/

  /************************ Max Followers Start **************************/

  function updateMaxFollowers(selectedList, selectedItem, data_type) {
    if (data_type === "audience") {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          audience_data: {
            ...prev_state.audience_data,
            maximum_followers: selectedItem.value,
          },
        };
      });
    } else {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            maximum_followers: selectedItem.value,
          },
        };
      });
    }
  }

  function renderMaxFollowers(data_type) {
    return (
      <>
        <div className="m-t-16">
          <label>Maximum Followers</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.FOLLOWERS)}
            onSelect={(selectedList, selectedItem) =>
              updateMaxFollowers(selectedList, selectedItem, data_type)
            }
            onRemove={(selectedList, selectedItem) =>
              updateMaxFollowers(selectedList, selectedItem, data_type)
            }
            // selectedValues={
            // //'influencer_data' in campaign_deep_data &&
            // 'maximum_followers' in campaign_deep_data['influencer_data'] &&
            // [CampaignData.FOLLOWERS[campaign_deep_data['influencer_data']['maximum_followers']]]
            // }
            singleSelect={true}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /************************* Max Followers End ***************************/

  /************************ Min Followers Start **************************/

  function updateMinFollowers(selectedList, selectedItem, data_type) {
    if (data_type === "audience") {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          audience_data: {
            ...prev_state.audience_data,
            minimum_followers: selectedItem.value,
          },
        };
      });
    } else {
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            minimum_followers: selectedItem.value,
          },
        };
      });
    }
  }

  function renderMinFollowers(data_type) {
    return (
      <>
        <div className="m-t-16">
          <label>Minimum Followers</label>
        </div>

        <div className="m-t-16">
          <Multiselect
            options={Object.values(CampaignData.FOLLOWERS)}
            onSelect={(selectedList, selectedItem) =>
              updateMinFollowers(selectedList, selectedItem, data_type)
            }
            onRemove={(selectedList, selectedItem) =>
              updateMinFollowers(selectedList, selectedItem, data_type)
            }
            // selectedValues={
            //   'influencer_data' in campaign_deep_data &&
            //   'minimum_followers' in campaign_deep_data['influencer_data'] &&
            //   [CampaignData.FOLLOWERS[campaign_deep_data['influencer_data']['minimum_followers']]]
            // }
            singleSelect={true}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
            }}
          />
        </div>
      </>
    );
  }

  /************************* Min Followers End ***************************/

  /*************************** Catagory Start ****************************/
  function onSelectCatagories(selectedList, selectedItem, data_type) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.name);
    });
    var data;
    if (data_type === "audience") {
      data = Campaign.updateAudienceInterest(campaign_deep_data, value);
      set_campaign_deep_data({ ...data });
    } else {
      //data =  Campaign.updateInfluencerCatagories(campaign_deep_data, value)
      set_campaign_deep_data((prev_state) => {
        return {
          ...prev_state,
          influencer_data: {
            ...prev_state.influencer_data,
            categorys_data: selectedList,
          },
        };
      });
    }
  }

  function renderCatagories(data_type, key) {
    if (category_list != null) {
      return (
        <>
          <div className="m-t-16">
            <label>Catagories</label>
          </div>

          <div className="m-t-16">
            <Multiselect
              options={Object.values(category_list)}
              onSelect={(selectedList, selectedItem) =>
                onSelectCatagories(selectedList, selectedItem, data_type)
              }
              onRemove={(selectedList, selectedItem) =>
                onSelectCatagories(selectedList, selectedItem, data_type)
              }
              //selectedValues={getDropdownSelectedValue(CampaignData.CATEGORY, key, data_type)}
              singleSelect={false}
              displayValue={"name"}
              showArrow={false}
              style={{
                chips: Style.chips,
                option: Style.option,
              }}
            />
          </div>
        </>
      );
    }
  }

  function updateInterests(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        audience_data: {
          ...prev_state.audience_data,
          interests: value,
        },
      };
    });
  }

  function renderInterests() {
    if (category_list != null) {
      return (
        <>
          <div className="m-t-16">
            <label>Catagories</label>
          </div>

          <div className="m-t-16">
            <Multiselect
              options={Object.values(CampaignData.CATEGORY)}
              onSelect={(selectedList, selectedItem) =>
                updateInterests(selectedList, selectedItem)
              }
              onRemove={(selectedList, selectedItem) =>
                updateInterests(selectedList, selectedItem)
              }
              singleSelect={false}
              displayValue={"n"}
              showArrow={false}
              style={{
                chips: Style.chips,
                option: Style.option,
              }}
            />
          </div>
        </>
      );
    }
  }

  /**************************** Catagory End *****************************/

  function renderTargetAudience() {
    if (campaign_deep_data.campaign_data.type === "social_media") {
      return (
        <div style={{ marginTop: "22px" }}>
          <CustomBox width="720px">
            <div
              style={{
                paddingLeft: "26px",
                padding: "18px",
                paddingRight: "26px",
              }}
            >
              <div>
                <CustomText
                  p
                  text="TARGET AUDIENCE"
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>

              <div style={{ width: "380px" }}>
                <div
                  className="full-width m-r-32"
                  style={{
                    fontFamily: Theme.fonts.REGULAR,
                    fontWeight: "normal",
                  }}
                >
                  {renderAudienceAgeRange()}
                  {renderGender("audience", "audience_data")}
                  {renderLocation("audience")}
                  {renderLanguage("audience")}
                  {renderInterests()}
                </div>
              </div>
            </div>
          </CustomBox>
        </div>
      );
    }
  }

  function renderTargetInfluencer() {
    return (
      <div style={{ marginTop: "22px" }}>
        <CustomBox width="720px">
          <div
            style={{
              paddingLeft: "26px",
              padding: "18px",
              paddingRight: "26px",
            }}
          >
            <div>
              <CustomText
                p
                text="TARGET CREATOR"
                fontSize={"font_size_large"}
                textAlign={"text_align_left"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
            </div>

            <div style={{ width: "380px" }}>
              <div
                className="full-width m-r-32"
                style={{
                  fontFamily: Theme.fonts.REGULAR,
                  fontWeight: "normal",
                }}
              >
                {renderInfluenderAgeRange()}
                {renderGender("influencer", "influencer_data")}
                {renderLocation("influencer")}
                {renderLanguage("influencer")}
                {renderMaxFollowers("influencer")}
                {renderMinFollowers("influencer")}
                {renderCatagories("influencer", "category")}
              </div>
            </div>
          </div>
        </CustomBox>
      </div>
    );
  }

  /************************ Reward Data START **************************/

  function updateRewardTypeData(reward_type) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        reward_data: {
          ...prev_state.reward_data,
          type: reward_type,
        },
      };
    });
  }

  function renderRewardType() {
    return (
      <>
        <div className="m-t-16">
          <label>
            <CustomText
              p
              text="REWARD TYPE"
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
            />
          </label>
        </div>

        <div className="m-t-16 ">
          <RadioGroup
            onChange={(e) => updateRewardTypeData(e)}
            horizontal
            value={campaign_deep_data.reward_data["type"]}
            //  pointColor="red"
            //={"red"}
          >
            {Object.values(CampaignData.REWARD_TYPE).map((item, index) => {
              return (
                <RadioButton
                  rootColor="#dddddd"
                  pointColor="#6C25FF"
                  padding={12}
                  value={Object.keys(CampaignData.REWARD_TYPE)[index]}
                >
                  {item.n}
                </RadioButton>
              );
            })}
          </RadioGroup>
        </div>
      </>
    );
  }

  /************************ Reward Data END ****************************/

  /********************* Campaign Budget START *************************/

  function updateCampaignBudget(budget) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        campaign_data: {
          ...prev_state.campaign_data,
          max_budget: +budget,
        },
      };
    });
  }

  function renderMaxBudget() {
    return (
      <div className="m-t-16">
        <label>
          <CustomText
            p
            text="MAX BUDGET"
            fontSize={"font_size_normal"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16" style={{ width: "218px" }}>
          <CustomTextInput
            placeholder="INR"
            onChange={(e) => updateCampaignBudget(e.target.value)}
            value={campaign_deep_data.campaign_data.max_budget}
          />
        </div>
      </div>
    );
  }

  /********************** Campaign Budget END **************************/

  function updateCampaignEndDate(date) {
    var unixTime = Sdk.Campaign.getDateString(date);
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        campaign_data: {
          ...prev_state.campaign_data,
          end_date: unixTime,
        },
      };
    });
  }

  function updateCampaignDeliveryDate(date) {
    var unixTime = Sdk.Campaign.getDateString(date);
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        campaign_data: {
          ...prev_state.campaign_data,
          delivery_date: unixTime,
        },
      };
    });
  }

  function renderFollowerRange() {
    return folower_range.map((item, index) => {
      return (
        <div
          className="m-t-16"
          style={{
            width: "607px",
            height: "50px",
            padding: "14px",
            border: "1px solid #70707048",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "400px" }}>
            <CustomText
              p
              text={item.title}
              fontSize={"font_size_normal"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
            />
          </div>
          <div style={{ width: "218px" }}>
            <CustomTextInput
              placeholder="INR"
              onChange={(e) => item.onChange(e.target.value)}
              value={item.value}
            />
          </div>
        </div>
      );
    });
  }

  /********************** INSTRUCTIONS DATA START *************************/

  function updateInstructionNoteData(instruction) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        instruction_data: {
          ...prev_state.instruction_data,
          instruction_note: instruction,
        },
      };
    });
  }

  function renderInstructionNote() {
    return (
      <div className="m-t-16" style={{ width: "613px" }}>
        <label>
          <CustomText
            p
            text={"INSTRUCTIONS FOR CREATOR"}
            fontSize={"font_size_normal"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16">
          <CustomTextArea
            placeholder={"INSTRUCTIONS FOR CREATOR"}
            onChange={(e) => updateInstructionNoteData(e.target.value)}
            value={
              edit_campaign
                ? Sdk.Campaign.getCampaignInstructionNote(campaign_deep_data)
                : null
            }
          />
        </div>
      </div>
    );
  }

  function updateDoData(do_data) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        instruction_data: {
          ...prev_state.instruction_data,
          do: do_data,
        },
      };
    });
  }

  function renderDoNote() {
    return (
      <div className="m-t-16" style={{ width: "613px" }}>
        <label>
          <CustomText
            p
            text={`Do's`}
            fontSize={"font_size_normal"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16">
          <CustomTextArea
            placeholder={`Do's`}
            onChange={(e) => updateDoData(e.target.value)}
            value={
              edit_campaign
                ? Sdk.Campaign.getCampaignInstructionDo(campaign_deep_data)
                : null
            }
          />
        </div>
      </div>
    );
  }

  function updateDoNotData(do_not_data) {
    set_campaign_deep_data((prev_state) => {
      return {
        ...prev_state,
        instruction_data: {
          ...prev_state.instruction_data,
          do_not: do_not_data,
        },
      };
    });
  }

  function renderDoNotNote() {
    return (
      <div className="m-t-16" style={{ width: "613px" }}>
        <label>
          <CustomText
            p
            text={`Don'ts`}
            fontSize={"font_size_normal"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
        </label>
        <div className="m-t-16">
          <CustomTextArea
            placeholder={`Don'ts`}
            onChange={(e) => updateDoNotData(e.target.value)}
            value={
              edit_campaign
                ? Sdk.Campaign.getCampaignInstructionDoNot(campaign_deep_data)
                : null
            }
          />
        </div>
      </div>
    );
  }

  /*********************** INSTRUCTIONS DATA END* *************************/

  return (
    <div className="dashboard-container background-color-primary-create-campaign">
      <SideMenu />
      <div className="main-screen-create-campaign">
        <Header />

        <div className="dashboard-maincontainer-create-campaign">
          <Wizard
            campaignId={campaignId}
            campaignDeepData={campaign_deep_data}
            setCampaignDeepData={set_campaign_deep_data}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCampaign;
