import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import Modal from "react-modal";
import { useSnackbar } from "react-simple-snackbar";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import {
  ConfirmationDialog,
  CounterProposal,
  CustomButton,
  CustomText,
  CustomTextInput,
  FallBack,
  FallBackBtn,
  Image,
  LinkCta,
  Popup,
  PrimaryCta,
  SearchFallBack,
  Sidebar,
} from "../../components";
import Icon from "../../components/core_component/icon";
import { Loader } from "../loader";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Sdk from "../../common/core/module/index.js";
import * as CampaignData from "../../common/data/campaign";
import exportToCSV from "../../common/exportToCSV";
import WebStorage from "../../common/web_storage";
import CustomTable from "../../components/customTable";
import * as Actions from "../../redux/actions";
import Style from "../../resources/style";
import CreateCollectionGrid from "../collection/collectionGrid.js";
import CreateCollectionList from "../collection/collectionList.js";
import InfluencerDetailModal from "../../modal/influencerDetialModal.js";
import { RepostJobOptionModal } from "../../modal/repostJobOption.js";
import CreateCampaignCollection from "./createCampaignCollection.js";
import { useToast } from "../../features/toastContainer.js";

const followers = [
  { value: "1000", label: "Nano    1K - 10k (min Rs 1k)" },
  { value: "10000", label: "Micro  10k - 50k (min Rs 5k)" },
  { value: "50000", label: "Mid Tier  50k - 2.5L (min Rs 10k)" },
  { value: "250000", label: "Macro  2.5L - 10L (min Rs 30k)" },
  { value: "1000000", label: "Top Tier  1M+  (min Rs 50k)" },
];

const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const counterOption = [
  { value: 0, label: "Yes" },
  { value: 1, label: "No" },
  { value: "all", label: "All" },
];

const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },

  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      fontWeight: 400,
      lineHight: "19.2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => row.lastCol,
    style: {
      justifyContent: "right",
    },
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    paddingLeft: 40,
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    minWidth: 352,
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const limit = 10;
function BidListScreen(props) {
  const [modalIsOpenCollection, setModalIsOpenCollection] = useState(false);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [bidList, setBidList] = useState([]);
  const [bidListSort, setBidListSort] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [isAppLoading, setAppLoading] = useState(true);
  const [start, setStart] = useState(null);
  const [next, setNext] = useState(0);
  const [count, setCount] = useState(0);
  const [bidApproved, setBidApproved] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [influencerId, setInfluencerId] = useState("");
  const [openSnackbar] = useSnackbar();
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [approveDialog, setApproveDialog] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [approveCampaign, setApproveCampaign] = useState({});
  const [categoryCard, setCategoryCard] = useState({});
  const [bidId, setBidId] = useState();
  const [bidValue, setBidValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [filterLabelMatch, setFilterLabelMatch] = useState("");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [filterDropDownMatch, setFilterDropDownMatch] = useState(false);
  const [influencerList, setInfluencerList] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [balance, setBalance] = useState("");
  const [balanceDialog, setBalanceDialog] = useState(false);
  const [counterModal, setCounterModal] = useState(false);
  const [counterOffer, setCounterOffer] = useState("");
  const [isLoadingCounter, setLoadingCounter] = useState(false);
  const [topMatchBidList, setTopMatchBidList] = useState([]);
  const [bidListFilter, setBidListFilter] = useState(false);
  const [minFollower, setMinFollower] = useState([]);
  const [category, setCategory] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [influencerGender, setInfluencerGender] = useState([]);
  const [counterCheck, setCounterCheck] = useState([]);
  const [filteredBidList, setFilteredBidList] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const currentUnixDate = Sdk.Campaign.getDateString(new Date());
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [showCollectionBtn, setShowCollectionBtn] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [collectionId, setCollectionId] = useState([]);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [collectionLoader, setCollectionLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [repostModal, setRepostModal] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [profession, setProfession] = useState([]);
  const [approvePopupOpen, setApprovePopupOpen] = useState(false);
  const [proposalLoading, setProposalLoading] = useState(false);
  const [counterPopupOpen, setCounterPopupOpen] = useState(false);
  const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [shortlistPopupOpen, setShortlistPopupOpen] = useState(false);
  const [contractPolicySidebarOpen, setContractPolicySidebarOpen] =
    useState(false);

  const showToast = useToast();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // If screen width is less than or equal to 768px, consider it mobile
  };

  useEffect(() => {
    // Add an event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log("unixDateToday", currentUnixDate);

  const updateDeliveryDate = (date) => {
    var unixDate = Sdk.Campaign.getDateString(date);

    setDeliveryDate(unixDate);
  };

  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    collectionId: [],
    counter: null,
  });

  const filterTopMatch = [
    {
      name: "Show All",
      onClick: (name) => {
        setFilterLabelMatch(name);
        setTopMatchBidList(null);
        setFilterDropDownMatch(!filterDropDownMatch);
      },
    },
    {
      name: "Top Match",
      onClick: (name) => {
        TopMatchHandler(name);
        setFilterDropDownMatch(!filterDropDownMatch);
      },
    },
  ];

  const sortTypes = [
    {
      sortBy: "bid_value",
      sortOrder: "asc",
      label: "Amount - Low to High",
    },
    {
      sortBy: "bid_value",
      sortOrder: "desc",
      label: "Amount - High to Low",
    },
    {
      sortBy: "followers",
      sortOrder: "asc",
      label: "Followers - Low to High",
    },
    {
      sortBy: "followers",
      sortOrder: "desc",
      label: "Followers - High to Low",
    },
  ];

  const campaignId = props.campaignId;
  var redux_dispatch = useDispatch();
  const navigate = useNavigate();

  const { campaignDetail = {} } = props.campaignDetail;
  const productPrice = props.campaignDetail?.campaign_data?.product_price;
  const collabType = props.campaignDetail?.campaign_data?.collab_type;
  const campaignStatus = props.campaignDetail?.campaign_data?.status;
  const storageKeyDate = `ASSIGNMENT_DELIVERY_DATE_${campaignId}`;
  const LocalDeliveryDate = WebStorage.getDataFromWebStorage(storageKeyDate);
  const genderLocation = props?.campaignDetail?.audience_data?.state;
  const genderFilter = props.campaignDetail?.audience_data?.gender;

  const categoryId =
    props.campaignDetail?.influencer_data &&
    Object.keys(props.campaignDetail?.influencer_data?.categories);
  const [totalRows, setTotalRows] = useState(null);
  // const youtubeId =
  //   campaignDetail.content_type_deep_data[0]?.platform_data?.platform_id;

  const { content_type_deep_data = {} } = props.campaignDetail;
  const platformId = content_type_deep_data[0]?.platform_data?.platform_id;

  const storageKey = `ASSIGNMENT_DELIVERY_DATE_${campaignId}`;
  console.log("storageKey", storageKey);

  // WebStorage.saveDataInWebStorage(storageKey);

  useEffect(() => {
    if (campaignStatus !== "COMPLETED" && bidApproved !== null) {
      getBidList(start, updateProgress);
    }

    DeliveryDateSet();
  }, [bidApproved]);

  useEffect(() => {
    if (collectionId && collectionId?.length == 1) {
      getCollectionInfluencerList();
    }
  }, [collectionId]);

  function updateProgress(status) {
    setLoading(status);
  }

  function contractPolicy() {
    window.open("/contract-policy", "_blank");
  }

  // useEffect(() => {
  //   if (bidList.length != 0) {
  //     setFilteredBidList(filterBidList());
  //   }
  // }, [filterData]);

  useEffect(() => {
    if (campaignStatus !== "COMPLETED") {
      getOrgDataUser();
      getBidList();
      getCollectionList();
    } else {
      setAppLoading(false);
      setTotalRows(0);
    }
  }, []);

  useEffect(() => {
    let counterList = bidListSort.filter(
      (item) => item?.bid_data?.is_counter == 1
    );
    let counterListReject = bidListSort.filter(
      (item) => item?.bid_data?.is_counter == 2
    );

    let notCounterList = bidListSort.filter(
      (item) =>
        item?.bid_data?.is_counter !== 1 && item?.bid_data?.is_counter !== 2
    );

    setBidList([...counterList, ...counterListReject, ...notCounterList]);
  }, [bidListSort]);

  useEffect(() => {
    if (searchName !== null) {
      getBidList();
    }
  }, [searchName]);

  useEffect(() => {
    if (campaignStatus !== "COMPLETED" && sortBy) {
      getBidList();
    } else {
      setAppLoading(false);
    }
  }, [sortBy, sortOrder]);

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { all_permissions = {} } = UserData;

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };
  function filterBidList() {
    const filteredBidListNewData = bidList?.filter((bid) => {
      const influencerData = bid.influencer_deep_data.influencer_data;
      const platformsData = bid.influencer_deep_data.platforms_data;
      const categorysData = bid.influencer_deep_data.categorys_data;
      const bidData = bid.bid_data;
      // console.log("data", influencerData);
      // console.log("data1", platformsData, );

      // Check gender
      if (
        filterData.gender !== "all" &&
        filterData.gender &&
        influencerData.gender !== filterData.gender
      ) {
        return false;
      }

      if (
        filterData.counter !== "all" &&
        filterData.counter &&
        bidData.is_counter >= filterData.counter
      ) {
        return false;
      }

      if (
        filterData.counter !== "all" &&
        filterData.counter == 0 &&
        !bidData.is_counter
      ) {
        return false;
      }

      // Check city
      if (
        filterData?.city?.length > 0 &&
        !filterData.city.includes(Number(influencerData?.city_id))
      ) {
        return false;
      }

      // Check state
      if (
        filterData.state.length > 0 &&
        !filterData.state.includes(Number(influencerData.state_id))
      ) {
        return false;
      }

      // Check follower count
      // Check follower count

      if (filterData.follower.length > 0) {
        let maxFollowers = [];
        let minFollowers = [];

        filterData.follower.forEach((item) => {
          if (item == 1000) {
            minFollowers.push(1000);
            maxFollowers.push(10000);
          }
          if (item == 10000) {
            minFollowers.push(10000);
            maxFollowers.push(50000);
          }
          if (item == 50000) {
            minFollowers.push(50000);
            maxFollowers.push(Infinity);
          }
        });

        //  let isFollowers =
        let maxFollowersData = maxFollowers.some(
          (item) => item > platformsData[0]["followers"]
        );
        let minFollowerData = minFollowers.some(
          (item) => item > platformsData[0]["followers"]
        );

        return minFollowerData < maxFollowersData;
        // if (!isFollowers) {
        //   return false;
        // }
      }

      // Check category
      if (filterData.category.length > 0) {
        const influencerCategories = Object.keys(categorysData).every(
          (category) => !filterData.category.includes(category)
        );
        if (influencerCategories) {
          return false;
        }
      }

      if (
        filterData.collectionId ||
        collectionInfluencerList?.collection_influencer_list?.length > 0
      ) {
        const filterCollectionList =
          collectionInfluencerList?.collection_influencer_list.every(
            (item) =>
              !item?.influencer_id.includes(influencerData.influencer_id)
          );

        if (filterCollectionList) {
          return false;
        }
      }

      return true;
    });
    if (filteredBidListNewData.length == 0) {
      setIsFilterApplied(true);
    }
    return filteredBidListNewData;
  }

  function allBidListData() {
    if (filteredBidList?.length > 0 || isFilterApplied) {
      return filteredBidList;
    } else if (topMatchBidList && topMatchBidList?.length > 0) {
      return topMatchBidList;
    }
    return bidList;
  }

  function validationDeliveryDate() {
    if (currentUnixDate + 86400 > deliveryDate) {
      toast.info("End date should be later than today");
      return false;
    }

    return true;
  }

  // delivery date set
  function DeliveryDateSet() {
    if (currentUnixDate) {
      setDeliveryDate(currentUnixDate);
    } else {
      if (collabType == "BARTER") {
        setDeliveryDate(currentUnixDate + 604800);
      }
      if (collabType == "AUCTION") {
        setDeliveryDate(currentUnixDate + 432000);
      }
    }
  }

  function clearFilterFun() {
    setMinFollower([]);
    setCategory([]);
    setCity([]);
    setState([]);
    setInfluencerGender([]);
    setCounterCheck([]);

    setFilterData({
      gender: null,
      city: [],
      state: [],
      follower: [],
      category: [],
      counter: null,
    });

    setIsFilterApplied(false);
    setFilteredBidList([]);
  }

  function getCollectionList() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
      campaign_id: campaignId,
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    console.log("props clol ", response_data);

    setCollectionList(response_data?.collection_list);
    if (response_data.collection_list.length > 0) {
      setSelectedCollectionId(response_data.collection_list[0].collection_id);
    }
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCollectionInfluencerList() {
    const params = {
      collection_id: collectionId[0]?.collection_id,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (status) => setCollectionLoader(status),
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data.collection_deep_data);
  }
  function getCollectionInfluencerListFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getInfluencerList() {
    var params = {
      campaign_id: campaignId,
    };
    Core.getInfluencerList(
      getInfluencerListSuccess,
      getInfluencerListFailed,
      (status) => setAppLoading(status),
      params
    );
  }

  function getInfluencerListSuccess(response_data) {
    setInfluencerList(response_data?.invite_list);
  }

  function getInfluencerListFailed(errorList) {
    openSnackbar(errorList);
  }

  function getBidList(page) {
    setIsLoading(true);
    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 10 * Number(page) - 10;
    }
    let filter_data = {
      location: filterData.state,
      gender: filterData.gender ? filterData.gender : "all",
      profession: filterData.profession,
      is_invited: filterData?.invite_mark?.toString(),
    };
    var params = {
      campaign_id: campaignId,
      // limit: limit,
      start: isNaN(newPage) ? 0 : newPage,
      filter_data: JSON.stringify(filter_data),
    };
    if (searchName != null) {
      params.search = searchName;
    }
    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    // if (sortBy && sortOrder) {
    //   (params.sort_order = sortOrder), (params.sort_by = sortBy);
    // }
    Core.getBidList(
      getBidListSuccess,
      getBidListFailed,
      updateProgress,
      params
    );
  }

  function getBidListSuccess(response_data) {
    setBidListSort(response_data["bid_list"]);

    setTotalRows(response_data?.pagination_data?.total_count);
    setNext(response_data?.pagination_data?.next);
    // setLoading(false);
    setAppLoading(false);
    setIsLoading(false);
  }

  function getBidListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const handlePageChange = (page) => {
    getBidList(page);
  };
  function updateProgressProposalList(status) {
    setProposalLoading(status);
    setApproveCampaign((prevState) => ({ ...prevState, [bidId]: status }));
  }
  function approveBidUser() {
    if (!validationDeliveryDate()) {
      return false;
    }
    var params = {
      bid_id: bidId,
      assignment_delivery_date: deliveryDate,
      campaign_id: campaignId,
    };

    Core.approveBidUser(
      getApproveBidUserSuccess,
      getApproveBidUserFailed,
      updateProgressProposalList,
      params
    );
    handleCancel();
  }

  function getApproveBidUserSuccess(response_data) {
    openSnackbar("Proposal Accepted ");
    setApprovePopupOpen(false);
    setProposalLoading(false);
    getBidList();
  }

  function getApproveBidUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgressReject(status) {
    setRejectLoading(status);
  }
  function postRejectBidUser() {
    rejectBidInArray();
    var params = {
      bid_id: bidId,
    };
    Core.postRejectBidUser(
      rejectBidUserSuccess,
      rejectBidUserFailed,
      updateProgressReject,
      params
    );
    handleCancel();
  }

  function rejectBidUserSuccess(response_data) {
    toast.info("Proposal Rejected");
    setRejectPopupOpen(false);
    setRejectLoading(false);
  }

  function rejectBidUserFailed(errorList) {
    toast.error(errorList);
  }

  function getOrgDataUser() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      () => {},
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setBalance(response_data?.org_data?.org_data?.balance);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList);
  }

  function counterProposal() {
    const params = {
      bid_id: bidId,
      counter_value: Utils.convertToINR(counterOffer, props.user_data.country),
    };
    if (validation())
      Core.counterProposal(
        counterProposalSuccess,
        counterProposalFailed,
        (status) => setLoadingCounter(status),
        params
      );
  }

  function counterProposalSuccess(response_data) {
    toast.success("counter bid send successfully");
    let index = bidList.findIndex((item) => item.bid_data.bid_id == bidId);

    setBidList((prev_value) => {
      prev_value[index]["bid_data"]["counter_value"] = Utils.convertToINR(
        counterOffer,
        props.user_data.country
      );

      return prev_value;
    });
    setCounterPopupOpen(false);
    userSelfData();

    // getBidList(start, updateProgress);
  }

  function counterProposalFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setCounterModal(!counterModal);
  }

  function userSelfData() {
    var params = { org_id: brand_deep_data.brand_data.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response_data) {
    // update create
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });
  }

  function userSelfDataFailed(errorList) {
    toast.error(errorList);
  }

  function rejectBidInArray() {
    setBidList((prevData) =>
      prevData.filter((item) => item.bid_data.bid_id !== bidId)
    );
    if (topMatchBidList) {
      setTopMatchBidList((prevData) =>
        prevData.filter((item) => item.bid_data.bid_id !== bidId)
      );
    }
  }

  function TopMatchHandler(name) {
    setFilterLabelMatch(name);
    let topMatchArray = [];

    let CampaignCategory = Object.keys(
      campaignDetail?.influencer_data?.categories
    );

    bidList.forEach((item) => {
      CampaignCategory.forEach((data) => {
        if (data in item.influencer_deep_data.categorys_data) {
          topMatchArray.push(item);
        }
      });
    });

    setTopMatchBidList(topMatchArray);
  }

  function validation() {
    if (
      Utils.convertToINR(Number(counterOffer), props?.user_data?.country) < 500
    ) {
      toast.info(
        `Counter offer not less then ${Utils.convertFromINR(
          500,
          props?.user_data?.country
        )}`
      );
      return false;
    }

    return true;
  }

  function counterOpen(id) {
    if (
      Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["proposals"]
      )
    ) {
      showToast();
      //    toast.error("You are not authorised to counter");
    } else {
      setCounterPopupOpen(true);
      setBidId(id);
      setCounterModal(!counterModal);
    }
  }

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === platformId);
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementRate = (platforms_data) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    var eng_data = null;

    var eng_rate = null;
    if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
      eng_data =
        Number(selectedPlatformData?.avg_likes) +
        Number(selectedPlatformData.avg_comments);

      eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
    }

    return eng_rate?.toFixed(1);
  };

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }
  function SortMatchDropDown() {
    if (filterDropDownMatch) {
      setFilterDropDownMatch(false);
    } else {
      setFilterDropDownMatch(true);
    }
    TopMatchHandler();
  }

  function filterDropDownOpenClose() {
    if ((sortBy != null) & (sortOrder != null)) {
      setSortBy(null);
      setSortOrder(null);
      setFilterLabel(null);
    } else {
      SortDropDown();
    }
  }
  function filterDropDownOpenCloseMatch() {
    if (filterLabelMatch != null) {
      setFilterLabelMatch(null);
    } else {
      SortMatchDropDown();
    }
  }

  function SearchBarActive() {
    if (searchActive) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
    }
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const openModalRepost = () => {
    if (repostModal == false) {
      setRepostModal(true);
    } else {
      setRepostModal(false);
    }
  };

  const closeModalRepost = () => {
    setRepostModal(false);
  };

  function balanceValidation(value) {
    openModal();
    // if (
    //   Utils.checkRoleAndPermission(
    //     redux_selector,
    //     all_permissions["campaign"]["permissions"]["proposals"]
    //   )
    // ) {
    //   toast.info("You are not authorised to accept proposal");
    // } else {
    // }
  }

  function openModalCollection() {
    if (modalIsOpenCollection == false) {
      setModalIsOpenCollection(true);
    } else {
      setModalIsOpenCollection(false);
    }
  }

  function closeModalCollection() {
    setModalIsOpenCollection(false);
  }
  function openModalCollectionGrid() {
    if (modalIsOpenCollectionGrid == false) {
      setModalIsOpenCollectionGrid(true);
    } else {
      setModalIsOpenCollectionGrid(false);
    }
  }

  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  const handleMouseEnterCollection = (id) => {
    setIsHovered(true);
  };

  const handleMouseLeaveCollection = () => {
    setIsHovered(false);
  };

  // function influencerDataFilter() {
  //   //const filterDataPush = [];
  //   console.log("filterData.gender", filterData.gender);

  //   let filterDataPush = bidList.filter((item) => {
  //     const influencerData = item.influencer_deep_data.influencer_data;
  //     const platformsData = item.influencer_deep_data.platforms_data;
  //     const categoryData = item.influencer_deep_data.categorys_data;

  //     const genderMatch = filterData.gender
  //       ? influencerData.gender == filterData.gender
  //       : true;
  //     const cityMatch = filterData.city
  //       ? influencerData.city_id == Number(filterData.city)
  //       : true;
  //     const stateMatch = filterData.city
  //       ? influencerData.state_id == Number(filterData.state)
  //       : true;

  //     const followersMatch = filterData.follower
  //       ? platformsData.some(
  //           (platform) => platform.followers >= filterData.follower
  //         )
  //       : true;

  //     const categoryMatch = filterData.category
  //       ? categoryData.hasOwnProperty(filterData.category)
  //       : true;

  //     console.log("categoryMatch", categoryMatch);

  //     return genderMatch && cityMatch && followersMatch && stateMatch;
  //     // console.log("item", item.influencer_deep_data.influencer_data.city_id)
  //     // filterData.gender.includes(
  //     //   item.influencer_deep_data.influencer_data.gender
  //     // );

  //     // if (filterData.city) {
  //     //   filterData.city.includes(
  //     //     Number(item.influencer_deep_data.influencer_data.city_id)
  //     //   );
  //     // }
  //   });

  //   console.log("genderData", filterDataPush);
  //   // bidList.filter((item) => {
  //   //   const influencer = item?.influencer_deep_data;

  //   //   // console.log("influencer.city_id", influencer.city_id);

  //   //   // let cityMatch = influencer.city_id;
  //   //   // const followersMatch =
  //   //   //   !followers ||
  //   //   //   influencer.platforms_data.some(
  //   //   //     (platform) => platform.followers >= followers
  //   //   //   );
  //   //   // console.log(
  //   //   //   " filterData.city.includes(cityMatch)",
  //   //   //   filterData.city.includes(cityMatch)
  //   //   // );
  //   //   // if (filterData.city) {
  //   //   //   filterData.city.filter((item) => {
  //   //   //     console.log("itemitem", item.includes(influen cer.city_id));
  //   //   //   });
  //   //   // }

  //   //   // filterData.category.forEach((data) => {
  //   //   //   console.log("data", data);
  //   //   //   if (data in item.influencer_deep_data.categorys_data) {
  //   //   //     console.log("llll", item);
  //   //   //     filterDataPush.push(item);
  //   //   //   }
  //   //   // });

  //   //   // if (filterData.city) {
  //   //   //   cityMatch = filterData.city.includes(influencer.city_id);
  //   //   // }

  //   //   // const genderMatch = influencerGender === influencer.gender;
  //   //   // const stateMatch = state === influencer.state_id;
  //   //   // //  console.log("cityMatch", cityMatch);

  //   //   // if (cityMatch || genderMatch || stateMatch) {
  //   //   //   filterDataPush.push(item);
  //   //   // }

  //   //   console.log("followersMatch", followersMatch);
  //   //   filterDataPush.push(followersMatch);
  //   // });

  //   setTopMatchBidList(filterDataPush);
  // }

  function BidListFilterOpen() {
    setBidListFilter(true);
  }
  function BidListFilterClose() {
    setBidListFilter(false);
  }

  function getLocations(locationsData, location) {
    let result = [];
    let specifiedStates = {};
    let includedCities = {};
    console.log("locationsData", locationsData);
    console.log("location", typeof location);

    if (!location || location?.length == 0) {
      return locationsData;
    }

    // Identify specified states and cities
    location.forEach((locId) => {
      let item = locationsData.find((x) => x.id.toString() == locId);
      if (item) {
        if (item.type == "state") {
          specifiedStates[item.id] = true;
        } else if (item.type == "city") {
          includedCities[item.id] = true;
        }
      }
    });

    // Add all cities that belong to the specified states and the states themselves
    locationsData.forEach((item) => {
      if (item.type == "state" && specifiedStates[item.id]) {
        // Check if there are no specific cities selected for this state
        let citiesInStateSelected = location.some((locId) => {
          let city = locationsData.find(
            (x) => x.id.toString() == locId && x.type === "city"
          );
          return city && city.state_id == item.id;
        });

        if (!citiesInStateSelected) {
          result.push(item); // Add the state if no cities in it are specifically selected
        }
      } else if (
        item.type == "city" &&
        (includedCities[item.id] || specifiedStates[item.state_id])
      ) {
        if (!result.find((r) => r.id === item.id)) {
          // Check for duplicates
          result.push(item);
        }
      }
    });

    return result;
  }
  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        state: value,
      };
    });

    setState(selectedList);
  }

  function updateProfession(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        profession: value,
      };
    });

    setProfession(selectedList);
  }

  function updateCity(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        city: value,
      };
    });

    setCity(selectedList);
  }
  function updateCategory(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        category: value,
      };
    });
    setCategory(selectedList);
  }
  function updateMinFollower(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        follower: value,
      };
    });

    setMinFollower(selectedList);
  }

  function updateGender(selectedItem) {
    setInfluencerGender(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          gender: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }
  function updateCollection(selectedItem) {
    setCollectionId(selectedItem);
    setFilterData((prev) => {
      return {
        ...prev,
        collectionId: selectedItem ? selectedItem[0] : [],
      };
    });
  }
  function updateCounterFilter(selectedItem) {
    setCounterCheck(selectedItem);

    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          counter: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function FilterOutSubCategory() {
    let subCateDataList = [];
    if (categoryId) {
      subCateDataList = categoryId.flatMap(
        (item) => CampaignData.FULL_CATEGORIES.subcategories[item]
      );
    }

    return subCateDataList;
  }

  const handleCancel = () => {
    setApproveDialog(false);
    setRejectDialog(false);
    setBalanceDialog(false);
  };

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const platformData = platforms_data?.find(
      (item) => item?.platform_id === platformId
    );

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data?.length).fill(false));
    };

    return (
      <>
        {platforms_data?.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData?.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
          {/* {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />
                  </div>
                </>
              );
            }
            return null;
          })} */}
        </div>
      </>
    );
  };

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );
    return inviteId;
  }

  let getId = influencerInviteList?.find(
    (item) => item?.influencer_id == "1l4ftw4q5ymwl5h19neu6xutt"
  );

  // let sendId = influencerList?.filter((item2) =>
  //   data.includes(item2.influencer_data.influencer_id)
  // );

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data) {
      if (influencer_data["city_id"]) {
        return CampaignData.CITY.find(
          (city) => city.id == influencer_data["city_id"]
        )?.label;
      } else if (influencer_data["state"]) {
        return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
      } else {
        return "-";
      }
    }
  }

  function hoveredButton() {
    return (
      <button
        onClick={() => {
          setShortlistPopupOpen(true);
          setShowCollectionBtn(false);
          console.log("button clicked");
        }}
        className={`button-hover-show-btn ${
          showCollectionBtn ? "hidden-hover-show-btn" : ""
        }`}
      >
        Shortlist
      </button>
    );
  }

  const tableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_deep_data"]["influencer_data"]["name"],
      sortable: true,
      width: "220px",
      cell: (data) => (
        <span
          className="container-hover-show-btn"
          onClick={(e) => {
            toggleFlyoutDropdown();
            setInfluencerId(
              data.influencer_deep_data?.influencer_data?.influencer_id
            );
          }}
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            textWrap: "nowrap",
          }}
        >
          <Image
            source={
              data?.influencer_deep_data?.influencer_data?.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data?.influencer_deep_data?.influencer_data?.influencer_id,
                    data?.influencer_deep_data?.influencer_data?.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            fallBack={require("../../resources/images/user-copy.png")}
            className="proposal-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <CustomText
                p
                text={data?.influencer_deep_data?.influencer_data.name}
                className="Body-1"
              />
              <CustomText
                p
                text={getInflencerLocation(
                  data?.influencer_deep_data?.influencer_data
                )}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              />
            </div>

            {data.bid_data.is_boosted == "1" && (
              <div className="circle-boost">
                <CustomText p text={"⚡"} fontSize={"font_size_label"} />
              </div>
            )}
          </div>
        </span>
      ),
    },
    {
      name: "Platform",
      selector: (row) =>
        platFormName(
          row?.influencer_deep_data?.platforms_data,
          row?.influencer_deep_data?.influencer_data.influencer_id
        ),
      // sortable: true,
      width: "90px",
      omit: isMobile,
    },

    {
      name: "Followers",
      selector: (row) =>
        formatPlatformData(
          row?.influencer_deep_data?.platforms_data,
          "followers"
        ) == 0
          ? "-"
          : formatPlatformData(
              row?.influencer_deep_data?.platforms_data,
              "followers"
            ),
      width: "105px",
      omit: isMobile,
    },
    // {
    //   name: "Like",
    //   selector: (row) =>
    //     formatPlatformData(
    //       row.influencer_deep_data.platforms_data,
    //       "avg_likes"
    //     ),
    //   width: "90px",
    // },
    // {
    //   name: "Comment",
    //   selector: (row) =>
    //     formatPlatformData(
    //       row.influencer_deep_data.platforms_data,
    //       "avg_comments"
    //     ),
    //   width: "100px",
    // },
    {
      name: "Avg Views",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(
            row?.influencer_deep_data?.platforms_data,
            "avg_views"
          )
        )
          ? "-"
          : formatPlatformData(
              row?.influencer_deep_data?.platforms_data,
              "avg_views"
            ),
      width: "100px",
      omit: isMobile,
    },
    {
      name: "ER",
      omit: isMobile,
      selector: (row) =>
        Utils.isNullOrUndefinedOrZero(
          formatPlatformData(
            row?.influencer_deep_data?.platforms_data,
            "engagement_rate"
          )
        )
          ? "-"
          : formatPlatformData(
              row?.influencer_deep_data?.platforms_data,
              "engagement_rate"
            ),
      width: "120px",
      cell: (data) => (
        <span
          style={{
            fontSize: 14,
          }}
        >
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(
                    data?.influencer_deep_data?.platforms_data,
                    "avg_likes"
                  )}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(
                    data?.influencer_deep_data?.platforms_data,
                    "avg_comments"
                  )}
                </div>
              </>
            }
          >
            {Utils.isNullOrUndefinedOrZero(
              formatPlatformData(
                data?.influencer_deep_data?.platforms_data,
                "engagement_rate"
              )
            )
              ? "-"
              : Number(
                  formatPlatformData(
                    data?.influencer_deep_data?.platforms_data,
                    "engagement_rate"
                  )
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },

    {
      name: "Asking Price",
      selector: (row) => console.log("resssssssss", row["bid_data"]["value"]),

      cell: (data) => (
        <>
          {data["bid_data"]["value"] == 0 && !data["bid_data"]["value"] ? (
            "-"
          ) : (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                fontSize: 14,
              }}
              onClick={
                collabType !== "BARTER"
                  ? () => counterOpen(data["bid_data"]["bid_id"])
                  : ""
              }
            >
              <>
                {data["bid_data"]["counter_value"] ? (
                  <div>
                    {data["bid_data"]["is_counter"] == 2 ? (
                      <>
                        <Tooltip title={"Influencer rejected offer"} arrow>
                          <span style={{ color: "red", marginRight: 2 }}>
                            {Utils.convertFromINR(
                              data["bid_data"]["counter_value"],
                              props?.user_data?.country
                            )}
                          </span>
                        </Tooltip>
                        <span style={{ color: "#1977F2" }}>
                          {Utils.convertFromINR(
                            data["bid_data"]["value"],
                            props?.user_data?.country
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <s style={{ marginRight: 5 }}>
                          {Utils.convertFromINR(
                            data["bid_data"]["value"],
                            props?.user_data?.country
                          )}
                        </s>
                        <span style={{ color: "#1977F2" }}>
                          {Utils.convertFromINR(
                            data["bid_data"]["counter_value"],
                            props?.user_data?.country
                          )}
                        </span>
                      </>
                    )}
                  </div>
                ) : (
                  <span
                    style={{
                      color:
                        data["bid_data"]["is_counter"] === 1
                          ? "rgb(0, 154, 41)"
                          : "#1977F2",
                    }}
                  >
                    {Utils.convertFromINR(
                      data["bid_data"]["value"],
                      props?.user_data?.country
                    )}
                  </span>
                )}
              </>

              {/* {balanceValidation(data?.bid_data?.value)} */}
              {collabType !== "BARTER" && (
                <>
                  <Tooltip title={"Counter Offer"} arrow>
                    <div
                      style={{ marginTop: 3 }}
                      // onClick={() => counterOpen(data["bid_data"]["bid_id"])}
                    >
                      <Icon iconName="EditIconCounter" />
                    </div>
                  </Tooltip>
                </>
              )}
            </span>
          )}
        </>
      ),
    },

    {
      name: "",

      lastCol: true,

      paddingRight: "20px",
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            justifyContent: "right",
          },
        },
      ],
      cell: (data) => (
        <snap
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
          }}
        >
          <PrimaryCta
            fifth
            onClick={(index) => {
              setBidId(data.bid_data.bid_id);
              balanceValidation(data.bid_data.value);
              setBidValue(data.bid_data.value);
              // setInfluencerId(index);
              openPopAprroveBid();
            }}
            fontSize={"font_size_large"}
            text={"Accept"}
            isLoading={approveCampaign[data.bid_data.bid_id]}
            className="blue-button-p-10-15"
          />
          {!isMobile && (
            <div
              onClick={() => {
                setBidId(data.bid_data.bid_id);
                setRejectPopupOpen(true);
              }}
              style={{ textWrap: "nowrap" }}
            >
              <CustomText
                p
                text={"Reject"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_secondary"}
              />
            </div>
          )}
        </snap>
      ),

      // sortable: true,
    },

    {
      name: "",
      width: "60px",
      omit: isMobile,
      cell: (data) => (
        <span
          style={{ paddingLeft: 10, paddingRight: 10 }}
          onClick={() => {
            setShowCollectionBtn(!showCollectionBtn);
            setHoveredId(
              data?.influencer_deep_data?.influencer_data?.influencer_id
            );
          }}
        >
          <img src="../assets/images/dots.png" height={15} alt="" srcset="" />
          {data?.influencer_deep_data?.influencer_data?.influencer_id ==
            hoveredId && hoveredButton()}
        </span>
      ),
    },
  ];

  function checkBalance() {
    let totalAmount = 0; // Initialize as a number

    if (collabType === "BARTER") {
      totalAmount =
        (Number(bidValue) || 0) +
        (Number(campaignDetail?.campaign_data?.convenience_fee) || 0);
    } else {
      totalAmount = (Number(productPrice) || 0) + (Number(bidValue) || 0);
    }

    return totalAmount;
  }

  const bidCSVFile = () => {
    const data = bidList.map((item) => {
      const bidData = item?.bid_data;
      const influencerDataObj = item?.influencer_deep_data;
      const influencerData = influencerDataObj?.influencer_data;

      const city = Utils.getCityById(influencerData?.city_id);
      const socialData = influencerDataObj?.platforms_data;
      const category =
        influencerDataObj?.categorys_data &&
        Object.values(influencerDataObj?.categorys_data)[0]?.name;

      return {
        Name: influencerData?.name,
        "Proposal Amount": bidData?.value, // Change key to 'Proposal Amount'
        Gender: influencerData?.gender,
        Username: socialData && socialData[0]?.identifier_id,
        Followers: Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(socialData, "followers")
        )
          ? "N/A"
          : formatPlatformData(socialData, "followers"),
        "Avg Likes": Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(socialData, "avg_likes")
        )
          ? "N/A"
          : formatPlatformData(socialData, "avg_likes"),
        "Avg Comments": Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(socialData, "avg_comments")
        )
          ? "N/A"
          : formatPlatformData(socialData, "avg_comments"),
        "Avg Views": Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(socialData, "avg_views")
        )
          ? "N/A"
          : formatPlatformData(socialData, "avg_views"),
        "Engagement Rate": getEngagementRate(socialData),
        City: city,
        Category: category,
      };
    });

    return (
      <div className="bid-csv-btn" onClick={() => exportToCSV(data)} style={{}}>
        <Icon iconName="downloadIcon" />
      </div>
    );
  };

  function renderLoader() {
    return (
      <>
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      </>
    );
  }

  function renderFallback() {
    if (!loading && campaignStatus == "COMPLETED") {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBackBtn
              heading="Campaign Completed"
              title="Your campaign got completed or expired."
              text="Renew Campaign"
              onClick={openModalRepost}
            />
          </div>
        </>
      );
    } else if (!loading && campaignStatus == "IN_REVIEW") {
      return (
        <div className="fall-back-center-campaign">
          <FallBack
            heading={"Your campaign is under review"}
            title={
              "You will start receiving proposals as soon as it gets reviewed"
            }
          />
        </div>
      );
    } else if (!loading && bidListSort.length == 0) {
      return (
        <>
          {" "}
          {searchName == null ? (
            <div className="fall-back-center-campaign">
              <FallBack
                heading={"No Proposals Available"}
                title={"Please wait until you get a new proposal"}
              />
            </div>
          ) : (
            <div className="fall-back-center-campaign">
              <SearchFallBack
                heading={"Your search did not match any results."}
                title={"Make sure all words are spelled correctly"}
                subTitle={"Try different keywords"}
              />
            </div>
          )}
        </>
      );
    } else {
      return <></>;
    }
  }

  if (!props.isActive) {
    return <></>;
  }
  const bidListFilterSidebarHeader = () => <div>ALL FILTERS</div>;
  const bidListFilterSidebarFooter = () => (
    <div style={{ display: "flex", gap: 10 }}>
      <div>
        <PrimaryCta
          invert
          onClick={() => clearFilterFun()}
          fontSize={"font_size_large"}
          text={"Clear Filter"}
          isLoading={""}
        />
      </div>
      <div>
        <PrimaryCta
          fifth
          onClick={() => getBidList()}
          fontSize={"font_size_large"}
          text={"Apply Filter"}
          isLoading={""}
          className="blue-button"
        />
      </div>
    </div>
  );

  function bidListFilterFun() {
    return (
      <Sidebar
        isOpen={bidListFilter}
        onClose={BidListFilterClose}
        Header={bidListFilterSidebarHeader}
        Footer={bidListFilterSidebarFooter}
        sidebarWidth={"30%"}
        headerAlignment="left"
      >
        {/* <div className="">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Engagement Rate"}
              textAlign={"text_align_left"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Min Rate"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Max Rate"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="m-t-20">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Avg Views"}
              textAlign={"text_align_left"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Min Views"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Max Views"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="p-20">
          <div className="">
            <div className="m-b-5">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Profession"}
                textAlign={"text_align_left"}
              />
            </div>
            <Multiselect
              placeholder="Select profession"
              id="css_custom"
              selectedValues={profession}
              options={FilterOutSubCategory()}
              onSelect={(selectedList, selectedItem) =>
                updateProfession(selectedList, selectedItem)
              }
              onRemove={(selectedList, selectedItem) =>
                updateProfession(selectedList, selectedItem)
              }
              singleSelect={false}
              displayValue={"name"}
              showArrow={false}
              style={{
                chips: Style.chips,
                option: Style.option,
                multiselectContainer: Style.multiselectContainerFilter,
                searchBox: Style.searchBox,
              }}
            />
          </div>

          <div className="m-t-20">
            <div className="m-b-5">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Location"}
                textAlign={"text_align_left"}
              />
            </div>
            <Multiselect
              placeholder="Select location"
              id="css_custom"
              options={
                genderLocation
                  ? getLocations(CampaignData.CITY, JSON.parse(genderLocation))
                  : getLocations(CampaignData.CITY, [])
              }
              selectedValues={state}
              onSelect={(selectedList, selectedItem) =>
                updateState(selectedList, selectedItem)
              }
              onRemove={(selectedList, selectedItem) =>
                updateState(selectedList, selectedItem)
              }
              singleSelect={false}
              displayValue={"label"}
              showArrow={false}
              style={{
                chips: Style.chips,
                option: Style.option,
                multiselectContainer: Style.multiselectContainerFilter,
                searchBox: Style.searchBox,
              }}
            />
          </div>

          {genderFilter == "all" && (
            <div style={{ textAlign: "left" }} className="m-t-20">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Gender"}
                  textAlign={"text_align_left"}
                />
              </div>

              <Select
                multi={false}
                options={options}
                values={influencerGender}
                onChange={(value) => updateGender(value)}
                placeholder="Select gender"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid #cbd2d9",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  paddingBottom: "9px",
                  paddingTop: "9px",
                  option: {
                    backgroundColor: "red",
                  },
                  color: "#cbd2d9",
                }}
              />
            </div>
          )}
        </div>
      </Sidebar>
    );
  }

  function openPopAprroveBid() {
    if (
      !Utils.checkRoleAndPermission(
        redux_selector,
        all_permissions["campaign"]["permissions"]["proposals"]
      )
    ) {
      showToast();
      //   toast.info("You are not authorised to accept proposal");
    } else {
      setApprovePopupOpen(true);
    }
  }
  function contractPolicyOpen() {
    setContractPolicySidebarOpen(true);
    setApprovePopupOpen(false);
  }

  function contractPolicyClose() {
    setContractPolicySidebarOpen(false);
  }
  const contractPolicySidebarHeader = () => (
    <CustomText
      p
      text={"Reelax Contract Cancellation Terms"}
      className="side-bar-heading-text"
    />
  );
  function reelaxCreditPolicy() {
    return (
      <Sidebar
        isOpen={contractPolicySidebarOpen}
        onClose={contractPolicyClose}
        Header={contractPolicySidebarHeader}
      >
        <div>
          <div
            style={{
              borderRadius: "5px",
              padding: "15px",
            }}
          >
            {/* <h2
              className="credit-terms-conditions"
              style={{ marginBottom: "20px" }}
            >
              Reelax Contract Cancellation Terms
            </h2> */}

            <p style={{ marginBottom: "20px", color: "#6b7280" }}>
              To maintain fairness and transparency for both brands and
              influencers, Reelax outlines the following contract cancellation
              terms:
            </p>

            <div style={{ marginBottom: "20px" }}>
              <h3
                className="credit-terms-conditions"
                style={{ marginBottom: "10px" }}
              >
                1. Cancellation Before Work Starts
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  color: "#6b7280",
                  marginBottom: "10px",
                  backgroundColor: "rgb(248, 250, 252)",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <strong>Scenario:</strong> The brand assigns the contract, and
                the influencer does not begin working.
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#6b7280",
                  backgroundColor: "rgb(248, 250, 252)",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <strong>Policy:</strong> 100% cancellation is offered, and the
                full contract amount is refunded to the brand.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3
                className="credit-terms-conditions"
                style={{ marginBottom: "10px" }}
              >
                2. Cancellation After Work Starts
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  color: "#6b7280",
                  marginBottom: "10px",
                  backgroundColor: "rgb(248, 250, 252)",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <strong>Scenario:</strong> The influencer marks the contract as
                “Work Started” but has not submitted the content.
              </p>
              <ul style={{ paddingLeft: "20px" }}>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>Influencer’s Fault (Delayed Submission):</strong> A
                  10% cancellation fee is applied, and the remaining amount is
                  refunded to the brand.
                </li>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>Influencer Not Responding:</strong> 100% cancellation
                  is offered, and the full amount is refunded to the brand.
                </li>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>Influencer Working but Brand Denies:</strong> A 50%
                  cancellation fee is applied, and the remaining amount is
                  refunded to the brand.
                </li>
              </ul>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3
                className="credit-terms-conditions"
                style={{ marginBottom: "10px" }}
              >
                3. Cancellation After Content Submission
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  color: "#6b7280",
                  marginBottom: "10px",
                  backgroundColor: "rgb(248, 250, 252)",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <strong>Scenario:</strong> The influencer submits the content,
                and the brand does not approve it within 15 days.
              </p>
              <ul style={{ paddingLeft: "20px" }}>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>Policy:</strong> If the brand takes no action within
                  15 days, the content will be automatically approved.
                </li>
              </ul>
              <p
                style={{
                  fontSize: "14px",
                  color: "#6b7280",
                  marginBottom: "10px",
                  backgroundColor: "rgb(248, 250, 252)",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <strong>Scenario:</strong> The influencer submits the content,
                and the brand does not approve it within 15 days.
              </p>
              <ul style={{ paddingLeft: "20px" }}>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>If the Content Follows Instructions:</strong> The
                  brand can request minor fixes only. Retakes are not permitted.
                </li>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>If the Content Violates Brand Guidelines:</strong> The
                  influencer must either retake the content or agree to a
                  cancellation with a 10% cancellation fee applied to the
                  influencer.
                </li>
                <li
                  style={{
                    fontSize: "14px",
                    color: "#6b7280",
                    marginBottom: "10px",
                    backgroundColor: "rgb(248, 250, 252)",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <strong>If the Brand Declines Retake:</strong> The brand may
                  cancel the contract with a 50% cancellation fee applied.
                </li>
              </ul>
            </div>

            <p style={{ fontSize: "14px", color: "#6b7280" }}>
              These terms ensure clarity and protect both parties in case of
              disputes or changes during the collaboration.
            </p>
          </div>
        </div>
      </Sidebar>
    );
  }
  const requiredBalance = Number(checkBalance()) - Number(balance);

  console.log("deliveryDate", deliveryDate);

  return (
    <div style={{ minHeight: 500 }}>
      <>
        <>
          {!isMobile && (
            <div className="bid-list-dropdown-header-container">
              <div
                style={{ marginTop: 12 }}
                className="flex-row justify-between align-center"
              ></div>
              <div
                className="flex-row"
                style={{ display: "flex", gap: 16, alignItems: "center" }}
              >
                {searchActive && (
                  <div className="bid-search-bar">
                    <div className="search-icon">
                      <Icon iconName="Search" height={18} width={18} />
                    </div>
                    <div>
                      <input
                        className="bid-search-campaign"
                        type="text"
                        placeholder="Search for influencers"
                        onChange={(e) => {
                          setNext(0);
                          setSearchName(e.target.value);
                        }}
                      />
                    </div>

                    <div
                      onClick={() => SearchBarActive()}
                      className="search-filter-icon"
                    >
                      <Icon iconName="crossCloseIcon" height={10} width={10} />
                    </div>
                  </div>
                )}
                {!searchActive && (
                  <div
                    className="cursor-pointer"
                    onClick={() => SearchBarActive()}
                  >
                    <Icon iconName={"bidSearchIcon"} height={18} width={18} />
                  </div>
                )}
                <div className="">
                  <button
                    onClick={() => filterDropDownOpenClose()}
                    className="dropdown-button"
                  >
                    <CustomText
                      p
                      text={filterLabel ? filterLabel : "Sort by"}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_black"}
                      textAlign={"text_align_right"}
                    />
                    {filterLabel ? (
                      <div style={{ width: 10 }}>
                        <Icon
                          iconName="crossCloseIcon"
                          height={10}
                          width={10}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <Icon
                          iconName={"DropdownIcon"}
                          height={18}
                          width={18}
                        />
                      </div>
                    )}
                  </button>
                  {filterDropDown && (
                    <div className="dropdown-list-container">
                      {sortTypes.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              setNext(0);
                              setSortBy(item.sortBy);
                              setSortOrder(item.sortOrder);
                              SortDropDown();
                              setFilterLabel(item.label);
                            }}
                            className={
                              item.sortBy == sortBy &&
                              item.sortOrder == sortOrder
                                ? "dropdown-button-list-selected"
                                : "dropdown-button-list"
                            }
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div
                  className="filter-icon-btn"
                  onClick={() => BidListFilterOpen()}
                >
                  <Icon iconName="filterIcon" />
                </div>
                <div>{bidCSVFile()}</div>
              </div>
            </div>
          )}

          <div className="custom-table-bid">
            <CustomTable
              className="CustomTable"
              tableSchema={tableSchema}
              paginationPerPage={10}
              tableData={bidListSort ? bidListSort : []}
              rowPaddingTop={16}
              rowPaddingBottom={16}
              customStyles={customBidStyles}
              conditionalRowStyles={conditionalRowStyles}
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              noDataComponent={renderFallback()}
              progressComponent={renderLoader()}
              paginationServer
              pagination
            />
          </div>
        </>
      </>

      {
        <Popup
          isOpen={approvePopupOpen}
          onClose={() => setApprovePopupOpen(false)}
          headerText={
            Number(balance) > checkBalance() ? "Accept Proposal" : "Balance Low"
          }
          footerButton1Text={"Cancel"}
          footerButton1Action={() => setApprovePopupOpen(false)}
          footerButton2Text={
            Number(balance) > checkBalance() ? "Accept" : "Add Balance"
          }
          footerButton2Action={() =>
            Number(balance) > checkBalance()
              ? approveBidUser()
              : navigate("/transaction-list")
          }
          isLoading={proposalLoading}
          width="50%"
          // height={"300px"}
          bottomSheetMaxHeight={0.6}
        >
          {Number(balance) > checkBalance() ? (
            <div className="contract-cancellation-confirm">
              <div className="contract-cancellation-top-p">
                <div style={{ marginTop: "10px" }}>
                  By accepting the proposal, you agree to accept &nbsp;
                  <u
                    onClick={contractPolicyOpen}
                    style={{ color: "#1977f2", cursor: "pointer" }}
                  >
                    Contract cancellation terms.
                  </u>
                </div>
              </div>
              <div
                className=" m-t-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "10px", fontSize: "14px" }}>
                  <CustomText
                    p
                    textColor="text_color_primary"
                    text="Delivery Date"
                  />
                </div>
                <div className="datepicker-container">
                  <DatePicker
                    selected={new Date(deliveryDate * 1000)}
                    onChange={(date) => updateDeliveryDate(date)}
                    className="datepicker-bid-list"
                  />
                </div>
              </div>
            </div>
          ) : (
            // Show when balance is sufficient
            <div className="contract-cancellation-confirm">
              <p className="cancel-request">
                Add Rs {isNaN(requiredBalance) ? 0 : requiredBalance} to your
                wallet to accept this proposal.
              </p>
            </div>
          )}
        </Popup>
      }
      {balanceDialog && (
        <ConfirmationDialog
          title={"insufficient Balance"}
          subtext={"Go to wallet add sufficient balance ?"}
          open={balanceDialog}
          handleClose={handleCancel}
          handelAgree={() => navigate("/transaction-list")}
        />
      )}
      {
        <Popup
          isOpen={rejectPopupOpen}
          onClose={() => setRejectPopupOpen(false)}
          headerText={"Reject Proposal"}
          footerButton1Text={"No"}
          footerButton1Action={() => setRejectPopupOpen(false)}
          footerButton2Text={"Yes"}
          footerButton2Action={() => postRejectBidUser()}
          isLoading={rejectLoading}
          width="480px"
          // height={"240px"}
          bottomSheetMaxHeight={0.35}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomText
              p
              fontSize="font_size_m"
              text="Are you sure, you want to reject this proposal?"
              textColor="text_color_primary"
            />
          </div>
        </Popup>
      }
      {bidListFilterFun()}
      {
        <Popup
          isOpen={counterPopupOpen}
          onClose={() => setCounterPopupOpen(false)}
          headerText={"Counter Offer"}
          footerButton1Text={"Cancel"}
          footerButton1Action={() => setCounterPopupOpen(false)}
          footerButton2Text={"Counter"}
          footerButton2Action={() => counterProposal()}
          isLoading={isLoadingCounter}
          width="480px"
          height={"280px"}
          bottomSheetMaxHeight={0.5}
        >
          {" "}
          <div style={{ padding: "10px 0" }}>
            <div style={{ marginBottom: "10px" }}>
              <CustomText
                p
                fontSize="font_size_m"
                text="Offer Price"
                textColor="text_color_primary"
              />
            </div>
            <div>
              <CustomTextInput
                height={51}
                placeHolder={"Offer Price"}
                value={counterOffer}
                onChange={(e) => setCounterOffer(e.target.value)}
              />
            </div>
          </div>
        </Popup>
      }

      {modalIsOpenCollection && (
        <CreateCollectionList closeModal={closeModalCollection} />
      )}
      {
        <CreateCampaignCollection
          shortlistPopupOpen={shortlistPopupOpen}
          setShortlistPopupOpen={setShortlistPopupOpen}
          influencer_id={hoveredId}
          campaignId={campaignId}
          selectedCollectionId={selectedCollectionId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }

      {repostModal && (
        <RepostJobOptionModal
          campaignDetail={props.campaignDetail}
          handleUpdateCampaign={props.handleUpdateCampaign}
          campaignId={campaignId}
          closeModalRepost={closeModalRepost}
        />
      )}
      {reelaxCreditPolicy()}
    </div>
  );
}

export default BidListScreen;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "658px",
    minHeight: "385px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};

const customStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "1000",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    // justifyContent: "space-around",
    minWidth: "500px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
