import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
  Popup,
} from "../../components";
import Select from "react-dropdown-select";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import campaign from "../../common/data/campaign";
import { isMobile } from "react-device-detect";
import styled from "@emotion/styled";

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }

  :hover {
    background: #f2f2f2;
  }
`;
const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const socialOption = [
  { value: "btll", label: "Instagram" },
  { value: "nxjo", label: "Youtube" },
];

function CreateCollectionModal({ getCollectionList, campaignId, ...props }) {
  const [collectionName, setCollectionName] = useState("");
  const [loading, setLoading] = useState(false);
  const [socialMedia, setSocialMedia] = useState([
    { value: "btll", label: "Instagram" },
  ]);
  const redux_dispatch = useDispatch();

  const redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { UserData = {} } = redux_selector;
  const { brand_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;
  console.log("socialMedia", socialMedia);
  function createCollectionList() {
    var collection_data = {
      campaign_id: campaignId ? campaignId : null,
      brand_id: brand_data?.brand_id,
      org_id: brand_data?.org_id,
      collection_name: collectionName,
      platform_id: socialMedia[0]?.value,
    };
    const params = {
      collection_data: JSON.stringify(collection_data),
    };
    Core.postAddCollection(
      createCollectionSuccess,
      createCollectionListFailed,
      (status) => setLoading(status),
      params
    );
  }

  function createCollectionSuccess(response_data) {
    getCollectionList();
    props.setCreateListPopupOpen(false);
    toast.success("Create List Successfully");
    setLoading(false);
  }

  function createCollectionListFailed(errorList) {
    toast.error(errorList);
  }

  function updateSocialMedia(selectedItem) {
    setSocialMedia(selectedItem);
  }
  return (
    <Popup
      isOpen={props.createListPopupOpen}
      onClose={() => props.setCreateListPopupOpen(false)}
      headerText={"Create List"}
      footerButton1Text={"Cancel"}
      footerButton1Action={() => props.setCreateListPopupOpen(false)}
      footerButton2Text={"Create"}
      footerButton2Action={() => createCollectionList()}
      isLoading={loading}
      width="480px"
      bottomSheetMaxHeight={0.7}>
      <div>
        <div className="m-t-10">
          <div style={{ textAlign: "center" }} className="flex-row">
            <CustomText
              p
              text={"List name"}
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              textAlign={"text_align_left"}
              className="m-b-8 "
            />
          </div>
          <CustomTextInput
            value={collectionName}
            height={51}
            onChange={(e) => setCollectionName(e.target.value)}
            placeHolder={"List name"}
            type="text"
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Select social media"}
              textAlign={"text_align_left"}
            />
          </div>
          <Select
            multi={false}
            options={socialOption}
            values={socialMedia}
            onChange={(value) => updateSocialMedia(value)}
            itemRenderer={({ item, methods }) => (
              console.log("demo 22", item, methods),
              (
                <StyledItem>
                  <div onClick={() => methods.addItem(item)}>
                    <input
                      type="checkbox"
                      onChange={() => methods.addItem(item)}
                      checked={methods.isSelected(item)}
                    />
                    {item.label}
                  </div>
                </StyledItem>
              )
            )}
            placeholder="Select social media"
            autoFocus={false}
            color="#C8C2C2"
            style={{
              border: "1px solid #cbd2d9",
              backgroundColor: "#fff",
              fontSize: 14,
              borderRadius: 6,
              padding: "15px 10px",

              option: {
                backgroundColor: "red",
                // margin: 0,
              },
            }}
          />
        </div>
        <div
          className="m-t-25"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 20,
          }}></div>
      </div>
    </Popup>
  );
}

export default CreateCollectionModal;
