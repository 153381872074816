import React, { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomText,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import Utils from "../../common/utils";
import { useSnackbar } from "react-simple-snackbar";
import Core from "../../common/clientSdk";
import { useState } from "react";
import { useEffect } from "react";
import UploadImageS3 from "../../common/s3";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import "react-image-crop/dist/ReactCrop.css";
import * as Actions from "../../redux/actions";
import ReportSidebar from "../report-sidebar";
import Cropper from "react-easy-crop";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    minWidth: "400px",
    height: "70%",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function BrandSettingsScreen() {
  const [imageId, setImageId] = useState();
  const [brandName, setBrandName] = useState();
  const [brandDescription, setBrandDescription] = useState();
  const [openSnackbar] = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const redux_dispatch = useDispatch();

  useEffect(() => {
    if (croppedImage) {
      generateUploadUrl();
    }
  }, [croppedImage]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    openModal();
  };

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const handleCropImageSave = () => {
    if (cropArea) {
      const canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");
      const imageObj = new Image();

      imageObj.onload = () => {
        canvas.width = cropArea.width;
        canvas.height = cropArea.height;
        ctx.drawImage(
          imageObj,
          cropArea.x,
          cropArea.y,
          cropArea.width,
          cropArea.height,
          0,
          0,
          cropArea.width,
          cropArea.height
        );

        // Get the cropped image data as a base64-encoded string
        const croppedImageData = canvas.toDataURL("image/jpeg");

        // Determine the image type (JPEG, PNG, etc.)
        const imageType = croppedImageData.split(";")[0].split(":")[1];

        // Convert the base64 data to a Blob
        const byteCharacters = atob(croppedImageData.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: imageType });

        // Create a File object
        const fileName = `cropped_${Date.now()}.${imageType.split("/")[1]}`;
        const croppedFile = new File([blob], fileName, { type: imageType });

        // Save the cropped File to the state
        setCroppedImage(croppedFile);
      };

      imageObj.src = image;
    }
    closeModal();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const imageRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    setBrandName(brand_data.name);
    setBrandDescription(brand_data.note);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { UserData = {} } = redux_selector;

  const { brand_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  function updateBrandSetting(progress_cb) {
    const params = {
      brand_data: JSON.stringify({
        brand_id: brand_data.brand_id,
        org_id: brand_data.org_id,
        image_id: imageId,
        name: brandName,
        note: brandDescription,
      }),
    };
    Core.updateBrandSetting(
      updateBrandSettingSuccess,
      updateBrandSettingFailed,
      progress_cb,
      params
    );
  }

  function updateBrandSettingSuccess(response_data) {
    openSnackbar("Brand details updated");
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: { brand_data: response_data.b_d } },
    });
    setCroppedImage(null);
  }

  function updateBrandSettingFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function generateUploadUrl() {
    var params = {
      id: brand_data.org_id,
      file_type: croppedImage.type,
      action: "brand_logo",
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      redux_selector.Auth.auth_token,
      croppedImage
    );
  }

  function generateUrlSuccess(response) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(croppedImage, response.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return openSnackbar("Upload failed");
        }
        setImageId(response.upload_data.media_id);
      });
    } else {
      setUploading(false);
      openSnackbar("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    openSnackbar("Something went wrong");
    setUploading(false);
  }

  let mediaPath;

  if (croppedImage) {
    mediaPath = URL.createObjectURL(croppedImage);
  } else {
    mediaPath = Utils.generateBrandLogoUrl(
      brand_data.org_id,
      brand_data.image_id
    );
  }

  return (
    <>
      <div className="dashboard-container">
        <SideMenu />

        <div className="main-screen">
          <Header />
          <div className="page-container">
            <div className="campaign-text-heading">
              <CustomText
                p
                text={"Brand Settings"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
              />
            </div>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div onClick={closeModal} className="close-btn-icon m-b-15">
                <Icon iconName="CloseRoundButtonIcon" />
              </div>
              <div style={{ marginTop: "40px" }}>
                <div
                  style={{
                    flexDirection: "column",
                    position: "relative",
                    height: 300,
                  }}
                >
                  <div style={{ height: 200, width: 200 }}>
                    {image && (
                      <React.Fragment>
                        <Cropper
                          mediaProps={"png"}
                          image={image}
                          crop={crop}
                          zoom={zoom}
                          aspect={1 / 1}
                          onCropChange={onCropChange}
                          onZoomChange={onZoomChange}
                          onCropComplete={onCropComplete}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              {image && (
                <div className="m-t-32 add-new-brand-btn full-width">
                  <PrimaryCta
                    fontSize={"font_size_large"}
                    fullWidth={true}
                    text={"Set Brand Logo"}
                    isLoading={isLoading}
                    onClick={() => {
                      handleCropImageSave();
                    }}
                  />
                </div>
              )}
            </Modal>

            <div className="setting-screen-main-container brand-setting-container">
              <div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      marginRight: "8px",
                      marginTop: "20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="m-t-4">
                      <img
                        alt="not fount"
                        className="brand-img"
                        src={mediaPath}
                      />
                    </div>
                    <div>
                      <input
                        accept="image/*"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          opacity: 0,
                          height: 100,
                          width: 100,
                          borderRadius: "50%",
                        }}
                        type="file"
                        name="myImage"
                        ref={imageRef}
                        onChange={onFileChange}
                      />
                    </div>
                  </div>
                  <div className="brand-update-page-loader">
                    {isUploading && <Loader />}
                  </div>
                </div>
                <div className="m-t-20 full-width">
                  <CustomTextInput
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </div>
                <div className="m-t-20 brand-text-area">
                  <CustomTextArea
                    value={brandDescription}
                    onChange={(e) => setBrandDescription(e.target.value)}
                  />
                </div>
                <div className="m-t-20">
                  <PrimaryCta
                    fontSize={"font_size_large"}
                    fullWidth={true}
                    isLoading={isLoading}
                    text={"Update"}
                    onClick={() => updateBrandSetting(updateProgress)}
                    disabled={isLoading ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <ReportSidebar />
        </div>
      </div>
    </>
  );
}

export default BrandSettingsScreen;
