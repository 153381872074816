import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Core from "../common/clientSdk";
import { data } from "jquery";
import validator from "validator";
import countryList from "../common/data/country";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../redux/actions";
import Select from "react-dropdown-select";
import { Util } from "reactstrap";
import Utils from "../common/utils";
import { toast } from "react-toastify";
import { Sidebar } from "../components";
import { ToastContainer } from "react-toastify";

function UserTimeZone({
  changeTimeZoneSidebarOpen,
  setChangeTimeZoneSidebarOpen,
}) {
  const [isLoading, setLoading] = useState(false);

  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [timeZone, setTimeZone] = useState();
  const [error, set_error] = useState({
    designation: "",
  });
  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;
  console.log("timeZone", timeZone);

  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        time_zone: timeZone[0],
      }),
    };

    Core.postUpdateUserProfile(
      postUpdateUserProfileSuccess,
      postUpdateUserProfileFailed,
      progress_cb,
      params
    );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    setChangeTimeZoneSidebarOpen(false);
    toast.success("Time zone changed successfully!");
  }

  function postUpdateUserProfileFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function updateCountyTime(selectedItem) {
    console.log("selectedItem", selectedItem);

    setTimeZone(selectedItem ? selectedItem.map((option) => option.value) : []);
  }

  console.log("time zone", Utils.getTimeZone(user_data.country));
  const timezoneData = Object.values(Utils.getTimeZone(user_data.country)).map(
    (timezone) => ({
      label: timezone,
      value: timezone,
    })
  );
  console.log("timezoneData", timezoneData);

  const changeFullNameSidebarHeader = () => {
    return <CustomText p text={"Change Time Zone"} />;
  };
  const changeFullNameSidebarFooter = () => {
    return (
      <PrimaryCta
        fontSize={"font_size_large"}
        className="blue-button"
        fullWidth={true}
        text={"Update"}
        isLoading={isLoading}
        onClick={() => {
          postUpdateUserProfile(updateProgress);
        }}
      />
    );
  };
  return (
    <div>
      <Sidebar
        sidebarWidth={"30%"}
        isOpen={changeTimeZoneSidebarOpen}
        onClose={() => setChangeTimeZoneSidebarOpen(false)}
        Header={changeFullNameSidebarHeader}
        Footer={changeFullNameSidebarFooter}
        headerAlignment="left">
        <div className="p-20">
        <div className="">
          <CustomText p text={"Select Time Zone"} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div className=" full-width">
            <Select
              multi={false}
              options={timezoneData}
              // values={user_data.time_zone ? [user_data.time_zone] : timeZone}
              onChange={(value) => updateCountyTime(value)}
              placeholder="Select Time Zone"
              // labelField="name"
              // valueField="country_code"
              autoFocus={false}
              color="#c8c2c2"
              // Customcolor
              style={{
                border: "1px solid #cbd2d9",
                backgroundColor: "#fff",
                borderRadius: 6,
                paddingBottom: "16px",
                maxWidth: "100%",
                paddingTop: "16px",
                option: {
                  backgroundColor: "red",
                },
              }}
            />
            <h5 className="error">{error.designation}</h5>
          </div>
        </div>
        </div>
      </Sidebar>
      <ToastContainer />
    </div>
  );
}

export default UserTimeZone;
