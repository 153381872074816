import React from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  TeamMembers,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import utils from "../../common/utils";
import { useNavigate } from "react-router-dom";
import Style from "../../components/core_component/style";
import { useSnackbar } from "react-simple-snackbar";

function KycVerifyScreen() {
  const [kycOrgData, setKycOrgData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const ORG_KYC_TYPES = ["ADDRESS_PROOF", "TAX_PROOF"];

  var navigate = useNavigate();
  useEffect(() => {
    getOrgKycDetails(updateProgress);
  }, []);
  var redux_selector = useSelector((state) => {
    return {
      UserData: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  function updateProgress(status) {}

  const { UserData = {} } = redux_selector;
  const { user_data = {} } = UserData;

  const { brand_deep_data = {} } = UserData;
  const { brand_data = {} } = brand_deep_data;

  function getOrgKycDetails(progress_cb) {
    const params = { org_id: brand_data.org_id };
    Core.getOrgKycDetails(
      getOrgKycDetailsSuccess,
      getOrgKycDetailsFailed,
      progress_cb,
      params
    );
  }

  function getOrgKycDetailsSuccess(response_data) {
    setKycOrgData(response_data);
    setLoading(false);
  }

  function getOrgKycDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function renderPanUI(kyc_type) {
    if (kycOrgData && kycOrgData.kyc_data) {
      const kycData = kycOrgData.kyc_data.find(
        (kycItem) => kycItem.kyc_type === kyc_type
      );

      return (
        <div
          onClick={() => navigate("/kyc-address-proof/" + kyc_type)}
          className="content-row"
        >
          <TeamMembers
            Name={utils.getKYCTypeString(kyc_type)["n"]}
            title={renderSubText(kycData)}
          />
        </div>
      );
    }

    return null;
  }

  function renderSubText(kycData) {
    let subText = "s";
    if (kycData) {
      if (kycData.status === "IN_REVIEW") {
        subText = () => (
          <div style={Style.text_pending_color}>Under Review</div>
        );
      } else if (kycData.status === "COMPLETED") {
        subText = () => <div style={Style.text_completed_color}>Approved</div>;
      } else if (kycData.status === "REJECTED") {
        subText = () => <div style={Style.text_active_color}>Rejected</div>;
      }
    } else {
      subText = () => <div>Pending</div>;
    }
    return subText();
  }

  return (
    <>
      <div className="dashboard-container">
        <SideMenu />

        <div className="main-screen">
          <Header />
          <div className="page-container">
            <div className="campaign-text-heading">
              <CustomText
                p
                text={"Kyc Settings"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
              />
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div class="content-row-parent">
                {ORG_KYC_TYPES.map((item) => {
                  return <>{renderPanUI(item)}</>;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default KycVerifyScreen;
